import React, { useState } from "react"
import Card from "components/Card/Card"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import { Menu, MenuItem, Typography } from "@mui/material"
import { useTheme } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"
import ImageDisplay from "components/ImageDisplayer"
import classes from "pages/private/marketing/menu/menu.module.scss"
import Paragraph from "components/ReadMoreParagraph"
import {
  addarchiveSpiritItemsAction,
  deleteSpiritMenuItemAction,
  getSpiritItemsAction,
  unArchiveSpiritsItemsAction
} from "redux/marketing/actions/user"
import UnarchiveItem from "assets/icons/UnarchiveItem.svg"
import SelectAll from "assets/icons/SelectAllRectange.svg"
import unSelect from "assets/icons/unSelectedRectange.svg"
import { isEmptyObject } from "helpers/functions"
import selectedItem from "assets/icons/SelectedRectange.svg"
import { toast } from "react-toastify"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { marketingActions } from "redux/marketing/slice/user"
import DMVAddMenuItems from "pages/private/DVM_Console/DMV_Menu/AddDMVMenuItems"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { DMVActions } from "redux/DMV/slice/user"

function ArchivedSpiritListItems({ data, isEdit = false }) {
  const theme = useTheme()
  const [selectedItems, setSelectedItems] = useState({})
  const [modal, setDeleteModal] = useState(false)
  const [menuItemsModal, setMenuItemsModal] = useState(false)
  const [isEditModalItem, setIsEditModalItem] = useState(false)
  const [unarchiveModal, setUnarchiveItemModal] = useState(false)
  const dispatch = useDispatch()
  const { archived } = useSelector((store) => store?.marketing?.menu ?? {})
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event, id, categoryId) => {
    setAnchorEl(event.currentTarget)
    dispatch(DMVActions.setDMVItemID(id))
    dispatch(DMVActions.setDMVMenuCategoryId(categoryId))
    dispatch(getSpiritItemsAction())
  }

  function handleEditMenuItemModal() {
    setAnchorEl(null)
    setIsEditModalItem(true)
    setMenuItemsModal(true)
    dispatch(marketingActions.setisArchive(true))
  }

  function handleCloseModal() {
    setDeleteModal(false)
    dispatch(marketingActions.setMenuItemId(""))
  }

  function handleCloseMenuItems() {
    setMenuItemsModal(false)
    setIsEditModalItem(false)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }

  function handleMenuItemDelete() {
    dispatch(deleteSpiritMenuItemAction())
    dispatch(marketingActions.setisArchive(false))
  }

  function handleArchiveItem() {
    dispatch(marketingActions.setisArchive(true))
    setUnarchiveItemModal(true)
    setAnchorEl(null)
  }

  function handleCloseArchiveModal() {
    setUnarchiveItemModal(false)
  }

  function handleUnarchiveMenuItem() {
    const payload = {
      item_state: "available"
    }
    dispatch(addarchiveSpiritItemsAction(payload))
  }

  function handleMenuDeleteModal() {
    handleClose()
    setDeleteModal(true)
    setAnchorEl(null)
    dispatch(marketingActions.setisArchive(true))
  }

  const handleSelect = (item) => {
    if (isEmptyObject(selectedItems)) {
      setSelectedItems(item)
    } else {
      setSelectedItems({})
    }
  }

  const handleUnArchive = () => {
    if (isEmptyObject(selectedItems)) {
      toast.error("Please select items to unarchive!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    } else {
      const id_dict = selectedItems?.spirit_list?.map((item) => ({
        id: item.id,
        food_type: "spirits"
      }))
      const payload = {
        id_dict
      }
      dispatch(unArchiveSpiritsItemsAction(payload))
    }
  }

  const checkSelectedItems = (id) => {
    const isItemSelected = selectedItems?.spirit_list?.filter((item) => item?.id === id)
    if (isItemSelected?.length > 0) {
      return true
    } else {
      return false
    }
  }

  const handleSelectItem = (id) => {
    if (isEmptyObject(selectedItems)) {
      setSelectedItems({
        ...data,
        spirit_list: data?.spirit_list?.filter((item) => item?.id === id)
      })
    } else {
      const itemsData = selectedItems?.spirit_list?.filter((item) => item?.id === id)
      if (itemsData?.length > 0) {
        if (selectedItems?.spirit_list?.length === 1) {
          setSelectedItems({})
        } else {
          setSelectedItems({
            ...selectedItems,
            spirit_list: selectedItems?.spirit_list?.filter((item) => item?.id !== id)
          })
        }
      } else {
        setSelectedItems({
          ...selectedItems,
          spirit_list: [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...selectedItems?.spirit_list,
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...data?.spirit_list?.filter((item) => item?.id === id)
          ]
        })
      }
    }
  }
  return (
    <>
      <DMVAddMenuItems
        isEdit={isEditModalItem}
        open={menuItemsModal}
        onClose={handleCloseMenuItems}
        isCMS={true}></DMVAddMenuItems>
      <DeleteConfirmationDialog
        title={`Are You Sure You Want To Delete This Wine ?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseModal}
        onDelete={() => handleMenuItemDelete()}
        open={modal}></DeleteConfirmationDialog>
      <DeleteConfirmationDialog
        title={`Are You Sure You Want To Unarchive This Wine?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseArchiveModal}
        onDelete={() => handleUnarchiveMenuItem()}
        open={unarchiveModal}
        btnText="Unarchive"
        type="warning"
      />
      {data?.spirit_list?.length > 0 ? (
        <Card style={{ height: "fit-content" }}>
          <Row>
            <Col lg="12">
              <div
                className="d-flex justify-content-between align-item-center"
                style={{
                  backgroundColor: isEdit && theme.palette.background.default,
                  padding: ` ${isEdit ? "12px 16px" : "0px"}`,
                  borderRadius: 6
                }}>
                <img
                  src={
                    selectedItems?.spirit_list?.length === data?.spirit_list?.length
                      ? selectedItem
                      : SelectAll
                  }
                  alt="selectAll"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSelect(data)}
                />
                <Typography variant="h2">{data?.spirit_category}</Typography>
                <div className="d-flex align-items-center">
                  <img
                    src={UnarchiveItem}
                    alt="unarchive"
                    style={{ cursor: "pointer" }}
                    onClick={handleUnArchive}
                  />
                </div>
              </div>
            </Col>
            <Col lg="12">
              {data?.spirit_list?.length ? (
                data?.spirit_list?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className={archived ? classes.archivedItemContainer : classes.itemContainer}
                      style={{
                        border: `${isEdit ? `1px solid ${theme.palette.secondary.main}` : "none"}`,
                        borderBottom: `${
                          !isEdit
                            ? `1px solid #605e5e`
                            : `.5px solid ${theme.palette.secondary.main}`
                        }`,
                        borderRadius: `${isEdit ? "6px" : "0px"}`,
                        padding: `${isEdit ? "8px" : "5px"}`
                      }}>
                      <div>
                        {checkSelectedItems(item?.id) ? (
                          <img
                            src={selectedItem}
                            alt="select"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSelectItem(item?.id)}
                          />
                        ) : (
                          <img
                            src={unSelect}
                            alt="select"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSelectItem(item?.id)}
                          />
                        )}
                      </div>
                      <div className={classes.imageContainer} style={{ height: 250 }}>
                        <ImageDisplay data={item?.image_url}></ImageDisplay>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          maxWidth: 375,
                          overflowWrap: "break-word"
                        }}>
                        <div>
                          <Typography variant="h4">{item?.name ?? "-"}</Typography>
                        </div>

                        <div>
                          <div className="mt-2">
                            <Typography variant="tag1">{"Description:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.description ?? "-"}
                            </Typography>
                          </div>

                          <div className="mt-2">
                            <Typography variant="tag1">{"Region:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.origin ?? "-"}
                            </Typography>
                          </div>

                          <div className="mt-2">
                            <Typography variant="tag1">{"Unique Facts:"}</Typography>
                            <br></br>
                            {item?.unique_facts?.length === 1
                              ? item?.unique_facts?.map((item, index) =>
                                  item?.fact !== "" ? (
                                    <Typography
                                      style={{
                                        display: item?.fact ? "list-item" : "",
                                        marginLeft: 15
                                      }}
                                      key={index}
                                      variant="tagItalic"
                                      sx={{ opacity: 0.6 }}>
                                      <Paragraph
                                        text={item?.fact ?? ""}
                                        maxCharCount={70}
                                        key={index}
                                        variant="tagItalic"
                                        sx={{ opacity: 0.6 }}></Paragraph>
                                    </Typography>
                                  ) : null
                                )
                              : item?.unique_facts?.map((item, index) => (
                                  <Typography
                                    style={{
                                      display: item?.fact ? "list-item" : "",
                                      marginLeft: 15
                                    }}
                                    key={index}
                                    variant="tagItalic"
                                    sx={{ opacity: 0.6 }}>
                                    <Paragraph
                                      text={item?.fact ?? ""}
                                      maxCharCount={70}
                                      key={index}
                                      variant="tagItalic"
                                      sx={{ opacity: 0.6 }}></Paragraph>
                                  </Typography>
                                ))}
                          </div>

                          <div className="mt-2">
                            <Typography variant="tag1">{"Ingredients:"}</Typography>
                            <br></br>
                            {item?.ingredient?.map((item, index) => (
                              <Typography key={index} variant="tagItalic" sx={{ opacity: 0.6 }}>
                                {`${item?.name ?? ""}`}
                                {item?.measurement
                                  ? item?.ingredient?.length - 1 > index
                                    ? ` (${item?.measurement}), `
                                    : item?.measurement
                                    ? ` (${item?.measurement}) `
                                    : ""
                                  : item?.ingredient?.length - 1 > index
                                  ? ", "
                                  : ""}
                              </Typography>
                            ))}
                          </div>

                          <div className="mt-2">
                            <Typography variant="tag1">{"Nose:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.nose ?? "-"}
                            </Typography>
                          </div>

                          <div className="mt-2">
                            <Typography variant="tag1">{"Palate:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.palate ?? ""}
                            </Typography>
                          </div>
                          <div className="mt-2">
                            <Typography variant="tag1">{"Finish:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.finish ?? ""}
                            </Typography>
                          </div>
                          <div className="mt-2">
                            <Typography variant="tag1">{"Distillation:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.distillation ?? ""}
                            </Typography>
                          </div>
                          <div className="mt-2">
                            <Typography variant="tag1">{"Aging:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.aging ?? ""}
                            </Typography>
                          </div>
                          <div className="mt-2">
                            <Typography variant="tag1">{"Bottling:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.bottling ?? ""}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      {isEdit && (
                        <div>
                          <MoreVertIcon
                            id="demo-positioned-button"
                            aria-controls={open ? "demo-positioned-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={(e) => handleClick(e, item?.id, data?.spirit_category_id)}
                            sx={{ cursor: "pointer" }}></MoreVertIcon>
                          <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right"
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right"
                            }}>
                            <MenuItem onClick={handleEditMenuItemModal} sx={{ width: "150px" }}>
                              Edit
                            </MenuItem>
                            <MenuItem onClick={() => handleArchiveItem()} sx={{ width: "150px" }}>
                              Unarchive
                            </MenuItem>
                            <MenuItem
                              onClick={handleMenuDeleteModal}
                              sx={{ width: "150px", color: theme.palette.red.main }}>
                              Delete
                            </MenuItem>
                          </Menu>
                        </div>
                      )}
                    </div>
                  )
                })
              ) : (
                <Typography className="mt-4" style={{ opacity: 0.6, textAlign: "center" }}>
                  No Items Found
                </Typography>
              )}
            </Col>
          </Row>
        </Card>
      ) : (
        <Typography>No Items Found</Typography>
      )}
    </>
  )
}

export default React.memo(ArchivedSpiritListItems)
