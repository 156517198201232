import React, { useEffect } from "react";
import { List, ListItem, Typography } from "@mui/material";
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder";

import Card from "components/Card/Card";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTopTriviaAnalyticsAction,

} from "redux/L&D/actions";

import CustomDivider from "components/StyledComponents/Divider";

function TriviaAnalyticsSnapshotUI({ TriviaCalendarSnapshotIcon, routeNext, date = "", style }) {
  const { topTrivia } = useSelector((store) => store?.learningDevelopment);


  const dispatch = useDispatch();

  const history = useHistory();
  const fetchData = () => {

    dispatch(getTopTriviaAnalyticsAction());
  };
  useEffect(() => {
    fetchData();
    
  }, []);

  return (
    <Card style={{ paddingBottom: 12, ...style }}>
      <List>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
          <Typography variant="h2">Trivia Analytics {date ? `for ${date} ` : ""}</Typography>
          <span onClick={() => history.push(routeNext)}>
            <IconButtonWithBorder aria-label="Go to Trivia Analytics">
              <TriviaCalendarSnapshotIcon />
            </IconButtonWithBorder>
          </span>
        </ListItem>

        {/* <ListItem
          sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            Top Restaurant
          </Typography>
          <Typography variant="body1Regular">{topTrivia.top_restaurant || "-"}</Typography>
        </ListItem> */}
        <ListItem
          sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            {`Percentage Correct  From ${topTrivia.top_restaurant ?? "-"}`}
          </Typography>
          <Typography
            variant="body1Regular"
            sx={(theme) => ({ color: theme.palette.green.main })}>
            { topTrivia.correct_answer_from_top_restaurant ? (topTrivia?.correct_answer_from_top_restaurant.toFixed(0) + "%") : "0%"}
          </Typography>
        </ListItem>
      

        {/* <ListItem
          sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            Bottom Restaurant
          </Typography>
          <Typography variant="body1Regular">{ topTrivia.bottom_restaurant || "-"}</Typography>
        </ListItem>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            {`Percentage Correct From ${topTrivia.bottom_restaurant ?? "-"}`}
          </Typography>
          <Typography
            variant="body1Regular"
            sx={(theme) => ({ color: theme.palette.green.main })}>
            { topTrivia.correct_answer_from_bottom_restaurant ? (topTrivia?.correct_answer_from_bottom_restaurant.toFixed(0)+"%") : "0%"}
          </Typography>
        </ListItem> */}
        <CustomDivider />
        <ListItem
          sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            Trivia Question Correct percent
          </Typography>
          <Typography
            variant="body1Regular"
            sx={(theme) => ({ color: theme.palette.green.main })}>
            { topTrivia.trivia_question_correct ? (topTrivia?.trivia_question_correct.toFixed(0)+"%") : "0%"}
          </Typography>
        </ListItem>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            Trivia Question Incorrect percent
          </Typography>
          <Typography
            variant="body1Regular"
            sx={(theme) => ({ color: theme.palette.green.main })}>
            { topTrivia.trivia_question_incorrect ? ((topTrivia.trivia_question_incorrect).toFixed(0)+"%") : "0%"}
          </Typography>
        </ListItem>
      </List>
    </Card>
  );
}

export default TriviaAnalyticsSnapshotUI;
