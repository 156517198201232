import Header from "components/Header/Header"
import React, { useEffect, useState } from "react"
import QuestionsBanks from "./components/QuestionBanks"
import AppButton from "components/StyledComponents/AppButton"
import { useHistory } from "react-router-dom"
import AddToTrivia from "./AddTrivia"
import { getQuestionsBankAction } from "redux/L&D/actions/user"
import { useDispatch, useSelector } from "react-redux"
import AppSelect from "components/StyledComponents/AppSelect"
import { Col, Row } from "react-bootstrap"

const filters = [
  {
    label: "All",
    value: "ALL"
  },
  {
    label: "AMERICANCUT",
    value: "scarpetta"
  },
  {
    label: "Vanta AI",
    value: "gamification"
  }
]

const QuestionBank = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [openTriviaDialog, setOpenTriviaDialog] = useState(false)
  const [triviaData, setTriviaData] = useState({})
  const [filter, setFilter] = useState("ALL")
  const [filterQuestion, setFilterQuestion] = useState("ALL")

  const { questionBank } = useSelector((state) => state?.learningDevelopment)

  const history = useHistory()

  const handleAddQuestion = () => {
    history.push("/learning_development/dashboard/question-bank/add-questions")
  }

  const handleAddTriviaDialogClose = () => {
    setOpenTriviaDialog(false)
  }

  const handleAddToTrivia = (data) => {
    setOpenTriviaDialog(true)
    setTriviaData(data)
  }

  const handleFilter = (event) => {
    setFilter(event.target.value)
  }

  useEffect(() => {
    dispatch(getQuestionsBankAction({ page: 1, platform: filter, question_used: filterQuestion }))
    setCurrentPage(1)
  }, [filter, filterQuestion])

  useEffect(() => {
    dispatch(
      getQuestionsBankAction({
        page: currentPage,
        platform: filter,
        question_used: filterQuestion
      })
    )
  }, [currentPage, filterQuestion])

  return (
    <>
      {openTriviaDialog && <AddToTrivia onClose={handleAddTriviaDialogClose} data={triviaData} />}
      <Header title="Question Bank">
        <AppButton onClick={handleAddQuestion}>Add Questions</AppButton>
      </Header>
      <Row className="d-flex">
        <Col lg="2">
          <AppSelect menuItems={filters} value={filter} onChange={handleFilter} />
        </Col>
      </Row>
      <QuestionsBanks
        title="Question Bank"
        questions={questionBank?.results}
        pages={questionBank?.pages}
        isEditable={true}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isTiriva={true}
        handleAddToTrivia={handleAddToTrivia}
        isDelete={true}
        questionFilter={true}
        setFilterQuestion={setFilterQuestion}
        filterQuestion={filterQuestion}
        filterOptions={sortOption}
      />
    </>
  )
}

export default QuestionBank

const sortOption = [
  {
    label: "All",
    value: "ALL"
  },
  {
    label: "In use",
    value: "true"
  },
  {
    label: "Not In Use",
    value: "false"
  }
]
