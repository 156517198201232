import { Box, CircularProgress, FormHelperText, Typography } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import CustomDivider from "components/StyledComponents/Divider"
import { FieldArray } from "formik"
import React, { useRef } from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import MenuPairing from "./menuPairingForm"
import TastingNotesForm from "./TastingNotesForm"
import WineCharacteristicsForm from "./WineCharacteristicsForm"
import WineDetailsForm from "./WineDetailsForm"

const CombinedForm = ({ formik, formReset, isEditMode }) => {
  const loading = useSelector((store) => store.app.loading)
  const tastingNoteUid = useRef(0)
  const characteristicUid = useRef(0)
  const menuPairingUid = useRef(0)

  return (
    <>
      <WineDetailsForm isEdit={isEditMode} formik={formik} />
      <CustomDivider sx={{ margin: "34px 0px" }} />
      <Typography variant="body1Medium">Tasting Notes</Typography>
      <FieldArray
        name={"tastingNotesSet"}
        render={(arrayHelper) => (
          <>
            {formik.values?.tastingNotesSet?.map((category, index) => (
              <TastingNotesForm
                {...{ formik, arrayHelper, index, isEditMode }}
                key={`tastingNote-${category?.id}`}
              />
            ))}
            {formik.values?.tastingNotesSet?.length < 5 ? (
              <AppButton
                onClick={() =>
                  arrayHelper.push({
                    uid: ++tastingNoteUid.current,
                    flavour: "",
                    image: null
                  })
                }
                sx={{ marginTop: "30px", width: 200 }}>
                Add More
              </AppButton>
            ) : null}
          </>
        )}
      />
      <CustomDivider sx={{ margin: "34px 0px" }} />
      <Typography variant="body1Medium">Menu Pairing</Typography>
      <FieldArray
        name={"menuPairing"}
        render={(arrayHelper) => (
          <>
            {formik.values?.menuPairing?.map((category, index) => {
              return (
                <MenuPairing
                  {...{ formik, arrayHelper, index, isEditMode }}
                  key={`menuPairing-${index}`}
                />
              )
            })}
            {formik.values?.menuPairing?.length < 2 ? (
              <AppButton
                onClick={() =>
                  arrayHelper.push({
                    uid: ++menuPairingUid.current,
                    flavour: "",
                    image: null
                  })
                }
                sx={{ marginTop: "30px", width: 200 }}>
                Add More
              </AppButton>
            ) : null}
          </>
        )}
      />
      <CustomDivider sx={{ margin: "34px 0px" }} />
      <Typography variant="body1Medium">Wine Characteristics</Typography>
      <FieldArray
        name={"wineCharateristicsSet"}
        render={(arrayHelper) => (
          <>
            {formik.values?.wineCharateristicsSet?.map((category, index) => (
              <WineCharacteristicsForm
                {...{ formik, arrayHelper, index, isEditMode }}
                characteristic={formik.values.wineCharateristicsSet[index].characteristic}
                key={`wineCharacteristic-${category.id || category.uid}`}
              />
            ))}
            {!Array.isArray(formik?.errors?.wineCharateristicsSet) &&
            Boolean(formik?.touched?.wineCharateristicsSet) &&
            Boolean(formik?.errors?.wineCharateristicsSet) ? (
              <FormHelperText error={true}>{formik.errors.wineCharateristicsSet}</FormHelperText>
            ) : null}
            {formik.values?.wineCharateristicsSet?.length < 5 ? (
              <AppButton
                onClick={() =>
                  arrayHelper.push({
                    uid: ++characteristicUid.current,
                    characteristic: "",
                    level: ""
                  })
                }
                sx={{ marginTop: "30px", width: 200 }}>
                Add More
              </AppButton>
            ) : null}
          </>
        )}
      />
      <Row className="mb-5">
        <Col lg="2" className="mt-5">
          <AppButton onClick={formReset} sx={{ height: "48px", width: "100%" }}>
            Reset
          </AppButton>
        </Col>
        <Col lg="2" className="mt-5">
          <AppButton
            disabled={loading}
            onClick={formik.handleSubmit}
            sx={{ height: "48px", width: "100%" }}>
            {loading ? (
              <Box className="d-flex">
                <CircularProgress size={20} sx={{ color: "#FFFFFF", marginRight: "5px" }} />
                <span>Please Wait...</span>
              </Box>
            ) : (
              "Submit"
            )}
          </AppButton>
        </Col>
      </Row>
    </>
  )
}

export default CombinedForm
