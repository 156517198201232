import { Grid, Typography, useTheme } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { Formik } from "formik"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { validationSchemaAddQuestions } from "helpers/validationSchema"
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Prompt, useHistory } from "react-router-dom"
import { addTriviaQuestionsAction, getTriviaCategoriesAction } from "redux/L&D/actions"
import { learningDevelopmentActions } from "redux/L&D/slice/user"
import AddQuestions from "../components/AddQuestions"
import UploadQuestionModal from "./components/UploadAddUserModalAdmin"

const initialQuestionSet = {
  questionSet: [
    {
      uid: 0,
      duration: 60,
      question: "",
      category: "",
      difficultyLevel: "",
      optionSet: [{ uid: "00", name: "", isCorrect: false }]
    }
  ]
}

const NewContestAddQuestions = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const [questionSetData, setQuestionSetData] = useState(initialQuestionSet)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  const statusRef = useRef("Draft")
  const {
    questionsPublished,
    triviaId,
    questionImages = []
  } = useSelector((store) => store?.learningDevelopment?.trivia?.triviaContest?.newContest)
  const { uploadQuestionData } = useSelector((store) => store?.learningDevelopment || [])

  const handleSaveDraft = ({ handleSubmit }) => {
    statusRef.current = "Draft"
    handleSubmit()
  }

  const handlePublish = ({ handleSubmit }) => {
    statusRef.current = "Published"
    handleSubmit()
  }

  const handleSubmit = (values) => {
    setIsFormSubmitting(true)
    const payload = {
      questions: values.questionSet?.map((questionData, index) => ({
        title: questionData.question,
        category: questionData.category,
        ...(questionImages?.[index]?.id ? { image: questionImages[index].id } : {}),
        level: questionData.difficultyLevel,
        duration: questionData.duration,
        status: "Approved",
        answers: questionData.optionSet?.map((optionData) => ({
          answer: optionData.name,
          is_correct: optionData.isCorrect ? "True" : "False"
        }))
      }))
    }
    dispatch(
      addTriviaQuestionsAction({
        triviaId,
        data: payload,
        status: statusRef.current
      })
    )
  }

  const fetchDropdownData = () => {
    dispatch(getTriviaCategoriesAction())
  }

  const checkDisabled = (questions) =>
    new Set(questions?.map((el) => el?.category).filter((e) => e) || []).size < 5

  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ""
  }

  useEffect(() => {
    if (uploadQuestionData?.length) {
      setQuestionSetData({
        questionSet: uploadQuestionData?.map((item) => {
          return {
            uid: item?.id,
            duration: item?.duration,
            question: item?.title,
            category: item?.category,
            difficultyLevel: item?.level,
            optionSet: item?.answers?.map((ans) => {
              return {
                uid: ans?.id,
                name: ans?.answer,
                isCorrect: ans?.is_correct
              }
            })
          }
        })
      })
    } else {
      return
    }
  }, [uploadQuestionData?.length])

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser)
    return () => {
      window.removeEventListener("beforeunload", alertUser)
    }
  }, [])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    fetchDropdownData()
  }, [])

  useEffect(() => {
    if (!triviaId) {
      history.push("/learning_development/dashboard/trivia-contest/new-trivia-contest")
    }
    if (questionsPublished) {
      dispatch(learningDevelopmentActions.setQuestionsPublished(false))
      history.push("/learning_development/dashboard/trivia-contest")
    }
  }, [questionsPublished, triviaId])

  useLayoutEffect(() => {
    dispatch(learningDevelopmentActions.setUploadedImagesArr([]))
  }, [])

  const handleClose = () => {
    setModalOpen(false)
  }


  return (
    <Grid container>
      <UploadQuestionModal open={modalOpen} onClose={handleClose} isEdit={false}></UploadQuestionModal>
      <Prompt
        when={!isFormSubmitting}
        message={"Changes you made may not be saved. Are you sure you want to leave this page ?"}
      />
      <Formik
        enableReinitialize={true}
        initialValues={questionSetData}
        validationSchema={validationSchemaAddQuestions}
        onSubmit={handleSubmit}>
        {(formik) => (
          <Grid container>
            <Grid item xs={12}>
              <Header
                isBreadCrumbDisabled={true}
                back={false}
                title="January Trivia Contest"
                breadCrumbs={breadCrumbs}>
                <Typography
                  variant="body1Regular"
                  onClick={() => setModalOpen(true)}
                  sx={{
                    color: theme.palette.secondary.main,
                    textDecoration: "underline",
                    margin: "12px 10px",
                    cursor: "pointer",
                    textUnderlineOffset: "2px"
                  }}>
                  + Upload Bulk Questions
                </Typography>
                <Typography
                  variant="body1Regular"
                  onClick={() => handleSaveDraft(formik)}
                  sx={{
                    color: theme.palette.secondary.main,
                    textDecoration: "underline",
                    margin: "13px 30px",
                    cursor: "pointer",
                    textUnderlineOffset: "2px"
                  }}>
                  Save Draft
                </Typography>
                <AppButton
                  disabled={checkDisabled(formik?.values?.questionSet)}
                  onClick={() => handlePublish(formik)}
                  sx={{ padding: "13px 80px" }}>
                  Publish
                </AppButton>
              </Header>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography className="mb-2" variant="h2">
                  Note -
                </Typography>
              </Grid>
              <Grid item xs={12} className="mb-4">
                <Typography variant="body1Regular">
                  There should be at least 1 question in the Trivia Category. Preferably, the
                  greater number of questions you put in each category, the more variety of
                  questions the players get while playing a contest.
                </Typography>
              </Grid>
              <AddQuestions {...{ formik, questionImages }} />
            </Grid>
          </Grid>
        )}
      </Formik>
    </Grid>
  )
}

export default NewContestAddQuestions
