import React from "react"
import Card from "components/Card/Card"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Col, Row } from "react-bootstrap"
import { Typography, useTheme } from "@mui/material"
import classes from "./wineScroller.module.scss"
import AppButton from "components/StyledComponents/AppButton"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import { getSingleWineMonthDataAction } from "redux/marketing/actions"

function WineOfTheMonthScroller({
  details,
  onPrev = () => {},
  onNext = () => {},
  isTopDisabled,
  isBottomDisabled
}) {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { featureItemId } = useSelector((state) => state?.marketing?.featureItemMonth || {})
  const styles = {
    itemBox: {
      padding: "20px 10px",
      border: `1px solid `,
      borderColor: theme.palette.secondary.main,
      display: "flex",
      alignItems: "center",
      position: "relative",
      marginBottom: 16,
      borderRadius: 12,
      height: 120
    },
    itemBoxSpirit: {
      padding: "20px 10px",
      border: `1px solid `,
      borderColor: theme.palette.secondary.main,
      display: "flex",
      alignItems: "center",
      position: "relative",
      marginBottom: 16,
      borderRadius: 12,
      height: 120,
      background:
        "radial-gradient(53.47% 65.24% at 99.09% 90.85%, rgba(173, 145, 111, 0.20) 0%, rgba(173, 145, 111, 0.00) 100%), radial-gradient(62% 108.38% at 1.39% 0%, rgba(173, 145, 111, 0.20) 0%, rgba(139, 110, 75, 0.00) 100%), var(--V1, #231E1B)"
    },
    imgBottle: {
      height: "inherit"
    },
    buttonStyle: {
      border: "none",
      padding: "0",
      background: "transparent"
    }
  }

  function getItemId(id) {
    dispatch(marketingActions.setFeatureItemId(id))
    dispatch(getSingleWineMonthDataAction())
  }

  return (
    <Card>
      <Row>
        <Col lg="12" className="d-flex justify-content-center w-100 mb-2">
          <AppButton styles={styles.buttonStyle} onClick={onPrev} disabled={isTopDisabled}>
            <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
          </AppButton>
        </Col>
      </Row>
      {details?.map((item, i) => (
        <Row key={i}>
          <Col className="p-0" lg="12" onClick={() => getItemId(item?.id)}>
            <div
              style={styles.itemBox}
              className={`${classes.itemBox} ${
                featureItemId === item?.id ? classes.itemIsActive : ""
              }`}>
              <div className={[classes.wineBox]}>
                {item?.wine_image ? (
                  <img
                    style={styles.imgBottle}
                    className="img-fluid"
                    src={item?.wine_image}
                    alt=""
                  />
                ) : (
                  <div className="d-flex justify-content-center align-items-center text-center">
                    {" "}
                    <Typography sx={{ fontSize: 10, fontStyle: "italic" }} variant="body2Regular">
                      {"No Image Found"}
                    </Typography>
                  </div>
                )}
              </div>
              <div className="d-flex  justify-content-center flex-column">
                <div className={classes.textOverflow}>
                  <Typography variant="body2Regular">{item?.name ?? ""}</Typography>
                </div>
                <div>
                  <Typography variant="tag1" style={{ opacity: 0.6 }}>
                    {moment(item?.month).format("DD MMM YYYY") ?? ""}
                  </Typography>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      ))}

      <Row>
        <Col lg="12" className="d-flex justify-content-center mt-2">
          <AppButton styles={styles.buttonStyle} onClick={onNext} disabled={isBottomDisabled}>
            <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
          </AppButton>
        </Col>
      </Row>
    </Card>
  )
}

export default WineOfTheMonthScroller
