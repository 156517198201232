import React from "react"
import { Grid, styled, TextField, Typography } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"
import Tooltip from "@mui/material/Tooltip"

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root:before": {
    borderBottom: "none !important"
  },
  "& .MuiInput-root:before": {
    borderBottom: "none !important"
  },
  "& .Mui-focused:after": {
    transform: "none",
    border: `1px solid ${theme.palette.text.primary}`
  },
  "& .MuiInputBase-input": {
    padding: "12px 16px",
    color: "#FFFFFF",
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: 6,
    ...theme.typography.body1Regular,
    lineHeight: "24px",
    "&[type='time']": {
      cursor: "text",
      "&::-webkit-calendar-picker-indicator": {
        // filter:
        //   "invert(98%) sepia(100%) saturate(7%) hue-rotate(153deg) brchoose aightness(102%) contrast(100%)"
        display: "none"
      }
    },
    "&[type='search']": {
      cursor: "text",
      border: "none",
      padding: "12px 36px",
      background: theme.palette.drawer.main,
      borderBottom: "2px solid #212121",
      borderRadius: 6,
      "&::-webkit-calendar-picker-indicator": {
        // filter:
        //   "invert(98%) sepia(100%) saturate(7%) hue-rotate(153deg) brightness(102%) contrast(100%)"
        display: "none"
      },
      "&::-webkit-search-cancel-button": {
        appearance: "none",
        backgroundImage:
          "url(https://icones.pro/wp-content/uploads/2022/05/icone-fermer-et-x-rouge-1.png)",
        backgroundSize: "cover",
        height: "14px",
        width: "14px"
      }
    }
  },
  "& .Mui-error": {
    "& .MuiInputBase-input": {
      border: `1px solid ${theme.palette.error.main}`
    },
    "& .MuiIconButton-root": {
      border: `1px solid ${theme.palette.error.main}`,
      borderLeft: "none"
    }
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main
  },
  "& .MuiFilledInput-root": {
    "& .Mui-focused": {
      borderRadius: 6
    }  
  },
  "& .Mui-disabled": {
    "& .MuiInputBase-input": {
      WebkitTextFillColor: "#969698"
    }
  }
}))

function tootTileString(renderString) {
  return <p dangerouslySetInnerHTML={{ __html: renderString }}></p>
}

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 8,
  "& span": {
    color: theme.palette.error.main
  }
}))

const SearchTextField = ({
  label,
  isDisabled,
  isToolTip = false,
  isRequired,
  style = {},
  renderToolTip,
  className = "",
  placeholder,
  formStyle = {},
  isUpperCase = false,
  onChange = () => {},
  onBlur = () => {},
  value = "",
  ...props
}) => {
  return (
    <Grid container style={style} className={className}>
      {label ? (
        <StyledLabel variant="body1Regular">
          {!isUpperCase ? (label ?? "-").toUpperCase() : label ?? "-"}
          {isToolTip && (
            <Tooltip title={tootTileString(renderToolTip)}>
              <InfoIcon className="ms-1" fontSize="small"></InfoIcon>
            </Tooltip>
          )}
          {isRequired ? <span>*</span> : null}
        </StyledLabel>
      ) : null}
      <Grid item xs={12}>
        <StyledTextField
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{ disableUnderline: true }}
          variant="filled"
          disabled={isDisabled}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          style={formStyle}
          value={value}
          {...props}
        />
      </Grid>
    </Grid>
  )
}

export default SearchTextField
