import { Box, Grid, Typography, useTheme } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { Formik } from "formik"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { validationSchemaAddQuestions } from "helpers/validationSchema"
import React, { useEffect, useRef } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { getTriviaCategoriesAction } from "redux/L&D/actions"
import { learningDevelopmentActions } from "redux/L&D/slice/user"
import AddQuestions from "../components/AddQuestions"
import UploadQuestionModal from "../NewContest/components/UploadAddUserModalAdmin"
import QuestionNavBar from "components/QuestionNavBar"
import QuestionsBanks from "../../QuestionBank/components/QuestionBanks"
import GenerateQuestion from "components/GenerateQuestion"
import {
  addManualQuestionToBankAction,
  getAutoSuggestQuestionsAction
} from "redux/L&D/actions/user"

const defaultMenuItem = "ADD"

const questionManuItems = {
  ADD_QUESTION: "ADD",
  GENERATE_QUESTION: "GENERATE",
  AUTO_SUGGEST: "AUTO"
}

const initialQuestionSet = {
  questionSet: [
    {
      uid: 0,
      duration: 60,
      question: "",
      category: "",
      difficultyLevel: "",
      optionSet: [{ uid: "00", name: "", isCorrect: false }]
    }
  ]
}

const EditTriviaAddQuestions = (props) => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const statusRef = useRef("Draft")
  const [questionSetData, setQuestionSetData] = useState(initialQuestionSet)
  const [breadCrumbs, setBreadcrumbs] = useState([])
  // const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [modalOpen, setModalOpen] = useState(false)
  const {
    // questionsPublished,
    questionImages
  } = useSelector((store) => store?.learningDevelopment?.trivia?.triviaContest?.newContest)
  const { uploadQuestionData } = useSelector((store) => store?.learningDevelopment || [])
  const { contestData } = useSelector((store) => store?.learningDevelopment?.trivia?.editTrivia)
  const { menuLink, autoSuggestQuestions } = useSelector((store) => store?.learningDevelopment)
  const [filterQuestion, setFilterQuestion] = useState("ALL")

  const handleSubmit = (values) => {
    // setIsFormSubmitting(true)
    const payload = {
      questions: values.questionSet?.map((questionData, index) => ({
        title: questionData.question,
        category: questionData.category,
        ...(questionImages?.[index]?.id ? { image: questionImages[index].id } : {}),
        level: questionData.difficultyLevel,
        status: "Approved",
        answers: questionData.optionSet?.map((optionData) => ({
          answer: optionData.name,
          is_correct: optionData.isCorrect ? "True" : "False"
        })),
        is_moved_to_bank: true,
        is_added_manually: true,
        question_platform: "SCARPETTA"
      }))
    }
    dispatch(addManualQuestionToBankAction(payload?.questions))
    history.push("/learning_development/dashboard/question-bank")
  }

  const handlePublish = ({ handleSubmit }) => {
    statusRef.current = "Published"
    handleSubmit()
    dispatch(learningDevelopmentActions.setUploadQuestionsData([]))
  }

  const checkPublish = (questions) => {
    let existingCategories = props?.location?.state?.questionsCategories || []
    let totalCategories =
      questions
        ?.filter((el) => el?.status != "Pending")
        .map((el) => el?.categoryName)
        ?.filter((el) => el) || []
    return new Set([...existingCategories, ...totalCategories]).size < 5
  }

  const checkStatus = () => {
    if (contestData?.status?.toLowerCase() === "published") {
      return new Date(contestData?.start_date) > new Date()
    }
    return true
  }

  // const alertUser = (e) => {
  //   e.preventDefault()
  //   e.returnValue = ""
  // }

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser)
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser)
  //   }
  // }, [])

  useEffect(() => {
    if (menuLink === questionManuItems?.AUTO_SUGGEST) {
      dispatch(getAutoSuggestQuestionsAction({ page: currentPage, menu: filterQuestion }))
    }
    if (menuLink === questionManuItems.ADD_QUESTION) {
      dispatch(learningDevelopmentActions.setUploadQuestionsData([]))
    }
  }, [menuLink, currentPage, filterQuestion])

  useEffect(() => {
    if (uploadQuestionData?.length) {
      setQuestionSetData({
        questionSet: uploadQuestionData?.map((item) => {
          return {
            uid: item?.id,
            duration: item?.duration,
            question: item?.title,
            category: item?.category,
            difficultyLevel: item?.level,
            optionSet: item?.answers?.map((ans) => {
              return {
                uid: ans?.id,
                name: ans?.answer,
                isCorrect: ans?.is_correct
              }
            })
          }
        })
      })
    } else {
      return
    }
  }, [uploadQuestionData?.length])

  const handleClose = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    dispatch(getTriviaCategoriesAction())
    dispatch(learningDevelopmentActions.setUploadedImagesArr([]))
    dispatch(learningDevelopmentActions.setQuestionMenuLink(questionManuItems?.ADD_QUESTION))
  }, [])

  return (
    <Grid container>
      <UploadQuestionModal
        open={modalOpen}
        onClose={handleClose}
        isEdit={true}></UploadQuestionModal>
      {/* <Prompt
        when={!isFormSubmitting}
        message={"Changes you made may not be saved. Are you sure you want to leave this page ?"}
      /> */}
      <Formik
        enableReinitialize={true}
        initialValues={questionSetData}
        validationSchema={validationSchemaAddQuestions}
        onSubmit={handleSubmit}>
        {(formik) => (
          <Grid container>
            <Grid item xs={12}>
              <Header title="Trivia Contest" breadCrumbs={breadCrumbs}>
                {(menuLink === "" || questionManuItems?.ADD_QUESTION === menuLink) && (
                  <>
                    <Typography
                      variant="body1Regular"
                      onClick={() => setModalOpen(true)}
                      sx={{
                        color: theme.palette.secondary.main,
                        textDecoration: "underline",
                        margin: "12px 20px",
                        cursor: "pointer",
                        textUnderlineOffset: "2px"
                      }}>
                      + Upload Bulk Questions
                    </Typography>
                    <AppButton onClick={() => handlePublish(formik)} sx={{ padding: "13px 80px" }}>
                      Add To Question Bank
                    </AppButton>
                  </>
                )}
              </Header>
              <QuestionNavBar activeTab={menuLink === "" ? defaultMenuItem : menuLink} />
            </Grid>
            <Grid container className="mt-3">
              {(menuLink === "" || questionManuItems?.ADD_QUESTION === menuLink) && (
                <AddQuestions
                  formik={formik}
                  questionImages={questionImages ?? []}
                  checkStatus={checkStatus}
                  checkPublish={checkPublish}
                />
              )}
              {menuLink === questionManuItems?.GENERATE_QUESTION && (
                <Box sx={{ width: "100%" }}>
                  <GenerateQuestion />
                </Box>
              )}
              {menuLink === questionManuItems?.AUTO_SUGGEST && (
                <Box sx={{ width: "100%" }}>
                  <QuestionsBanks
                    title=""
                    questions={autoSuggestQuestions?.results}
                    pages={autoSuggestQuestions?.pages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isEditable={false}
                    isAutoSuggest={true}
                    questionFilter={true}
                    setFilterQuestion={setFilterQuestion}
                    filterQuestion={filterQuestion}
                    filterOptions={sortOption}
                    isDelete={true}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </Formik>
    </Grid>
  )
}

export default EditTriviaAddQuestions

const sortOption = [
  {
    label: "All",
    value: "ALL"
  },
  {
    label: "Dinner",
    value: "DINNER"
  },
  {
    label: "Bar Menu",
    value: "BAR_ITEMS"
  },
  {
    label: "Wine by Glass",
    value: "WINE_BY_GLASS"
  },
  {
    label: "Spirits",
    value: "SPIRITS"
  }
]
