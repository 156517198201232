import { Divider, Typography, List, Box } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"
import { Formik } from "formik"
import { validationSchemaEditContest } from "helpers/validationSchema"
import React, { useEffect, useRef } from "react"
import { Fragment } from "react"
import { useTheme } from "@mui/system"
import Header from "components/Header/Header"
import { Container, Row, Col } from "react-bootstrap"
import EditPendingQuestion from "../components/EditPendingQuestion"
import AppDatePicker from "components/AppDatePicker"
import { Prompt, useHistory } from "react-router-dom"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  editTriviaContestAction,
  getEditTriviaContestDetailsAction,
  getEditTriviaQuestionsAction
} from "redux/L&D/actions/user"
import { formatDate } from "helpers/functions"
import { learningDevelopmentActions } from "redux/L&D/slice/user"
import AppSelect from "components/StyledComponents/AppSelect"
import moment from "moment"
// import { toast } from "react-toastify";

const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

function EditTrivia() {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const statusRef = useRef("Pending")
  const { contestData, questions, isContestEdited } = useSelector(
    (store) => store?.learningDevelopment?.trivia?.editTrivia
  )
  const [weekDaysChoices, setWeekDaysChoices] = useState([])
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [initialValues, setInitialValues] = useState({
    name: "",
    scope: "All Restaurant",
    created_by: "",
    start_date: "",
    creation_date: ""
  })
  const [breadCrumbs, setBreadcrumbs] = useState([])

  const styles = {
    heading: {
      color: theme.palette.text.secondary
    },
    btn: {
      padding: "13px 80px"
    },
    required: {
      color: theme.palette.error.main
    }
  }

  const handleSaveAsDraft = ({ handleSubmit }) => {
    statusRef.current = "Draft"
    handleSubmit()
  }

  const handlePublish = ({ handleSubmit }) => {
    statusRef.current = "Published"
    handleSubmit()
  }

  const handleAddQuestions = ({ handleSubmit }) => {
    statusRef.current = "AddQuestions"
    handleSubmit()
  }

  const handleSubmit = (values) => {
    // if (statusRef.current === "Published" && checkPublish()) {
    //   return toast.error(
    //     "At least one question per category is required to publish the contest",
    //     {
    //       position: "bottom-right",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: false,
    //       draggable: false,
    //       progress: undefined,
    //       theme: "dark"
    //     }
    //   );
    // }
    setIsFormSubmitting(true)

    if (typeof values.start_date._d == "object") {
      values.start_date = values.start_date._d
    }
    const payload = {
      name: values.name,
      published_week_day: values.published_week_day,
      start_date: formatDate(
        new Date(values.start_date.getFullYear(), values.start_date.getMonth(), 1)
      ),
      end_date: formatDate(
        new Date(values.start_date.getFullYear(), values.start_date.getMonth() + 1, 0)
      ),
      ...(statusRef.current !== "AddQuestions" ? { status: statusRef.current } : {})
    }

    dispatch(editTriviaContestAction(payload))
  }

  const checkStatus = () => {
    if (contestData?.status?.toLowerCase() === "published") {
      return new Date(contestData?.start_date) > new Date()
    }
    return true
  }

  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ""
  }

  // const checkPublish = () =>
  //   new Set(
  //     questions?.filter((el) => el?.status != "Pending")?.map((e) => e.category)
  //   ).size < 5;

  useEffect(() => {
    if (isContestEdited) {
      dispatch(learningDevelopmentActions.setIsContestEdited(false))
      if (statusRef.current === "AddQuestions") {
        history.replace({
          pathname: "/learning_development/dashboard/question-bank",
          state: {
            questionsCategories: new Set(questions?.map((e) => e.category))
          }
        })
      } else {
        history.push("/learning_development/dashboard/trivia-contest")
      }
    }
  }, [isContestEdited])

  useEffect(() => {
    const { name, created_by, start_date, creation_date, published_week_day } = {
      ...(contestData ?? {})
    }
    setInitialValues({
      name,
      scope: "All Restaurant",
      created_by,
      published_week_day,
      start_date: moment(start_date).utc(),
      creation_date: moment(creation_date).utc()
    })
  }, [contestData])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Trivia Contest"
    dispatch(getEditTriviaContestDetailsAction())
    dispatch(getEditTriviaQuestionsAction())
    setWeekDaysChoices(
      weekDays.map((weekName) => ({
        label: `Every ${weekName}`,
        value: weekName.toUpperCase()
      }))
    )
  }, [])

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser)
    return () => {
      window.removeEventListener("beforeunload", alertUser)
    }
  }, [])

  return (
    <Fragment>
      <Prompt
        when={!isFormSubmitting}
        message={"Changes you made may not be saved. Are you sure you want to leave this page ?"}
      />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={validationSchemaEditContest}>
        {(formik) => (
          <Fragment>
            <Container fluid>
              <Row>
                <Col lg="12">
                  <Header title="Trivia Contest & Questions" breadCrumbs={breadCrumbs}>
                    {checkStatus() ? (
                      <Typography
                        variant="body1Regular"
                        onClick={() => handleSaveAsDraft(formik)}
                        sx={{
                          color: theme.palette.secondary.main,
                          textDecoration: "underline",
                          margin: "13px 50px",
                          cursor: "pointer",
                          textUnderlineOffset: "2px"
                        }}>
                        Save Draft
                      </Typography>
                    ) : null}
                    <AppButton onClick={() => handlePublish(formik)} styles={styles.btn}>
                      Publish
                    </AppButton>
                  </Header>
                </Col>
              </Row>
            </Container>
            <Container fluid>
              <Row>
                <Col lg="4">
                  <AppTextField
                    id="name"
                    name="name"
                    fullWidth
                    label="Contest Name"
                    isRequired={true}
                    placeholder="Enter Contest Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Col>
                <Col lg="4">
                  <AppTextField
                    id="scope"
                    name="scope"
                    isDisabled={true}
                    fullWidth
                    label="Scope"
                    isRequired={false}
                    placeholder="Enter Scope"
                    value={formik.values.scope}
                    onChange={formik.handleChange}
                    error={formik.touched.scope && Boolean(formik.errors.scope)}
                    helperText={formik.touched.scope && formik.errors.scope}
                  />
                </Col>
                <Col lg="4">
                  <List sx={{ padding: 0 }}>
                    <Box>
                      <Typography variant="body1Regular" sx={styles.heading}>
                        DURATION
                      </Typography>
                      <span style={styles.required}>*</span>
                    </Box>
                    <Box sx={{ display: "flex", marginTop: "5px" }} className="datesContainer">
                      <Col lg="6" className="me-2 dateFieldContainer">
                        <AppDatePicker
                          views={["year", "month"]}
                          inputFormat="MMM yyyy"
                          key={"start_date"}
                          disabled={!checkStatus()}
                          value={formik.values.start_date}
                          minDate={new Date()}
                          onChange={(val) => formik.setFieldValue("start_date", val)}
                          error={
                            !checkStatus()
                              ? "Duration is not Editable for Ongoing Contest"
                              : formik.errors.start_date
                          }
                          touched={!checkStatus() ? false : formik.touched.start_date}
                        />
                      </Col>
                    </Box>
                  </List>
                </Col>
              </Row>
              <Divider className="mt-4"></Divider>
            </Container>
            <Container fluid className="mt-4 ">
              <Typography className="my-4">Publisher Information</Typography>
              <Row>
                <Col lg="4" style={{ paddingRight: 12 }}>
                  <Box>
                    <List sx={{ padding: 0 }}>
                      <Box className="pb-1">
                        <Typography variant="body1Regular" sx={styles.heading}>
                          {"Creation Date".toUpperCase()}
                        </Typography>
                        <span style={styles.required}>*</span>
                      </Box>
                      <Box sx={{ display: "flex", marginTop: "5px" }} className="datesContainer">
                        <Col lg="12" className="dateFieldContainer">
                          <AppDatePicker
                            key="creation_date"
                            disabled
                            value={formik.values.creation_date}
                            onChange={(val) => formik.setFieldValue("creation_date", val)}
                            error={formik.errors.creation_date}
                            touched={formik.touched.creation_date}
                          />
                        </Col>
                      </Box>
                    </List>
                  </Box>
                </Col>
                <Col lg="4">
                  <AppTextField
                    id="created_by"
                    name="created_by"
                    fullWidth
                    label="Created By"
                    isDisabled={true}
                    placeholder="Enter Created By"
                    value={formik.values.created_by}
                    onChange={formik.handleChange}
                    error={formik.touched.created_by && Boolean(formik.errors.created_by)}
                    helperText={formik.touched.created_by && formik.errors.created_by}
                  />
                </Col>
                <Col lg="4">
                  <AppSelect
                    id="published_week_day"
                    name="published_week_day"
                    disabled={true}
                    menuItems={weekDaysChoices}
                    label="Publish Weekly On"
                    value={formik.values.published_week_day}
                    onChange={formik.handleChange}
                    error={
                      !checkStatus()
                        ? "Publish Weekly On is not Editable for Ongoing Contest"
                        : formik.errors.published_week_day
                    }
                    touched={!checkStatus() ? false : formik.touched.published_week_day}
                  />
                </Col>
              </Row>
              <Divider className="mt-4"></Divider>
            </Container>
            <Container fluid className="mt-4">
              <Row>
                <Col lg="12" className="d-flex justify-content-between">
                  <Typography variant="h2">Questions</Typography>
                  <AppButton onClick={() => handleAddQuestions(formik)} styles={styles.btn}>
                    Add Questions
                  </AppButton>
                </Col>
              </Row>
            </Container>
          </Fragment>
        )}
      </Formik>
      <Container fluid className="mt-4">
        <Row>
          <Col lg="12">
            <EditPendingQuestion
              isEdit={contestData.status === "Published" ? true : false}
              isCorrectAnswer={true}
              rows={questions ?? []}
              columns={PendingColumns}
              contestData={contestData}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default EditTrivia

const PendingColumns = [
  {
    id: "S.No",
    label: "S.No",
    minWidth: 35
  },
  {
    id: "Questions",
    label: "Questions",
    minWidth: 50
  },
  {
    id: "Status",
    label: "Status",
    align: "center",
    minWidth: 120
  },
  {
    id: "Trivia Category",
    label: " Trivia Category",
    align: "center",
    minWidth: 100
  },
  {
    id: "Difficulty Level",
    label: "Difficulty Level",
    align: "center",
    minWidth: 100
  },
  {
    id: "Action",
    label: "Action",
    align: "center",
    minWidth: 70
  }
]
