import React from "react";
import { List, Typography, Breadcrumbs, Box } from "@mui/material";
import { useTheme } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useHistory } from "react-router-dom";
import classes from "./Header.module.scss";
import NotificationsIcon from "../../assets/icons/bell.svg";
import blueBell from "../../assets/icons/blueBell.svg";
import settingsIcon from "../../assets/icons/settingsBlue.svg";
import AppButtonFilled from "components/StyledComponents/AppButtonFilled";
function Header({
  breadCrumbs,
  notificationIcon = false,
  isNotify = false,
  title,
  children,
  back = true,
  isSettings = false,
  style = {},
  settingsRoute,
  isEnterprise = false,
  enterpriseRoute,
  isBreadCrumbDisabled = false
}) {
  const theme = useTheme();
  const history = useHistory();
  // const role = useSelector((res) => res?.user?.userData?.role);
  const styles = {
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "12px",
      marginBottom: "24px",
      ...style
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start"
    },
    icon: {
      position: "relative",
      top: "13px",
      right: "2px",
      marginRight: "10px",
      cursor: "pointer"
    },
    title: {
      color: theme.palette.text.primary
    }
  };
  return (
    <div style={styles.header}>
      <Box sx={styles.titleContainer}>
        {back && (
          <ArrowBackIcon
            onClick={() => history.goBack()}
            sx={styles.icon}
            fontSize="medium"
          />
        )}
        <List>
          <Typography variant="h2" sx={styles.title}>
            {title}
          </Typography>
          {breadCrumbs && (
            <Breadcrumbs>
              {breadCrumbs.map((item, i, arr) => {
                if (arr.length - 1 === i) {
                  return (
                    <Link
                      key={item.id}
                      to={isBreadCrumbDisabled ? "#" : item.href}
                      color={item.color}>
                      <Typography
                        sx={{
                          color: `${theme.palette.secondary.default}`,
                          opacity: `${isBreadCrumbDisabled ? "0.4" : "1"}`
                        }}
                        variant="body2Light">
                        {item.label}
                      </Typography>
                    </Link>
                  );
                } else {
                  return (
                    <Box
                      key={item.id}
                      sx={{
                        "& a:hover": {
                          color: `${theme.palette.secondary.default} !important`
                        }
                      }}>
                      {isBreadCrumbDisabled ? (
                        <Typography
                          sx={{
                            color: `${theme.palette.text.main}`,
                            opacity: "0.4"
                          }}
                          variant="body2Light">
                          {item.label}
                        </Typography>
                      ) : (
                        <Link
                          to={item.href}
                          color={item.color}>
                          <Typography
                            sx={{
                              color: `${theme.palette.text.main}`,
                            }}
                            variant="body2Light">
                            {item.label}
                          </Typography>
                        </Link>
                      )}
                    </Box>
                  );
                }
              })}
            </Breadcrumbs>
          )}
        </List>
      </Box>
      <Box className="d-flex justify-content-end">
        {children}
        {notificationIcon && (
          <Link to={notificationIcon}>
            <div className={classes.notificationBox}>
              <div className={classes.bellBox}>
                <img
                  src={
                    history?.location?.pathname?.includes("DMV")
                      ? blueBell
                      : NotificationsIcon
                  }
                  alt=""
                />
                {isNotify && <span className={classes.bellAlert}></span>}
              </div>
            </div>
          </Link>
        )}
        {isSettings && (
          <Link to={settingsRoute}>
            <div className={classes.notificationBox}>
              <div className={classes.bellBox}>
                <img src={settingsIcon} alt="" />
              </div>
            </div>
          </Link>
        )}
        {isEnterprise && (
          <Link to={enterpriseRoute}>
            <AppButtonFilled>+ Add Enterprise Client</AppButtonFilled>
          </Link>
        )}
      </Box>
    </div>
  );
}

export default Header;
