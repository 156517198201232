import React, { useState } from "react"
import classes from "pages/private/marketing/menu/menu.module.scss"
import { Menu, MenuItem, Typography } from "@mui/material"
import ImageDisplay from "components/ImageDisplayer"
// import ReadMoreReact from "read-more-react"
import { useTheme } from "@mui/system"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { deleteMenuItemAction } from "redux/marketing/actions"
import { useEffect } from "react"
import Paragraph from "components/ReadMoreParagraph"
import unSelect from "assets/icons/unSelectedRectange.svg"
import selectedItem from "assets/icons/SelectedRectange.svg"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import AddMenuItems from "pages/private/marketing/menu/AddMenuItems"
import { getFoodItemsAction, updateFoodItemsAction } from "redux/marketing/actions/user"

function ArchivedMenuListItems({ foodList, isEdit, selectedItems, handleSelectItem }) {
  const theme = useTheme()
  const [modal, setDeleteModal] = useState(false)
  const { archived } = useSelector((store) => store?.marketing?.menu ?? {})
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [isEditModalItem, setIsEditModalItem] = useState(false)
  const [menuItemsModal, setMenuItemsModal] = useState(false)
  const [unarchiveModal, setUnarchiveItemModal] = useState(false)

  const checkSelectedItems = () => {
    const isItemSelected = selectedItems?.food_list?.filter((item) => item?.id === foodList?.id)
    if (isItemSelected?.length > 0) {
      return true
    } else {
      return false
    }
  }

  function handleMenuItemDelete() {
    dispatch(deleteMenuItemAction())
  }

  function handleCloseModal() {
    setDeleteModal(false)
    dispatch(marketingActions.setMenuItemId(""))
  }

  useEffect(() => {}, [])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event, id, categoryId) => {
    setAnchorEl(event.currentTarget)
    dispatch(marketingActions.setMenuItemId(id))
    dispatch(marketingActions.setMenuCategoryId(categoryId))
    dispatch(getFoodItemsAction())
  }

  function handleMenuDeleteModal() {
    handleClose()
    setDeleteModal(true)
    setAnchorEl(null)
    dispatch(marketingActions.setisArchive(true))
  }

  function handleEditMenuItemModal() {
    setAnchorEl(null)
    setIsEditModalItem(true)
    setMenuItemsModal(true)
    dispatch(marketingActions.setisArchive(true))
  }

  function handleCloseMenuItems() {
    setMenuItemsModal(false)
    setIsEditModalItem(false)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }

  function handleArchiveItem() {
    dispatch(marketingActions.setisArchive(true))
    setUnarchiveItemModal(true)
    setAnchorEl(null)
  }

  function handleCloseArchiveModal() {
    setUnarchiveItemModal(false)
  }

  function handleUnarchiveMenuItem() {
    const payload = {
      item_state: "available"
    }
    dispatch(updateFoodItemsAction(payload))
  }

  return (
    <>
      <AddMenuItems isEdit={isEditModalItem} open={menuItemsModal} onClose={handleCloseMenuItems} />
      <DeleteConfirmationDialog
        title={`Are You Sure You Want To Delete This Dish ?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseModal}
        onDelete={() => handleMenuItemDelete()}
        open={modal}></DeleteConfirmationDialog>
      <DeleteConfirmationDialog
        title={`Are You Sure You Want To Unarchive This Dish?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseArchiveModal}
        onDelete={() => handleUnarchiveMenuItem()}
        open={unarchiveModal}
        btnText="Unarchive"
        type="warning"
      />
      <div
        className={archived ? classes.archivedItemContainer : classes.itemContainer}
        style={{
          border: `${isEdit ? `1px solid ${theme.palette.secondary.main}` : "none"}`,
          borderRadius: `${isEdit ? "6px" : "0px"}`,
          padding: `${isEdit ? "8px" : "0px"}`
        }}>
        <div>
          {checkSelectedItems() ? (
            <img
              src={selectedItem}
              alt="select"
              style={{ cursor: "pointer" }}
              onClick={() => handleSelectItem(foodList?.id)}
            />
          ) : (
            <img
              src={unSelect}
              alt="select"
              style={{ cursor: "pointer" }}
              onClick={() => handleSelectItem(foodList?.id)}
            />
          )}
        </div>
        <div className={classes.imageContainer}>
          <ImageDisplay data={foodList?.image}></ImageDisplay>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: 375,
            overflowWrap: "break-word"
          }}>
          <div>
            <Typography variant="h4">{foodList?.name ?? "-"}</Typography>

            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
              {foodList?.menu_description ?? "-"}
            </Typography>
          </div>

          <div>
            <div className="mt-2">
              <Typography variant="tag1">{"Menu Description:"}</Typography>
              <br></br>
              <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                {foodList?.menu_description ?? "-"}
              </Typography>
            </div>
            <div className="mt-2">
              <Typography variant="tag1">{"Allergens:"}</Typography>
              <br></br>
              <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                {foodList?.allergance?.length > 0
                  ? foodList?.allergance?.map((item, index) => {
                      return foodList?.allergance?.length - 1 > index
                        ? `${item?.name}, `
                        : `${item?.name}`
                    })
                  : ""}
              </Typography>
            </div>
            <div className="mt-2">
              <Typography variant="tag1">{"Chef’s Description:"}</Typography>
              <br></br>
              <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                {<Paragraph text={foodList?.description ?? ""} maxCharCount={70}></Paragraph>}
              </Typography>
            </div>
          </div>
        </div>
        {isEdit && (
          <div>
            <MoreVertIcon
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => handleClick(e, foodList?.id)}
              sx={{ cursor: "pointer" }}></MoreVertIcon>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}>
              <MenuItem onClick={handleEditMenuItemModal} sx={{ width: "150px" }}>
                Edit
              </MenuItem>
              <MenuItem onClick={() => handleArchiveItem()} sx={{ width: "150px" }}>
                Unarchive
              </MenuItem>
              <MenuItem
                onClick={handleMenuDeleteModal}
                sx={{ width: "150px", color: theme.palette.red.main }}>
                Delete
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
    </>
  )
}

export default ArchivedMenuListItems
