import React, { useState } from "react"
import Card from "components/Card/Card"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import { Typography } from "@mui/material"
import { useTheme } from "@mui/system"
import { useDispatch } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { DeleteMenuCategoryAction } from "redux/marketing/actions"
import ArchivedMenuListItems from "components/ArchivedMenuListItems"
import UnarchiveItem from "assets/icons/UnarchiveItem.svg"
import SelectAll from "assets/icons/SelectAllRectange.svg"
import { isEmptyObject } from "helpers/functions"
import selectedItem from "assets/icons/SelectedRectange.svg"
import { unArchiveItemsAction } from "redux/marketing/actions/user"
import { toast } from "react-toastify"

function ArchivedMenuListContainer({
  data,
  isEdit = false,
  setIsEditModalItem = () => {},
  setMenuItemsModal = () => {}
}) {
  const theme = useTheme()
  const [modal, setDeleteModal] = useState(false)
  const dispatch = useDispatch()
  const [selectedItems, setSelectedItems] = useState({})

  function handleMenuPeriodDelete(id) {
    dispatch(marketingActions.setMenuCategoryId(id))
    dispatch(DeleteMenuCategoryAction({ mealCategoryId: id }))
  }

  const handleSelect = (item) => {
    if (isEmptyObject(selectedItems)) {
      setSelectedItems(item)
    } else {
      setSelectedItems({})
    }
  }

  const handleUnArchive = () => {
    if (isEmptyObject(selectedItems)) {
      toast.error("Please select items to unarchive!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    } else {
      const id_dict = selectedItems?.food_list?.map((item) => ({
        id: item.id,
        food_type: "food"
      }))
      console.log(id_dict)
      const payload = {
        id_dict
      }
      dispatch(unArchiveItemsAction(payload))
    }
  }

  const handleSelectItem = (id) => {
    if (isEmptyObject(selectedItems)) {
      setSelectedItems({ ...data, food_list: data?.food_list?.filter((item) => item?.id === id) })
    } else {
      const itemsData = selectedItems?.food_list?.filter((item) => item?.id === id)
      if (itemsData?.length > 0) {
        if (selectedItems?.food_list?.length === 1) {
          setSelectedItems({})
        } else {
          setSelectedItems({
            ...selectedItems,
            food_list: selectedItems?.food_list?.filter((item) => item?.id !== id)
          })
        }
      } else {
        setSelectedItems({
          ...selectedItems,
          food_list: [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...selectedItems?.food_list,
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...data?.food_list?.filter((item) => item?.id === id)
          ]
        })
      }
    }
  }

  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to Delete “${data?.food_category ?? ""}”?`}
        description={`"${data?.food_category ?? ""}" has ${data?.food_list?.length ?? ""} ${
          data?.food_list?.length <= 1 ? "dish" : "dishes"
        }`}
        style={{ textAlign: "center", width: "80%" }}
        onClose={() => setDeleteModal(false)}
        onDelete={() => handleMenuPeriodDelete(data?.food_category_id)}
        open={modal}></DeleteConfirmationDialog>

      <Card style={{ height: "fit-content" }}>
        <Row>
          <Col lg="12">
            <div
              className="d-flex justify-content-between align-item-center"
              style={{
                backgroundColor: isEdit && theme.palette.background.default,
                padding: ` ${isEdit ? "12px 16px" : "0px"}`,
                borderRadius: 6
              }}>
              <img
                src={
                  selectedItems?.food_list?.length === data?.food_list?.length
                    ? selectedItem
                    : SelectAll
                }
                alt="selectAll"
                style={{ cursor: "pointer" }}
                onClick={() => handleSelect(data)}
              />
              <Typography variant="h2">{data?.food_category}</Typography>
              <div className="d-flex align-items-center">
                <img
                  src={UnarchiveItem}
                  alt="unarchive"
                  style={{ cursor: "pointer" }}
                  onClick={handleUnArchive}
                />
              </div>
            </div>
          </Col>
          <Col lg="12">
            {data?.food_list?.length ? (
              data?.food_list?.map((items, idx) => {
                return (
                  <ArchivedMenuListItems
                    categoryIdData={data?.food_category_id}
                    setIsEditModalItem={setIsEditModalItem}
                    setMenuItemsModal={setMenuItemsModal}
                    isEdit={isEdit}
                    key={idx}
                    selectedItems={selectedItems}
                    handleSelectItem={handleSelectItem}
                    foodList={items}></ArchivedMenuListItems>
                )
              })
            ) : (
              <Typography className="mt-4" style={{ opacity: 0.6, textAlign: "center" }}>
                No Items Found
              </Typography>
            )}
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default React.memo(ArchivedMenuListContainer)
