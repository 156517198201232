import React, { Fragment } from "react";
import { Tooltip, Typography } from "@mui/material";
import AnalyticsIcon from '@mui/icons-material/Analytics';
function ActivePlayers({ tooltip, data, icon, isStats = false, onClick = () => { } }) {
  const styles = {
    number: {
      height: "12px",
      marginBottom: "4px",
      fontSize: 14,
      '@media (min-width: 2000px)': {
        fontSize: 20,
      },
    }
  };

  return (
    <Tooltip title={tooltip} arrow>
      <div className="icon-column">
        {data ? (
          <Fragment>
            {icon ? <img src={icon} onClick={onClick} className="icon" /> :
              <AnalyticsIcon sx={{fontSize:"16px",color:"#9CC43B"}} onClick={onClick}></AnalyticsIcon>
            }
            <Typography sx={styles.number} variant="tag1">
              {!isStats ? data : ""}
            </Typography>
          </Fragment>
        ) : (
          // Render nothing if no active players
          <div style={{ height: "32px" }}></div>
        )}
      </div>
    </Tooltip>
  );
}

export default ActivePlayers;
