import axios from "axios"
import { call, put, select } from "redux-saga/effects"
import { toast } from "react-toastify"
import slice from "../slice/user"
import calendarSlice from "../../L&D/slice/user"
import { appActions } from "redux/app/slice/app"
import { formatDate } from "helpers/functions"
import { GMActions } from "redux/GM/slice/user"
import DMV_Slice from "../../DMV/slice/user"
import moment from "moment"

const baseUrl = process.env.REACT_APP_API_MY_URL

export function* raffleTicket(action) {
  try {
    yield call(axios.post, `${baseUrl}/frontline/raffle/`, action.payload)
    const { data } = yield call(axios.get, `${baseUrl}/frontline/raffle/`)
    if (data.success) {
      yield put(slice.actions.setRaffleListData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadWineOfTheMonthThumbnail(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const id = toast.loading("Uploading Image", {
      position: "bottom-right",
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      theme: "dark"
    })
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      toast.update(id, {
        render: "Image Uploaded",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        progress: undefined,
        theme: "dark"
      })
      yield put(slice.actions.setWineMonthVideoThumbnail(data.data.id))
    }
  } catch (e) {
    console.log(e)
  }
}
export function* uploadPDFThumbnail(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const id = toast.loading("Uploading Image", {
      position: "bottom-right",
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      theme: "dark"
    })
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      toast.update(id, {
        render: "Image Uploaded",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        progress: undefined,
        theme: "dark"
      })
      yield put(slice.actions.setWineMonthPDFThumbnail(data.data.id))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadWineOfTheMonth(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const date = yield select((store) => store.app?.filters?.date)
    const { data } = yield call(axios.post, `${baseUrl}/frontline/featured-item/`, action.payload)
    if (data.success) {
      toast.success("Featured Item of the month created", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      const dateObject = new Date(date)
      const newDate = new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, 0)
      const { data } = yield call(
        axios.get,
        `${baseUrl}/frontline/featured-item/?month=${formatDate(newDate)}`
      )
      if (data.success) {
        yield put(appActions.setIsLoading(false))
        yield put(slice.actions.setWineMonthListData(data.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getListWineMonth(action) {
  try {
    const { date } = action.payload
    let params = date ? `month=${date}` : `month=${moment(new Date()).format("YYYY-MM-DD")}`
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/featured-item/?${params}`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setWineMonthListData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* deleteWineOfTheMonthItem(action) {
  yield put(appActions.setIsLoading(true))
  const date = yield select((store) => store.app?.filters?.date)
  try {
    yield call(axios.delete, `${baseUrl}/frontline/featured-item/${action.payload}`)
    const dateObject = new Date(date)
    const newDate = new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, 0)
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/featured-item/?month=${formatDate(newDate)}`
    )
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setWineMonthListData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getSingleWineMonthData() {
  try {
    const id = yield select((store) => store?.marketing?.featureItemMonth?.featureItemId ?? "")
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/featured-item/${id}`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setGetSingleWineMonthData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* editWineOfTheMonthItem(action) {
  const date = yield select((store) => store.app?.filters?.date)
  try {
    yield put(appActions.setIsLoading(true))
    const id = yield select((store) => store.marketing?.featureItemMonth?.featureItemId)
    yield call(axios.patch, `${baseUrl}/frontline/featured-item/${id}`, action.payload)
    const dateObject = new Date(date)
    const newDate = new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, 0)
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/featured-item/?month=${formatDate(newDate)}`
    )
    if (data.success) {
      const { data: singleData } = yield call(axios.get, `${baseUrl}/frontline/featured-item/${id}`)
      yield put(slice.actions.setGetSingleWineMonthData(singleData.data))
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setWineMonthListData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* editRaffleContest(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const raffleID = yield select((store) => store?.marketing?.raffleContestData.id)

    yield call(axios.patch, `${baseUrl}/frontline/raffle/${raffleID}/`, action.payload)

    const { data } = yield call(axios.get, `${baseUrl}/frontline/raffle/`)
    if (data.success) {
      yield put(slice.actions.setRaffleListData(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getRaffleListData() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/raffle/`)
    if (data.success) {
      yield put(slice.actions.setRaffleListData(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* deleteRaffleContest() {
  const raffleId = yield select((store) => store.marketing?.raffleContestData?.id)
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/frontline/raffle/${raffleId}/`)
    const { data } = yield call(axios.get, `${baseUrl}/frontline/raffle/`)
    if (data.success) {
      yield put(slice.actions.setRaffleListData(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getRaffleSingleData() {
  try {
    yield put(appActions.setIsLoading(true))
    const raffleId = yield select((store) => store.marketing?.raffleContestData?.id)
    const { data } = yield call(axios.get, `${baseUrl}/frontline/raffle/${raffleId}`)
    if (data.success) {
      yield put(slice.actions.setRaffleSingleData(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* addCommentCalender(action) {
  const { filters } = yield select((store) => store?.app || {})
  const dateObject = new Date(filters?.date)
  const newDate = new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, 0)
  let params = `date=${formatDate(newDate)}`
  if (filters?.restaurant && filters?.restaurant !== "All") {
    params += `&restaurant=${filters?.restaurant}`
  }
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/trivia-note/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      const { data: calendarData } = yield call(
        axios.get,
        `${baseUrl}/trivia/calendar-api/?${params}`
      )
      if (calendarData.success) {
        yield put(appActions.setIsLoading(false))
        yield put(calendarSlice.actions.setCalendarData(calendarData.data || []))
        yield put(GMActions.setGMCalendarData(calendarData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* EditCommentCalender(action) {
  const { filters } = yield select((store) => store?.app || {})
  const dateObject = new Date(filters?.date)
  const newDate = new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, 0)
  let params = `date=${formatDate(newDate)}`
  if (filters?.restaurant && filters?.restaurant !== "All") {
    params += `&restaurant=${filters?.restaurant}`
  }
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/trivia/trivia-note/${action.payload.id}/`,
      {
        note: action.payload.note
      }
    )
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      const { data: calendarData } = yield call(
        axios.get,
        `${baseUrl}/trivia/calendar-api/?${params}`
      )
      if (calendarData.success) {
        yield put(appActions.setIsLoading(false))
        yield put(calendarSlice.actions.setCalendarData(calendarData.data || []))
        yield put(GMActions.setGMCalendarData(calendarData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getMenuPeriod(action) {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const archived = action?.payload
  const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}${
        archived ? `&item_state=archived` : ""
      }`
    )
    if (data.success) {
      yield put(slice.actions.setMenuPeriodData(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* editMenuCategory(action) {
  const { food_category, mealCategoryId, meal_period, name } = action.payload
  const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/sub-category/${mealCategoryId}`,
      {
        meal_period: meal_period,
        food_category: food_category,
        name: name
      }
    )
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}`
      )
      if (newData.success) {
        yield put(slice.actions.setMenuPeriodData(newData.data))
        yield put(appActions.setIsLoading(false))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* addMenuCategory(action) {
  const { food_category, meal_period, name } = action.payload
  const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/sub-category/`, {
      meal_period: meal_period,
      food_category: food_category,
      name: name
    })
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setMenuCategoryId(data.data.id))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}`
      )
      if (newData.success) {
        yield put(slice.actions.setMenuPeriodData(newData.data))
        yield put(appActions.setIsLoading(false))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* addMenuItems(action) {
  const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    const { payload, onClose } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/food-item/`, payload)
    if (data.success) {
      onClose()
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setMenuCategoryId(data.data.id))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}`
      )
      if (newData.success) {
        yield put(slice.actions.setMenuPeriodData(newData.data))
        yield put(appActions.setIsLoading(false))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* deleteMenuCategory(action) {
  const { mealCategoryId } = action.payload
  const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.delete, `${baseUrl}/frontline/sub-category/${mealCategoryId}`)
    console.log(data)

    yield put(appActions.setIsLoading(false))
    const { data: newData } = yield call(
      axios.get,
      `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}`
    )
    if (newData.success) {
      yield put(slice.actions.setMenuPeriodData(newData.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* deleteMenuItem() {
  const Id = yield select((store) => store?.marketing?.menu?.menu_item_ID || {})
  const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.delete, `${baseUrl}/frontline/food-item/${Id}`)
    console.log(data)

    yield put(appActions.setIsLoading(false))
    const { data: newData } = yield call(
      axios.get,
      `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}${
        isArchive === true ? `&item_state=archived` : ""
      }`
    )
    if (newData.success) {
      yield put(slice.actions.setMenuPeriodData(newData.data))
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setisArchive(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getFoodItems() {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}`)
    if (data.success) {
      yield put(slice.actions.setFoodItemsDetail(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* updateFoodItems(action) {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    const { payload, onClose } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}`,
      payload
    )
    if (data.success) {
      onClose()
      yield put(slice.actions.setFoodItemsDetail(data.data))
      yield put(appActions.setIsLoading(false))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}${
          isArchive === true ? `&item_state=archived` : ""
        }`
      )
      if (newData.success) {
        yield put(slice.actions.setMenuPeriodData(newData.data))
        yield put(appActions.setIsLoading(false))
      }
      yield put(slice.actions.setisArchive(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadMenuItem(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const id = toast.loading("Uploading Image", {
      position: "bottom-right",
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      theme: "dark"
    })
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      toast.update(id, {
        render: "Image Uploaded",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        progress: undefined,
        theme: "dark"
      })
      yield put(slice.actions.setMenuImageId(data.data.id))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadTastingNotesImages(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload.data)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      toast.success("Image Uploaded", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      yield put(
        slice.actions.setTastingNotesImages({
          index: action.payload.indexValue,
          id: data.data.id
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getLeaderboardData(action) {
  const { date, page, employeeClass, orderBy } = action.payload
  let params = `?month=${date}&page=${page}`
  if (employeeClass && employeeClass !== "All") {
    params += `&job_class=${employeeClass}`
  }
  if (orderBy) {
    params += `&ordering=${orderBy}`
  }
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/trivia/advance-leaderboard/${params}`)
    if (data.success) {
      yield put(slice.actions.setLoaderBoardData(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getSingleUserDataGraph(action) {
  const { userId } = yield select((store) => store?.marketing?.leaderboard || "")
  const { date } = action.payload
  let params = `&month=${date}`
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/trivia/trivia-score-graph/?user_id=${userId}${params}`
    )
    if (data.success) {
      yield put(slice.actions.setSingleUserDataGraph(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getBarMenu(action) {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const archived = action?.payload
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/bar-menu-web${archived ? `?&item_state=archived` : ""}`
    )
    if (data.success) {
      yield put(slice.actions.setBarMenuPeriodData(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getWineMenu(action) {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const archived = action?.payload
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True${
        archived ? `&item_state=archived` : ""
      }`
    )
    if (data.success) {
      yield put(slice.actions.setWineMenuPeriodData(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* addWineMenuItems(action) {
  try {
    const { payload, onClose } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.post,
      `${baseUrl}/frontline/food-item/?wine-by-glass=True`,
      payload
    )
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      onClose()
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True`
      )
      if (newData.success) {
        yield put(slice.actions.setWineMenuPeriodData(newData.data))
        yield put(appActions.setIsLoading(false))
      }
    } else {
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* editWineMenuItem(action) {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    const { payload, onClose } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?wine-by-glass=True`,
      payload
    )
    if (data.success) {
      yield put(slice.actions.setFoodItemsDetail({}))
      yield put(appActions.setIsLoading(false))
      onClose()
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True${
          isArchive === true ? `&item_state=archived` : ""
        }`
      )
      if (newData.success) {
        yield put(slice.actions.setWineMenuPeriodData(newData.data))
        yield put(appActions.setIsLoading(false))
      }
      yield put(slice.actions.setisArchive(false))
    } else {
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getWineItems() {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?wine-by-glass=True`
    )
    if (data.success) {
      yield put(slice.actions.setFoodItemsDetail(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* deleteWineMenuItem() {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    yield call(
      axios.delete,
      `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?wine-by-glass=True`
    )
    yield put(slice.actions.setFoodItemsDetail({}))
    yield put(appActions.setIsLoading(false))
    const { data: newData } = yield call(
      axios.get,
      `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True${
        isArchive === true ? `&item_state=archived` : ""
      }`
    )
    if (newData.success) {
      yield put(slice.actions.setWineMenuPeriodData(newData.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* addBarMenuItems(action) {
  try {
    const { payload, onClose } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.post,
      `${baseUrl}/frontline/food-item/?bar-menu=True`,
      payload
    )
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      onClose()
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/bar-menu-web`)
      if (newData.success) {
        yield put(slice.actions.setBarMenuPeriodData(newData.data))
        yield put(appActions.setIsLoading(false))
      }
    } else {
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* editBarMenuItem(action) {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    const { payload, onClose } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?bar-menu=True`,
      payload
    )
    if (data.success) {
      onClose()
      yield put(slice.actions.setFoodItemsDetail({}))
      yield put(appActions.setIsLoading(false))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/bar-menu-web${isArchive === true ? `?item_state=archived` : ""}`
      )
      if (newData.success) {
        yield put(slice.actions.setBarMenuPeriodData(newData.data))
        yield put(appActions.setIsLoading(false))
      }
      yield put(slice.actions.setisArchive(false))
    } else {
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getBarItems() {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?bar-menu=True`
    )
    if (data.success) {
      yield put(slice.actions.setFoodItemsDetail(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* deleteBarMenuItem() {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?bar-menu=True`)
    yield put(slice.actions.setFoodItemsDetail({}))
    yield put(appActions.setIsLoading(false))
    const { data: newData } = yield call(
      axios.get,
      `${baseUrl}/frontline/bar-menu-web${isArchive === true ? `?item_state=archived` : ""}`
    )
    if (newData.success) {
      yield put(slice.actions.setBarMenuPeriodData(newData.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* AddBarMenuCategory(action) {
  const { food_category, meal_period, name } = action.payload
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/bar-category/`, {
      meal_period: meal_period,
      food_category: food_category,
      name: name
    })
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setMenuCategoryId(data.data.id))
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/bar-menu-web`)
      if (newData.success) {
        yield put(slice.actions.setBarMenuPeriodData(newData.data))
        yield put(appActions.setIsLoading(false))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* editBarMenuCategory(action) {
  const { food_category, mealCategoryId, meal_period, name } = action.payload
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/bar-category/${mealCategoryId}`,
      {
        meal_period: meal_period,
        food_category: food_category,
        name: name
      }
    )
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/bar-menu-web`)
      if (newData.success) {
        yield put(slice.actions.setBarMenuPeriodData(newData.data))
        yield put(appActions.setIsLoading(false))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* deleteBarMenuCategory(action) {
  const { mealCategoryId } = action.payload
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.delete, `${baseUrl}/frontline/bar-category/${mealCategoryId}`)
    console.log(data)

    yield put(appActions.setIsLoading(false))
    const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/bar-menu-web`)
    if (newData.success) {
      yield put(slice.actions.setBarMenuPeriodData(newData.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* unArchiveItems(action) {
  try {
    const { item, category } = yield select((store) => store?.marketing?.menu?.linkCategory || {})
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/menu-archive/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?meal-period=${item}&food-type=${category}&item_state=archived
        `
      )
      if (data.success) {
        yield put(slice.actions.setMenuPeriodData(newData.data))
        yield put(appActions.setIsLoading(false))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* unArchiveBarItems(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/menu-archive/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      const { data } = yield call(
        axios.get,
        `${baseUrl}/frontline/bar-menu-web?item_state=archived`
      )
      if (data.success) {
        yield put(slice.actions.setBarMenuPeriodData(data.data))
        yield put(appActions.setIsLoading(false))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* importSpiritItem(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/spirit-import/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      const { menuCategory } = yield select((store) => store.dmv)
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?platform=marketing&item_state=available`
      )
      if (newData.success) {
        yield put(appActions.setIsLoading(false))
        yield put(slice.actions.setSpiritData(newData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getSpiritCMS(action) {
  try {
    const { menuCategory } = yield select((store) => store.dmv)
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-web-menu/${
        menuCategory?.name
      }?platform=marketing&item_state=available${
        action.payload !== "" && action.payload ? `&search=${action.payload}` : ""
      }`
    )
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setSpiritData(data?.data || []))
    }
  } catch (e) {
    console.log(e)
    yield put(appActions.setIsLoading(false))
  }
}

export function* getArchivedSpiritCMS() {
  try {
    const { menuCategory } = yield select((store) => store.dmv)
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?platform=marketing&item_state=archived`
    )
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setSpiritData(data?.data || []))
    }
  } catch (e) {
    console.log(e)
    yield put(appActions.setIsLoading(false))
  }
}

export function* unArchiveSpiritsItems(action) {
  try {
    const { menuCategory } = yield select((store) => store.dmv)
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/menu-archive/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      const { data } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?platform=marketing&item_state=archived`
      )
      if (data.success) {
        yield put(slice.actions.setSpiritData(data.data))
        yield put(appActions.setIsLoading(false))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* archiveSpiritItemsAction(action) {
  const { itemID, menuCategory } = yield select((store) => store.dmv)
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/spirit-menu/${itemID ?? ""}`,
      action.payload
    )
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      const { data: newDta } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?platform=marketing&item_state=archived`
      )
      if (data.success) {
        yield put(slice.actions.setSpiritData(newDta.data))
        yield put(appActions.setIsLoading(false))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* unarchiveWineItem(action) {
  const { menu_item_ID } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/food-item/${menu_item_ID ?? ""}?wine-by-glass=True`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setFoodItemsDetail({}))
      yield put(appActions.setIsLoading(false))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True&item_state=archived`
      )
      if (newData.success) {
        yield put(slice.actions.setWineMenuPeriodData(newData.data))
        yield put(appActions.setIsLoading(false))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* addarchiveSpiritItems(action) {
  const { itemID, menuCategory } = yield select((store) => store.dmv)
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/spirit-menu/${itemID ?? ""}`,
      action.payload
    )
    if (data.success) {
      if (data.success) {
        yield put(appActions.setIsLoading(false))
        const { data: newDta } = yield call(
          axios.get,
          `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?platform=marketing${
            isArchive === true ? `&item_state=archived` : `&item_state=available`
          }`
        )
        if (data.success) {
          yield put(slice.actions.setSpiritData(newDta.data))
          yield put(appActions.setIsLoading(false))
        }
        yield put(slice.actions.setisArchive(false))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* deleteSpiritMenuItem() {
  const { itemID, menuCategory } = yield select((store) => store.dmv)
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/frontline/spirit-menu/${itemID}`)
    yield put(appActions.setIsLoading(false))
    yield put(slice.actions.setMenuCategoryId(""))
    const { data: newData } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?platform=marketing${
        isArchive === true ? `&item_state=archived` : "&item_state=available"
      }`
    )
    if (newData.success) {
      yield put(slice.actions.setSpiritData(newData?.data || []))
    }
    yield put(slice.actions.setisArchive(false))
  } catch (e) {
    console.log(e)
  }
}

export function* getSpiritItems() {
  const { itemID } = yield select((store) => store.dmv)
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-menu/${itemID ?? ""}?platform=marketing&item_state=available`
    )
    yield put(appActions.setIsLoading(false))
    if (data.success) {
      yield put(DMV_Slice.actions.setSpiritMenuItem(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* updateSpiritItems(action) {
  const { itemID, menuCategory } = yield select((store) => store.dmv)
  const { isArchive } = yield select((store) => store?.marketing?.menu || {})
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/spirit-menu/${itemID ?? ""}`,
      action.payload
    )
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setMenuCategoryId(data.data.id))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?platform=marketing${
          isArchive === true ? `&item_state=archived` : `&item_state=available`
        }`
      )
      if (newData.success) {
        yield put(slice.actions.setSpiritData(newData?.data || []))
      }
      yield put(slice.actions.setisArchive(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getNewSpiritCount() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/spirit-count/`)
    yield put(appActions.setIsLoading(false))
    if (data.success) {
      yield put(slice.actions.setNewSpiritCount(data?.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}
