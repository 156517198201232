import React, { useEffect, useState } from "react"
import Card from "components/Card/Card"
import { Box, Typography, Dialog, styled, IconButton } from "@mui/material"
import CustomDivider from "components/StyledComponents/Divider"
import { useTheme } from "@mui/system"
import CloseIcon from "@mui/icons-material/Close"
import { Formik } from "formik"
import { validationSchemaWineOfTheMonth } from "helpers/validationSchema"
import { useSelector, useDispatch } from "react-redux"
import { formatDate } from "helpers/functions"
// import { uploadWineOfTheMonthAction } from "redux/marketing/actions";
import ImportTab from "../components/ImportTab"
import { marketingActions } from "redux/marketing/slice/user"
import { editWineOfTheMonthItemAction } from "redux/marketing/actions"

function getTastingNotes(allergence) {
  let allergenceItems = []
  if (!allergence) return []
  for (let al of allergence) {
    allergenceItems.push(al?.name)
  }
  return allergenceItems
}

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: "1100px"
  }
}))

const initialValues = {
  month: "",
  nameOfTheWine: "",
  videoThumbnail: "",
  videoURL: "",
  wineImageVideo: "",
  wineRegion: "",
  grapeVarietals: "",
  description: "",
  tastingNotes: [],
  menuPairing: [
    {
      image: null,
      title: ""
    }
  ]
}

function EditWineOfTheMonthModal({ open, onClose, isEdit = false }) {
  const { getSingleWineMonthData } = useSelector((store) => store?.marketing?.wineOfTheMonth || {})
  const theme = useTheme()
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState("Import")
  const [initialFormValue, setInitialFormValue] = useState(initialValues)
  const tabs = ["Import"]
  const { videoThumbnailImage, bottleimage, flavourImages } = useSelector(
    (store) => store?.marketing?.wineOfTheMonth || {}
  )

  const styles = {
    card: {
      margin: 0
    },
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center"
    },
    divider: {
      backgroundColor: theme.palette.secondary.main,
      height: 3,
      opacity: 1,
      position: "relative",
      top: "12px",
      left: "-23%",
      width: "150%"
    },
    mainDivider: {
      backgroundColor: theme.palette.background.main,
      opacity: 1,
      marginTop: "10px"
    },
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    }
  }

  function initForm() {
    setInitialFormValue({
      month: new Date(getSingleWineMonthData?.month).toUTCString() ?? "",
      nameOfTheWine: getSingleWineMonthData?.name ?? "",
      videoThumbnail: getSingleWineMonthData?.thumbnail_url
        ? {
            id: "",
            url: getSingleWineMonthData?.thumbnail_url
          }
        : "",
      videoURL: getSingleWineMonthData?.video_url ?? "",
      wineImageVideo: getSingleWineMonthData?.wine_image
        ? {
            id: "",
            url: getSingleWineMonthData?.wine_image
          }
        : "",
      wineRegion: getSingleWineMonthData?.wine_region,
      grapeVarietals: getSingleWineMonthData?.gape_variatals,
      description: getSingleWineMonthData?.description,
      tastingNotes: getTastingNotes(getSingleWineMonthData?.tasting_notes ?? []),
      menuPairing: getSingleWineMonthData?.menu_pairing?.map((el) => ({
        title: el?.name ?? "",
        image: el?.image
          ? {
              id: el?.image_id,
              url: el?.image
            }
          : ""
      }))
    })
    dispatch(marketingActions.setWineMonthVideoThumbnail(""))
  }

  function handleEditSubmit(values) {
    const payload = {
      month: formatDate(values.month),
      name: values?.nameOfTheWine,
      ...(videoThumbnailImage ? { thumbnail: videoThumbnailImage } : {}),
      ...(bottleimage ? { image: bottleimage } : {}),
      video_url: values?.videoURL,
      wine_region: values?.wineRegion,
      gape_variatals: values?.grapeVarietals,
      description: values?.description,
      menu_tasting_notes: values.tastingNotes,
      menu_pairing: values?.menuPairing?.map((el, index) => {
        return {
          name: el?.title,
          ...(flavourImages[index] || el?.image?.id
            ? {
                image: el?.image?.id
                  ? el?.image?.id
                  : flavourImages[index]
                  ? flavourImages[index]
                  : null
              }
            : {})
        }
      })
    }

    dispatch(editWineOfTheMonthItemAction(payload))
    onClose()
  }

  useEffect(() => {
    if (getSingleWineMonthData) initForm()
  }, [getSingleWineMonthData])

  return (
    <StyledDialog open={open} fullWidth>
      <Card style={styles.card}>
        <IconButton onClick={() => onClose()} sx={styles.closeBtn}>
          <CloseIcon sx={{ height: "14px", width: "14px" }} />
        </IconButton>
        {/* Headings */}
        <Box sx={styles.headingContainer}>
          {tabs.map((tab, index) => (
            <Box sx={styles.heading} key={index}>
              <Typography
                onClick={() => setActiveTab(tab)}
                variant="body1Regular"
                sx={[
                  tab === activeTab ? { color: theme.palette.secondary.main } : {},
                  { cursor: "pointer" }
                ]}>
                {tab}
              </Typography>
              {tab === activeTab && <CustomDivider sx={styles.divider} />}
            </Box>
          ))}
        </Box>
        <CustomDivider sx={styles.mainDivider} />
        <Box>
          {/* Upload Tab */}
          <Formik
            initialValues={initialFormValue}
            enableReinitialize={true}
            validationSchema={validationSchemaWineOfTheMonth}
            onSubmit={handleEditSubmit}>
            {(formik) => (
              <>
                {/* Import Tab */}
                {activeTab === "Import" && <ImportTab isEdit={isEdit} formik={formik} />}
              </>
            )}
          </Formik>
        </Box>
      </Card>
    </StyledDialog>
  )
}

export default EditWineOfTheMonthModal