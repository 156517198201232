import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
import AppRouter from "./router"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { defaultTheme, defaultThemeDVM } from "./themes/defaultTheme"
import store from "./redux/index"
import "bootstrap/dist/css/bootstrap.min.css"
import "./styles/global.scss"
import "./themes/font.css"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from "react-toastify"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import AppLoader from "components/Loader/AppLoader"
import "react-image-crop/src/ReactCrop.scss"
/**
 * @description Check if browser is Safar
 * @description It'll be usefull for web notifications
 */

if (window.safari) {
  console.log("safari browser detected")
} else {
  // initializeFirebase();
}

// Main APP Conflict Comment

function App() {
  const currentTheme = createTheme(defaultTheme)
  const DMVTheme = createTheme(defaultThemeDVM)
  let persistor = persistStore(store)
  const isDMV = location.pathname.includes("dmv") || location.pathname.includes("DMV")
  
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={isDMV ? DMVTheme : currentTheme}>
          <AppRouter />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <AppLoader></AppLoader>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
