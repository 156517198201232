import { Box, Dialog, DialogContent, FormHelperText, styled, Typography } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import styles from "./ImageUploadPopup.module.scss"
import CloseIcon from "@mui/icons-material/Close"
import DashedBorder from "assets/icons/DashedBorder.svg"
import UploadIcon from "assets/icons/UploadIcon.svg"
import TransparentImageIcon from "assets/icons/TransparentImageIcon.svg"
import RedCloseIcon from "assets/icons/RedCloseIcon.svg"
import AppButton from "components/StyledComponents/AppButton"
import { removeDecimalUpto1 } from "helpers/functions"
import ImageCropper from "components/ImageCropper"
import { toast } from "react-toastify"

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  }
}))

const ImageUploadPopup = ({
  onImageUpload = () => {},
  selectedImage,
  setSelectedImage = () => {},
  onImageDelete = () => {},
  onClose = () => {},
  open,
  maxImageSize,
  aspectWidth,
  aspectHeight,
  minPixelWidth,
  minPixelHeight,
  maxPixelWidth,
  maxPixelHeight
}) => {
  const [isInternalError, setIsInternalError] = useState(false)
  const inputFile = useRef(null)
  const [openCrop, setCrop] = useState(false)

  const [srcImage, setSelectedFileImage] = useState(null)
  const [fileDetail, setFileDetails] = useState(null)

  const onBrowseClick = () => {
    inputFile.current.click()
  }

  const onFilesSelected = (e) => {
    const { files } = e.target
    const fileType = files?.[0]?.type
    if (fileType !== "image/png" && fileType !== "image/jpeg") {
      toast.error("Sorry, Your file is not supported. Supported file types are png/jpeg.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      e.target.value = ""
      return
    }
    if (files && files.length) {
      setSelectedFileImage(URL.createObjectURL(files[0]))
      setFileDetails(files[0])
    }
    if (srcImage) {
      setCrop(true)
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const checkFileType = (file) => {
    const types = ["image/png", "image/jpeg", "image/jpg"]
    if (types.includes(file.type)) {
      return true
    }
    setIsInternalError(`Unsupported File Type`)
    return false
  }

  const checkFileName = (file) => {
    if (file?.name?.length <= 200) {
      return true
    }
    setIsInternalError(`File name exceeded 200 characters`)
    return false
  }

  const checkSizeInPixels = (imageHeight, imageWidth) => {
    if (minPixelWidth && imageWidth < minPixelWidth) {
      setIsInternalError(`Please Upload Image of Width more than ${minPixelWidth} pixels`)
      return false
    }
    if (minPixelHeight && imageHeight < minPixelHeight) {
      setIsInternalError(`Please Upload Image of Height more than ${minPixelHeight} pixels`)
      return false
    }
    if (maxPixelWidth && imageWidth < maxPixelWidth) {
      setIsInternalError(`Please Upload Image of Width Less than ${maxPixelWidth} pixels`)
      return false
    }
    if (maxPixelHeight && imageHeight < maxPixelHeight) {
      setIsInternalError(`Please Upload Image of Height Less than ${maxPixelHeight} pixels`)
      return false
    }

    return true
  }

  const checkAspectRatioAndSize = (file) => {
    let img = new Image()
    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth
        const height = img.naturalHeight
        if (!checkSizeInPixels(height, width, file.size)) {
          return resolve(false)
        }
        if (!aspectHeight || !aspectWidth) {
          return resolve(true)
        }
        const ratio = width / height
        if (file.size > maxImageSize) {
          setIsInternalError(`Please Upload Image with size < ${maxImageSize / 1024}KB`)
          return resolve(false)
        }
        if (removeDecimalUpto1(ratio) !== removeDecimalUpto1(aspectWidth / aspectHeight)) {
          setIsInternalError(`Please Upload Image with ${aspectWidth}:${aspectHeight} aspect ratio`)
          return resolve(false)
        }
        resolve(true)
      }
      img.onerror = reject
    })
    img.src = window.URL.createObjectURL(file)

    return promise
  }

  const checkFile = async (file) => {
    setIsInternalError(false)
    if (file && checkFileType(file) && checkFileName(file)) {
      const isAspectRatioAndSizeCorrect = await checkAspectRatioAndSize(file)
      if (isAspectRatioAndSizeCorrect) setSelectedImage(file)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const { files } = e.dataTransfer

    if (files && files.length) {
      setSelectedFileImage(URL.createObjectURL(files[0]))
      setFileDetails(files[0])

      // checkFile(files[0]);

      if (!srcImage) {
        setCrop(false)
      }
      setCrop(true)
    }
  }

  const onUploadClick = () => {
    onImageUpload(selectedImage)
    onClose()
  }

  useEffect(() => {
    if (fileDetail) {
      setCrop(true)
    }
  }, [fileDetail])

  function handleCroppedImage(image) {
    setSelectedImage(image)
    checkFile(image)
  }

  function showCropModal() {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image)}
        srcImage={srcImage}
        fileDetail={fileDetail}
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="">
      {showCropModal()}
      <DialogContent id="imageUploadDialog" className={styles.dialogBox}>
        {selectedImage ? (
          <Box>
            <Box className={styles.closeButton} onClick={onClose}>
              <CloseIcon sx={{ height: "14px", width: "14px" }} />
            </Box>
            <Box className={styles.uploadCtr} style={{ height: "auto", marginBottom: 50 }}>
              <Box className={styles.imageListItem}>
                <Box className={styles.imageItemInnerCtr}>
                  <img
                    style={{ height: "32px" }}
                    src={selectedImage ? URL.createObjectURL(selectedImage) : TransparentImageIcon}
                    alt=""
                    className="me-4"
                  />
                  <Typography className="ms-1 me-3" variant="body1Regular">
                    {selectedImage?.name}
                  </Typography>
                </Box>
                <img
                  className={styles.deleteBtn}
                  src={RedCloseIcon}
                  onClick={onImageDelete}
                  alt="delete"
                />
              </Box>
            </Box>
            <Box className={styles.divider} />
            <Box className="w-100 text-center">
              <AppButton onClick={onUploadClick} className={styles.uploadBtn}>
                Upload
              </AppButton>
            </Box>
          </Box>
        ) : (
          <Box className={styles.uploadCtrOuter}>
            <Box className={styles.closeButton} onClick={onClose}>
              <CloseIcon sx={{ height: "14px", width: "14px" }} />
            </Box>
            <div className={styles.uploadCtr} onDragOver={handleDragOver} onDrop={handleDrop}>
              <img src={DashedBorder} alt="" className={styles.borderImage} />
              <Box className={styles.innerUploadCtr}>
                <img src={UploadIcon} alt="" />
                <Typography variant="h3Italic">Drag And Drop</Typography>
                <Typography className={styles.imgUploadHelperText} variant="body1Regular">
                  or select files from device
                </Typography>
                <AppButton className={styles.browseBtn} onClick={onBrowseClick}>
                  Browse
                </AppButton>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={onFilesSelected}
                  accept="image/png, image/jpeg, image/jpg"
                />
              </Box>
            </div>

            {isInternalError ? (
              <FormHelperText sx={{ ml: 4 }} error={true}>
                {isInternalError}
              </FormHelperText>
            ) : null}
          </Box>
        )}
      </DialogContent>
    </StyledDialog>
  )
}

export default ImageUploadPopup
