import * as React from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import Typography from "@mui/material/Typography"
import Avatar from "@mui/material/Avatar"
import Logout from "../../assets/icons/logout.svg"
import avatarImg from "../../assets/images/avatarUser.svg"
import "./privateLayout.scss"
import { useHistory } from "react-router-dom"

import { FiltersData } from "assets/json/Filters"
import { useDispatch } from "react-redux"
import { appActions } from "redux/app/slice/app"
import { Fragment } from "react"

import FilterItem from "components/PrivateLayoutFilters/FilterItem"
import { userActions } from "redux/user/user"
import { useTheme } from "@mui/material"
import { useSelector } from "react-redux"
// import DMVLogo from "../../assets/icons/DMV_Logo.svg"
import VantaMenuLogo from "assets/icons/VantaMenuLogo.svg"
import { revertAllLnD } from "redux/L&D/actions"
import { revertAllMarketing } from "redux/marketing/actions"
import { revertAllGM } from "redux/GM/actions"
import { revertAllDMV } from "redux/DMV/actions"
import { formatDate } from "helpers/functions"

const drawerWidth = 240

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme }) => ({
  flexGrow: 1,
  padding: "24px",
  backgroundColor: theme.palette.background.main,
  color: theme.palette.text.primary,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: 0
}))

export default function PrivateLayout({ children }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const role = useSelector((res) => res.user?.userData?.data)
  const [open] = React.useState(true)

  const [filterItems] = React.useState(
    FiltersData.find((el) => window.location.pathname.endsWith(el.path))?.filters || []
  )

  const { full_name, rest_name } = useSelector((store) => store.user?.userData)
  const { image_url } = useSelector((store) => store.user?.userWithId?.data ?? "")

  function dispatchLogoutState() {
    dispatch(revertAllLnD())
    dispatch(revertAllMarketing())
    dispatch(revertAllGM())
    dispatch(revertAllDMV())
    dispatch(userActions.setUserData({}))
    dispatch(userActions.setUserWithData({}))
    dispatch(appActions.setIsLoggedInData(false))
    dispatch(
      appActions.setFilters({
        date: formatDate(new Date())
      })
    )
    dispatch(appActions.setRestaurantList([]))
    dispatch(appActions.setUserTypeList([]))
  }

  const handleLogOut = () => {
    localStorage.removeItem("access_token")
    localStorage.removeItem("refresh_token")
    dispatchLogoutState()
    if (role?.name === "DMV") {
      history.push("/dmv/auth/login")
    } else {
      history.push("/auth/login")
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Fragment>
      <Box sx={{ display: "flex" }}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: `${theme.palette.drawer.main}`,
              borderRight: "1px solid #212121"
            }
          }}
          variant="persistent"
          anchor="left"
          open={open}>
          <div className="logo">
            {history?.location?.pathname?.includes("DMV") ? (
              <picture>
                <source media="(min-width:1500px)" src="../assets/icons/DMV_Logo.svg"></source>
                <img src={VantaMenuLogo} onClick={() => history.push("/dmv/dashboard")} />
              </picture>
            ) : (
              <picture>
                <picture>
                  <source media="(min-width:1500px)" src="../assets/icons/DMV_Logo.svg"></source>
                  <img
                    src={VantaMenuLogo}
                    onClick={() => window.location.replace("/marketing/dashboard")}
                  />
                </picture>
              </picture>
            )}
          </div>
          {/* To be replaced with dynamic Filters */}
          {filterItems?.length ? (
            <div className="mt-5 filterContainer">
              {filterItems.map((filter) => {
                return <FilterItem key={filter.title} data={filter} />
              })}
            </div>
          ) : null}
          {/* Footer */}
          <div className="footer">
            <div className="footer__grid">
              <div className="avatar">
                <div className="avatar__img">
                  <Avatar
                    alt="Remy Sharp"
                    src={image_url ? image_url : avatarImg}
                    sx={{ width: 36, height: 36 }}
                  />
                </div>
                <div>
                  <div>
                    <Typography variant="body2Regular">{full_name}</Typography>
                  </div>
                  <div>
                    <Typography variant="body2Regular">{rest_name}</Typography>
                  </div>
                </div>
              </div>
              <button className="action">
                <img className="me-3" src={Logout} />
                <Typography onClick={handleLogOut} variant="body1Regular" sx={{ opacity: 0.6 }}>
                  Logout
                </Typography>
              </button>
            </div>
          </div>
        </Drawer>
        <Main open={open}>{children}</Main>
      </Box>
    </Fragment>
  )
}
