import React, { useState } from "react"
import Card from "components/Card/Card"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import { Menu, MenuItem, Typography } from "@mui/material"
import { useTheme } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import ImageDisplay from "components/ImageDisplayer"
import classes from "pages/private/marketing/menu/menu.module.scss"
import Paragraph from "components/ReadMoreParagraph"
import {
  DeleteBarMenuCategoryAction,
  deleteBarItemAction,
  getBarItemsAction,
  unArchiveBarItemsAction,
  updateBarItemsAction
} from "redux/marketing/actions/user"
import UnarchiveItem from "assets/icons/UnarchiveItem.svg"
import SelectAll from "assets/icons/SelectAllRectange.svg"
import unSelect from "assets/icons/unSelectedRectange.svg"
import { isEmptyObject } from "helpers/functions"
import selectedItem from "assets/icons/SelectedRectange.svg"
import { toast } from "react-toastify"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import AddMenuItems from "pages/private/marketing/menu/AddMenuItems"

function ArchivedBarListItems({ data, isEdit = false }) {
  const theme = useTheme()
  const [modal, setDeleteModal] = useState(false)
  const [modalItem, setDeleteItemModal] = useState(false)
  const [selectedItems, setSelectedItems] = useState({})
  const [isEditModalItem, setIsEditModalItem] = useState(false)
  const [menuItemsModal, setMenuItemsModal] = useState(false)
  const [unarchiveModal, setUnarchiveItemModal] = useState(false)
  const dispatch = useDispatch()
  const { archived } = useSelector((store) => store?.marketing?.menu ?? {})
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event, id, categoryId) => {
    setAnchorEl(event.currentTarget)
    dispatch(marketingActions.setMenuItemId(id))
    dispatch(marketingActions.setMenuCategoryId(categoryId))
    dispatch(getBarItemsAction())
  }

  function handleMenuDeleteModal() {
    handleClose()
    setDeleteItemModal(true)
    setAnchorEl(null)
    dispatch(marketingActions.setisArchive(true))
  }

  function handleEditMenuItemModal() {
    setAnchorEl(null)
    setIsEditModalItem(true)
    setMenuItemsModal(true)
    dispatch(marketingActions.setisArchive(true))
  }

  function handleCloseMenuItems() {
    setMenuItemsModal(false)
    setIsEditModalItem(false)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }

  function handleArchiveItem() {
    setUnarchiveItemModal(true)
    dispatch(marketingActions.setisArchive(true))
    setAnchorEl(null)
  }

  function handleMenuPeriodDelete(id) {
    dispatch(marketingActions.setMenuCategoryId(id))
    dispatch(DeleteBarMenuCategoryAction({ mealCategoryId: id }))
  }

  function handleMenuItemDelete() {
    dispatch(deleteBarItemAction())
  }

  function handleCloseModal() {
    setDeleteItemModal(false)
    dispatch(marketingActions.setMenuItemId(""))
  }

  function handleCloseArchiveModal() {
    setUnarchiveItemModal(false)
  }

  function handleUnarchiveMenuItem() {
    const payload = {
      item_state: "available"
    }
    dispatch(updateBarItemsAction(payload))
  }

  const handleSelect = (item) => {
    if (isEmptyObject(selectedItems)) {
      setSelectedItems(item)
    } else {
      setSelectedItems({})
    }
  }

  const handleUnArchive = () => {
    if (isEmptyObject(selectedItems)) {
      toast.error("Please select items to unarchive!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    } else {
      const id_dict = selectedItems?.food_list?.map((item) => ({
        id: item.id,
        food_type: "bar"
      }))
      const payload = {
        id_dict
      }
      dispatch(unArchiveBarItemsAction(payload))
    }
  }

  const checkSelectedItems = (id) => {
    const isItemSelected = selectedItems?.food_list?.filter((item) => item?.id === id)
    if (isItemSelected?.length > 0) {
      return true
    } else {
      return false
    }
  }

  const handleSelectItem = (id) => {
    if (isEmptyObject(selectedItems)) {
      setSelectedItems({ ...data, food_list: data?.food_list?.filter((item) => item?.id === id) })
    } else {
      const itemsData = selectedItems?.food_list?.filter((item) => item?.id === id)
      if (itemsData?.length > 0) {
        if (selectedItems?.food_list?.length === 1) {
          setSelectedItems({})
        } else {
          setSelectedItems({
            ...selectedItems,
            food_list: selectedItems?.food_list?.filter((item) => item?.id !== id)
          })
        }
      } else {
        setSelectedItems({
          ...selectedItems,
          food_list: [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...selectedItems?.food_list,
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...data?.food_list?.filter((item) => item?.id === id)
          ]
        })
      }
    }
  }
  return (
    <>
      <AddMenuItems isEdit={isEditModalItem} open={menuItemsModal} onClose={handleCloseMenuItems} />
      <DeleteConfirmationDialog
        title={`Are you sure you want to Delete “${data?.food_category ?? ""}”?`}
        description={`"${data?.food_category ?? ""}" has ${data?.food_list?.length ?? ""} ${
          data?.food_list?.length <= 1 ? "dish" : "dishes"
        }`}
        style={{ textAlign: "center", width: "80%" }}
        onClose={() => setDeleteModal(false)}
        onDelete={() => handleMenuPeriodDelete(data?.food_category_id)}
        open={modal}></DeleteConfirmationDialog>
      <DeleteConfirmationDialog
        title={`Are You Sure You Want To Delete This Bar Item ?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseModal}
        onDelete={() => handleMenuItemDelete()}
        open={modalItem}></DeleteConfirmationDialog>
      <DeleteConfirmationDialog
        title={`Are You Sure You Want To Unarchive This Bar Item?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseArchiveModal}
        onDelete={() => handleUnarchiveMenuItem()}
        open={unarchiveModal}
        btnText="Unarchive"
        type="warning"
      />
      <Card style={{ height: "fit-content" }}>
        <Row>
          <Col lg="12">
            <div
              className="d-flex justify-content-between align-item-center"
              style={{
                backgroundColor: isEdit && theme.palette.background.default,
                padding: ` ${isEdit ? "12px 16px" : "0px"}`,
                borderRadius: 6
              }}>
              <img
                src={
                  selectedItems?.food_list?.length === data?.food_list?.length
                    ? selectedItem
                    : SelectAll
                }
                alt="selectAll"
                style={{ cursor: "pointer" }}
                onClick={() => handleSelect(data)}
              />
              <Typography variant="h2">{data?.food_category}</Typography>
              <div className="d-flex align-items-center">
                <img
                  src={UnarchiveItem}
                  alt="unarchive"
                  style={{ cursor: "pointer" }}
                  onClick={handleUnArchive}
                />
              </div>
            </div>
          </Col>
          <Col lg="12">
            {data?.food_list?.length ? (
              data?.food_list?.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className={archived ? classes.archivedItemContainer : classes.itemContainer}
                    style={{
                      border: `${isEdit ? `1px solid ${theme.palette.secondary.main}` : "none"}`,
                      borderBottom: `${
                        !isEdit ? `1px solid #605e5e` : `.5px solid ${theme.palette.secondary.main}`
                      }`,
                      borderRadius: `${isEdit ? "6px" : "0px"}`,
                      padding: `${isEdit ? "8px" : "5px"}`
                    }}>
                    <div>
                      {checkSelectedItems(item?.id) ? (
                        <img
                          src={selectedItem}
                          alt="select"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleSelectItem(item?.id)}
                        />
                      ) : (
                        <img
                          src={unSelect}
                          alt="select"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleSelectItem(item?.id)}
                        />
                      )}
                    </div>
                    <div className={classes.imageContainer} style={{ height: 250 }}>
                      <ImageDisplay data={item?.image_url}></ImageDisplay>
                    </div>
                    <div
                      style={{
                        // display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        maxWidth: 375,
                        overflowWrap: "break-word"
                      }}>
                      <div>
                        <Typography variant="h4">{item?.name ?? "-"}</Typography>
                      </div>

                      <div>
                        {(item?.ingredient?.length > 0 || isEdit) && (
                          <div className="mt-2">
                            <Typography variant="tag1">{"Ingredients:"}</Typography>
                            <br></br>
                            {item?.ingredient?.map((item, index) => (
                              <Typography key={index} variant="tagItalic" sx={{ opacity: 0.6 }}>
                                {`${item?.name ?? ""}`}
                                {item?.measurement
                                  ? item?.ingredient?.length - 1 > index
                                    ? ` (${item?.measurement}), `
                                    : item?.measurement
                                    ? ` (${item?.measurement}) `
                                    : ""
                                  : item?.ingredient?.length - 1 > index
                                  ? ", "
                                  : ""}
                              </Typography>
                            ))}
                          </div>
                        )}
                        {(item?.allergance?.length > 0 || isEdit) && (
                          <div className="mt-2">
                            <Typography variant="tag1">{"Allergens:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.allergance?.map((allergance, index) => {
                                return (
                                  allergance?.name +
                                    (item?.allergance?.length - 1 > index ? ", " : "") ?? "-"
                                )
                              })}
                            </Typography>
                          </div>
                        )}
                        {(item?.preparation_method || isEdit) && (
                          <div className="mt-2">
                            <Typography variant="tag1">{"Preparation Method:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.preparation_method ?? "-"}
                            </Typography>
                          </div>
                        )}
                        {(item?.garnish || isEdit) && (
                          <div className="mt-2">
                            <Typography variant="tag1">{"Garnish:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.garnish ?? "-"}
                            </Typography>
                          </div>
                        )}
                        {(item?.glassware || isEdit) && (
                          <div className="mt-2">
                            <Typography variant="tag1">{"Glassware:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.glassware ?? "-"}
                            </Typography>
                          </div>
                        )}
                        {(item?.sound_bite || isEdit) && (
                          <div className="mt-2">
                            <Typography variant="tag1">{"Bartender’s Sound Bites:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {
                                <Paragraph
                                  text={item?.sound_bite ?? ""}
                                  maxCharCount={70}></Paragraph>
                              }
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                    {isEdit && (
                      <div>
                        <MoreVertIcon
                          id="demo-positioned-button"
                          aria-controls={open ? "demo-positioned-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => handleClick(e, item?.id)}
                          sx={{ cursor: "pointer" }}></MoreVertIcon>
                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                          }}>
                          <MenuItem onClick={handleEditMenuItemModal} sx={{ width: "150px" }}>
                            Edit
                          </MenuItem>
                          <MenuItem onClick={() => handleArchiveItem()} sx={{ width: "150px" }}>
                            Unarchive
                          </MenuItem>
                          <MenuItem
                            onClick={handleMenuDeleteModal}
                            sx={{ width: "150px", color: theme.palette.red.main }}>
                            Delete
                          </MenuItem>
                        </Menu>
                      </div>
                    )}
                  </div>
                )
              })
            ) : (
              <Typography className="mt-4" style={{ opacity: 0.6, textAlign: "center" }}>
                No Items Found
              </Typography>
            )}
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default React.memo(ArchivedBarListItems)
