import { appActions } from "redux/app/slice/app"
import axios from "axios"
import { call, put, select } from "redux-saga/effects"
import slice from "../slice/user"
import { toast } from "react-toastify"

const baseUrl = process.env.REACT_APP_API_MY_URL

export function* getUserTypeList() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/user/role`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(appActions.setUserTypeList(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getFrontlineUsersList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { page, search, job_class, status, restaurant, hire_date, orderBy } = action.payload
    let params = `page=${page}`
    if (search && search !== "") {
      params += `&search=${search}`
    }
    if (job_class && job_class !== "All") {
      params += `&job_class=${job_class}`
    }
    if (status && status !== "All") {
      params += `&status=${status}`
    }
    if (restaurant && restaurant !== "All") {
      params += `&restaurant=${restaurant}`
    }
    if (hire_date) {
      params += `&hire_date=${hire_date}`
    }
    if (orderBy) {
      params += `&order_by=${orderBy}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/super-admin/users?${params}`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(
        slice.actions.setFrontlineUserList({
          totalPages: data?.data?.pages,
          data: data?.data.results || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getFrontlineUsersData() {
  try {
    const id = yield select((store) => store?.superAdmin?.frontlineUser?.id ?? "")
    yield put(appActions.setIsLoading(true))

    const { data } = yield call(axios.get, `${baseUrl}/super-admin/${id}/`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setFrontlineUserData(data?.data || {}))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* EditFrontlineUsersData(action) {
  try {
    const id = yield select((store) => store?.superAdmin?.frontlineUser?.id ?? "")
    yield put(appActions.setIsLoading(true))

    const { data } = yield call(axios.patch, `${baseUrl}/super-admin/${id}/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      toast.success("Edit Successful", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadFrontlineUser(action) {
  try {
    const { data } = yield call(axios.post, `${baseUrl}/super-admin/`, action.payload)

    if (data.success) {
      toast.success("User has been added", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      let params = `page=1`
      yield put(appActions.setIsLoading(true))
      const { data: newData } = yield call(axios.get, `${baseUrl}/super-admin/users?${params}`)
      if (newData.success) {
        yield put(appActions.setIsLoading(false))
        yield put(
          slice.actions.setFrontlineUserList({
            totalPages: newData?.data?.pages,
            data: newData?.data.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* updateUserStatus(action) {
  try {
    const id = yield select((store) => store?.superAdmin?.frontlineUser?.id ?? "")
    const payload = { activate: action.payload }
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/super-admin/${id}/activate/`, payload)

    if (data.success) {
      yield put(appActions.setIsLoading(false))
      toast.success("Status Updated", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      const { data: user } = yield call(axios.get, `${baseUrl}/super-admin/${id}/`)
      if (user.success) {
        yield put(appActions.setIsLoading(false))
        yield put(slice.actions.setFrontlineUserData(user?.data || {}))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadFrontlineBulkUser(action) {
  try {
    const headerParams = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }

    const { data } = yield call(
      axios.post,
      `${baseUrl}/super-admin/bulk-create/frontline`,
      {
        file: action.payload
      },
      headerParams
    )

    if (data.success) {
      toast.success("Upload Successful", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      let params = `page=1&order_by=-hire_date`
      const { data: frontlineUser } = yield call(
        axios.get,
        `${baseUrl}/super-admin/users?${params}`
      )
      if (frontlineUser.success)
        yield put(
          slice.actions.setFrontlineUserList({
            totalPages: frontlineUser?.data?.pages,
            data: frontlineUser?.data.results || []
          })
        )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadFrontlineBulkUserAdmin(action) {
  try {
    const headerParams = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }

    const { data } = yield call(
      axios.post,
      `${baseUrl}/super-admin/bulk-create/admin`,
      {
        file: action.payload
      },
      headerParams
    )

    if (data.success) {
      toast.success("Upload Successful", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })

      let params = `page=1&ordering=-hire_date`
      const { data: userAdmin } = yield call(axios.get, `${baseUrl}/user/?${params}`)
      if (userAdmin.success) {
        yield put(
          slice.actions.setAdminUserList({
            totalPages: userAdmin?.data?.pages,
            data: userAdmin?.data.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getAdminUsersList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { page, search, role, status, restaurant, hire_date, orderBy } = action.payload
    let params = `page=${page}`
    if (search && search !== "") {
      params += `&search=${search}`
    }
    if (role && role !== "All") {
      params += `&role=${role}`
    }
    if (status && status !== "all") {
      params += `&status=${status}`
    }
    if (restaurant && restaurant !== "All") {
      params += `&restaurant=${restaurant}`
    }
    if (hire_date) {
      params += `&hire_date=${hire_date}`
    }
    if (orderBy) {
      params += `&ordering=${orderBy}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/user/?${params}`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(
        slice.actions.setAdminUserList({
          totalPages: data?.data?.pages,
          data: data?.data.results || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadAdminUser(action) {
  try {
    const { data } = yield call(axios.post, `${baseUrl}/user/`, action.payload)

    if (data.success) {
      toast.success("User has been added", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getUserMainList() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/user/user-type`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(appActions.setAdminUserList(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getRoleUsersList() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/user/role/`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(appActions.setRolesUserList(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getNewRestaurantList() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/super-admin/restaurant/`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(appActions.setNewRestaurantList(data.data.results || []))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getAdminUsersData() {
  try {
    const id = yield select((store) => store?.superAdmin?.adminUsers?.id ?? "")
    yield put(appActions.setIsLoading(true))

    const { data } = yield call(axios.get, `${baseUrl}/user/${id}/`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setAdminUserData(data?.data || {}))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* EditAdminUsersData(action) {
  try {
    const id = yield select((store) => store?.superAdmin?.adminUsers?.id ?? "")
    yield put(appActions.setIsLoading(true))

    const { data } = yield call(axios.patch, `${baseUrl}/user/${id}/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      const { data } = yield call(axios.get, `${baseUrl}/user/?page=1&ordering=-hire_date`)
      if (data.success) {
        yield put(appActions.setIsLoading(false))
        yield put(
          slice.actions.setAdminUserList({
            totalPages: data?.data?.pages,
            data: data?.data.results || []
          })
        )
      }
      toast.success("Edit Successful", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export function* updateAdminUserStatus(action) {
  try {
    const id = yield select((store) => store?.superAdmin?.adminUsers?.id ?? "")
    const payload = { activate: action.payload }
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/super-admin/${id}/activate/`, payload)

    if (data.success) {
      yield put(appActions.setIsLoading(false))
      toast.success("Status Updated", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      const { data: user } = yield call(axios.get, `${baseUrl}/user/${id}/`)
      if (user.success) {
        yield put(appActions.setIsLoading(false))
        yield put(slice.actions.setAdminUserData(user?.data || {}))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getDashboardStats() {
  try {
    yield put(appActions.setIsLoading(true))

    const { data } = yield call(axios.get, `${baseUrl}/super-admin/user-count/`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setDashboardStats(data?.data || {}))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getAppVersionsList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { page, search } = action.payload
    let params = `page=${page}`
    if (search && search !== "") {
      params += `&search=${search}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/version/logs/?${params}`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(
        slice.actions.setVersionListData({
          totalPages: data?.data?.pages,
          data: data?.data.results || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* addVersionItems(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/version/logs/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      const { data: newData } = yield call(axios.get, `${baseUrl}/version/logs`)
      if (newData.success) {
        yield put(
          slice.actions.setVersionListData({
            totalPages: newData?.data?.pages,
            data: newData?.data.results || []
          })
        )
        yield put(appActions.setIsLoading(false))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getVersionData() {
  const id = yield select((store) => store?.superAdmin?.versionId ?? "")
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/version/logs/${id}`)
    if (data.success) {
      yield put(slice.actions.setVersiondata(data?.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* updateVersionItem(action) {
  const id = yield select((store) => store?.superAdmin?.versionId ?? "")
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.patch, `${baseUrl}/version/logs/${id}`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      const { data: newData } = yield call(axios.get, `${baseUrl}/version/logs`)
      if (newData.success) {
        yield put(
          slice.actions.setVersionListData({
            totalPages: newData?.data?.pages,
            data: newData?.data.results || []
          })
        )
        yield put(appActions.setIsLoading(false))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* deleteVersionItem() {
  const id = yield select((store) => store?.superAdmin?.versionId ?? "")
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/version/logs/${id}`)
    yield put(appActions.setIsLoading(false))
    const { data: newData } = yield call(axios.get, `${baseUrl}/version/logs`)
    if (newData.success) {
      yield put(
        slice.actions.setVersionListData({
          totalPages: newData?.data?.pages,
          data: newData?.data.results || []
        })
      )
      yield put(slice.actions.setVersionID(null))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* addNotification(action) {
  try {
    const { data } = yield call(axios.post, `${baseUrl}/notifications/`, action.payload)
    if (data.success) {
      toast.success("Notification has been added", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      let params = `page=1&ordeing=-scheduled_date`
      yield put(appActions.setIsLoading(true))
      const { data: newData } = yield call(axios.get, `${baseUrl}/notifications/?${params}`)
      if (newData.success) {
        yield put(appActions.setIsLoading(false))
        yield put(
          slice.actions.setNotificationList({
            totalPages: newData?.data?.pages,
            data: newData?.data?.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getNotificationList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { page, orderBy } = action.payload
    let params = `page=${page}`
    if (orderBy) {
      params += `&ordering=${orderBy}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/notifications/?${params}`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(
        slice.actions.setNotificationList({
          totalPages: data?.data?.pages,
          data: data?.data?.results || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* deleteNotification(action) {
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/notifications/${action?.payload}`)
    yield put(appActions.setIsLoading(false))
    let params = `page=1&ordeing=-scheduled_date`
    const { data: newData } = yield call(axios.get, `${baseUrl}/notifications/?${params}`)
    if (newData.success) {
      yield put(
        slice.actions.setNotificationList({
          totalPages: newData?.data?.pages,
          data: newData?.data?.results || []
        })
      )
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* sendNotification(action) {
  try {
    const { data } = yield call(axios.post, `${baseUrl}/notifications/trigger/`, action.payload)
    if (data.success) {
      toast.success("Notification has been sent", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      let params = `page=1&ordeing=-scheduled_date`
      yield put(appActions.setIsLoading(true))
      const { data: newData } = yield call(axios.get, `${baseUrl}/notifications/?${params}`)
      if (newData.success) {
        yield put(appActions.setIsLoading(false))
        yield put(
          slice.actions.setNotificationList({
            totalPages: newData?.data?.pages,
            data: newData?.data?.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* EditNotificationData(action) {
  try {
    const id = yield select((store) => store?.superAdmin?.notifications?.id ?? "")
    yield put(appActions.setIsLoading(true))

    const { data } = yield call(axios.patch, `${baseUrl}/notifications/${id}/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      toast.success("Edit Successful", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      let params = `page=1&ordeing=-scheduled_date`
      yield put(appActions.setIsLoading(true))
      const { data: newData } = yield call(axios.get, `${baseUrl}/notifications/?${params}`)
      if (newData.success) {
        yield put(appActions.setIsLoading(false))
        yield put(
          slice.actions.setNotificationList({
            totalPages: newData?.data?.pages,
            data: newData?.data?.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getNotifications() {
  const id = yield select((store) => store?.superAdmin?.notifications?.id ?? "")
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/notifications/${id}`)
    if (data.success) {
      yield put(slice.actions.setNotificationData(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* editNotification(action) {
  const id = yield select((store) => store?.superAdmin?.notifications?.id ?? "")
  try {
    const { data } = yield call(axios.patch, `${baseUrl}/notifications/${id}`, action.payload)
    if (data.success) {
      toast.success("Notification has been edited", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      let params = `page=1&ordeing=-scheduled_date`
      yield put(appActions.setIsLoading(true))
      const { data: newData } = yield call(axios.get, `${baseUrl}/notifications/?${params}`)
      yield put(slice.actions.setNotificationID(null))
      if (newData.success) {
        yield put(appActions.setIsLoading(false))
        yield put(
          slice.actions.setNotificationList({
            totalPages: newData?.data?.pages,
            data: newData?.data?.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadNotificationImage(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const id = toast.loading("Uploading Image", {
      position: "bottom-right",
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      theme: "dark"
    })
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      toast.update(id, {
        render: "Image Uploaded",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        progress: undefined,
        theme: "dark"
      })
      yield put(slice.actions.setNotificationImageId(data.data.id))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getGiftCardList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { page, giftCard_type } = action.payload

    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/gift-card/?page=${page}${
        giftCard_type && giftCard_type !== "All" ? `&card_type=${giftCard_type}` : ""
      }`
    )
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(
        slice.actions.setGiftCardList({
          totalPages: data?.data?.pages,
          data: data?.data?.results || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getGiftCard() {
  const id = yield select((store) => store?.superAdmin?.giftCards?.id ?? "")
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/${id}/gift-card`)
    if (data.success) {
      yield put(slice.actions.setGiftCardData(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* addGiftCard(action) {
  try {
    const { payload, onClose } = action.payload
    const giftCard_type = yield select((store) => store.app?.filters?.giftCard_type)
    let params = `page=1`
    if (giftCard_type && giftCard_type !== "All") {
      params += `&card_type=${giftCard_type}`
    }
    const { data } = yield call(axios.post, `${baseUrl}/frontline/gift-card/`, payload)
    if (data.success) {
      onClose()
      toast.success("Price has been added", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      yield put(appActions.setIsLoading(true))
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/gift-card/?${params}`)
      if (newData.success) {
        yield put(appActions.setIsLoading(false))
        yield put(
          slice.actions.setGiftCardList({
            totalPages: newData?.data?.pages,
            data: newData?.data?.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* deleteGiftCard(action) {
  try {
    const giftCard_type = yield select((store) => store.app?.filters?.giftCard_type)
    let params = `page=1`
    if (giftCard_type && giftCard_type !== "All") {
      params = `&card_type=${giftCard_type}`
    }
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/frontline/${action?.payload}/gift-card`)
    yield put(appActions.setIsLoading(false))
    const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/gift-card/?${params}`)
    if (newData.success) {
      yield put(
        slice.actions.setGiftCardList({
          totalPages: newData?.data?.pages,
          data: newData?.data?.results || []
        })
      )
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* EditGiftCard(action) {
  const id = yield select((store) => store?.superAdmin?.giftCards?.id ?? "")
  const giftCard_type = yield select((store) => store.app?.filters?.giftCard_type)
  let params = `page=1`
  if (giftCard_type && giftCard_type !== "All") {
    params = `&card_type=${giftCard_type}`
  }
  try {
    const { payload, onClose } = action.payload
    const { data } = yield call(axios.patch, `${baseUrl}/frontline/${id}/gift-card`, payload)
    if (data.success) {
      onClose()
      toast.success("Price has been edited", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      yield put(appActions.setIsLoading(true))
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/gift-card/?${params}`)
      yield put(slice.actions.setGiftCardID(null))
      if (newData.success) {
        yield put(appActions.setIsLoading(false))
        yield put(
          slice.actions.setGiftCardList({
            totalPages: newData?.data?.pages,
            data: newData?.data?.results || []
          })
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadGiftCardImage(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const id = toast.loading("Uploading Image", {
      position: "bottom-right",
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      theme: "dark"
    })
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      toast.update(id, {
        render: "Image Uploaded",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        progress: undefined,
        theme: "dark"
      })
      yield put(slice.actions.setGiftCardImageId(data.data.id))
    }
  } catch (e) {
    console.log(e)
  }
}
