import { Grid, Typography } from "@mui/material"
import Card from "components/Card/Card"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import AppTextField from "components/StyledComponents/AppTextField"
import React, { useState } from "react"
import { Col } from "react-bootstrap"

const TastingNotesForm = ({ formik, index, arrayHelper, isEditMode }) => {
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
  // const [openCrop, setCrop] = useState(false);

  // const [fileDetails, setFileDetails] = useState(null);
  // const [imageObjectURL, setImageObjectUrl] = useState(null)

  // const handleImageUpload = (image) => {
  //   if (image) {
  //     setCrop(true)
  //     setFileDetails(image)
  //     setImageObjectUrl(URL.createObjectURL(image));
  //   }
  // };
  // const handleImageDelete = () => {
  //   dispatch(DMVActions.setTastingNotesFlavourImage({ index, id: null }));
  //   formik.setFieldValue(`tastingNotesSet[${index}].image`, null);
  // };

  const toggleDeletePopup = () => {
    setIsDeletePopupOpen((prev) => !prev)
  }

  const handleDeleteTastingNote = () => {
    arrayHelper.remove(index)
  }

  const onDeleteClick = () => {
    if (isEditMode) {
      toggleDeletePopup()
    } else {
      arrayHelper.remove(index)
    }
  }

  // function handleCroppedImage(image,formik) {
  //   let data = new FormData();
  //   data.append("media", image);
  //   data.append("media_type", "IMAGE");
  //   data.append("content_type", image.type);
  //   dispatch(uploadTastingNotesFlavourImageAction({ index, data }));
  //   formik.setFieldValue(`tastingNotesSet[${index}].image`, image);
  // }

  // function showCropperModal(imageObjectURL, fileDetails, formik) {
  //   return (
  //     <ImageCropper
  //       setSelectedImage={(image) => handleCroppedImage(image,formik)}
  //       srcImage={imageObjectURL}
  //       fileDetail={fileDetails}
  //       aspectRatio={1}
  //       onClose={setCrop}
  //       openCrop={openCrop}
  //     >
  //     </ImageCropper>
  //   )
  // }

  return (
    <Card className="mt-4" style={{ width: "65%" }}>
      {/* {showCropperModal(imageObjectURL, fileDetails,formik)} */}
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        title="Delete Tasting Note"
        onDelete={handleDeleteTastingNote}
        onClose={toggleDeletePopup}
      />
      <Col lg="12" className="d-flex justify-content-between align-items-center">
        <Typography variant="h2">{`Tasting Note ${index + 1}`}</Typography>
        {formik.values?.tastingNotesSet?.length > 1 ? (
          <Typography
            role="button"
            onClick={() => onDeleteClick()}
            color="error"
            className="body1Regular">
            Delete
          </Typography>
        ) : null}
      </Col>
      <Grid container spacing={4} key={`tastingnote${index}`} className="mt-2">
        <Grid item sm={12} md={12} lg={12} className="mb-4">
          <AppTextField
            label={`Flavor ${index + 1}`}
            placeholder="Enter Flavor"
            isRequired={true}
            fullWidth
            value={formik.values.tastingNotesSet[index].flavour}
            onChange={formik.handleChange(`tastingNotesSet[${index}].flavour`)}
            error={
              formik.touched?.tastingNotesSet?.[index]?.flavour &&
              Boolean(formik.errors?.tastingNotesSet?.[index]?.flavour)
            }
            helperText={
              formik.touched?.tastingNotesSet?.[index]?.flavour &&
              formik.errors?.tastingNotesSet?.[index]?.flavour
            }
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default TastingNotesForm
