import React, { Fragment } from "react";
import { Box, Divider } from "@mui/material";
import Question from "./Question";
import styles from "../../trivia-contest/components/PendingQuestionTable/PendingQuestionTable.module.scss";
import { useTheme } from "@mui/system";
import SuggestedBy from "./SuggestedBy";

function TriviaQuestions({ questions }) {
  const theme = useTheme();

  const style = {
    box: {
      borderRadius: "0",
      padding: "0",
      background: "none"
    },
    divider: {
      background: theme.palette.text.primary,
      height: "2px",
      opacity: 0.12
    }
  };
  return (
    <Fragment>
      <Divider sx={style.divider} />
      {questions?.map((question, index) => (
        <Fragment key={question?.id}>
          <Box key={question?.id} className={styles.questionCtr} sx={style.box}>
            <Question
              question={question}
              status={question?.status}
              index={index}
            />
            <div className="d-flex">
              {
                <div
                  className="me-5 p-2 d-flex align-items-center justify-content-center"
                  style={{ width: "200px", height: "auto" }}>
                  <img
                    className="img-fluid"
                    style={{ borderRadius: "6px" }}
                    src={question.image}></img>
                </div>
              }
              {question?.is_suggested && (
                <SuggestedBy question={question} index={index} />
              )}
            </div>
          </Box>
          {question?.id !== questions?.length && <Divider sx={style.divider} />}
        </Fragment>
      ))}
    </Fragment>
  );
}

export default TriviaQuestions;
