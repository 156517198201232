import React, { Fragment } from "react"
import { Box, useMediaQuery } from "@mui/material"
import ActivePlayersIcon from "assets/icons/ActivePlayersIcon.svg"
// import CheckedIcon from "assets/icons/CheckedIcon.svg";
import EventName from "./components/EventName"
import CreateIcon from "@mui/icons-material/Create"

import NumberOfPlayers from "./components/NumberOfPlayers"

function EventContent({ onTitleClick, eventInfo, setModalState, setCommentModal, modalState }) {
  const lowRes = useMediaQuery("(max-width: 1366px)")
  const { eventData } = eventInfo.event._def.extendedProps
  const notes = eventInfo?.event?._def?.extendedProps?.notes
  
  return (
    <Fragment>
      <div className="me-3">
        <CreateIcon
          onClick={() => setCommentModal(true)}
          sx={(theme) => ({
            fontSize: "18px",
            color: theme.palette.secondary.main,
            cursor: "pointer"
          })}></CreateIcon>
      </div>

      <div className="event-content">
        {notes?.id ? (
          <EventName
            key={`event${notes.id}`}
            title={notes.note}
            lowRes="sds"
            icon={null}
            onTitleClick={() => onTitleClick()}
          />
        ) : null}

        {eventData?.length ? (
          <>
            {eventData?.map((event, index) => {
              return (
                <EventName
                  key={`event-${index}`}
                  title={event.title}
                  lowRes={lowRes}
                  icon={event.icon}
                  onTitleClick={() => onTitleClick(index)}
                />
              )
            })}
          </>
        ) : null}

        <Box height={(3 - (eventData?.length || 0)) * 24} />

        {/* Stats */}
        <div className="day-icon-tray">
          <Fragment>
            {/* Active Players */}
            <NumberOfPlayers
              tooltip="Active Players"
              data={eventInfo.event._def.extendedProps.activePlayers}
              icon={ActivePlayersIcon}
            />

            {/* Players who completed quiz */}
            <NumberOfPlayers
              onClick={() => setModalState(!modalState)}
              isStats={true}
              tooltip="Daily Statistics"
              data={eventInfo.event._def.extendedProps.checked}
            />

            {/* Empty shell for balancing with flexbox */}
            <p style={{ width: "13px" }}> </p>
          </Fragment>
        </div>
      </div>
    </Fragment>
  )
}

export default EventContent
