import { Box } from "@mui/material"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import InfoCard from "../../../../components/PerformanceSummary/InfoCard/infoCard"
import PerformanceSummaryTable from "components/PerformanceSummary/PerformanceSummaryTable"
import { useDispatch, useSelector } from "react-redux"
import { getLeaderboardDataAction, getSingleUserDataGraphAction } from "redux/marketing/actions"
import { appActions } from "redux/app/slice/app"
import { marketingActions } from "redux/marketing/slice/user"

const GMPerformanceSummary = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const data = useSelector((state) => state?.marketing?.leaderboard?.data ?? [])
  const { userId } = useSelector((state) => state?.marketing?.leaderboard ?? "")
  const dispatch = useDispatch()

  const { date, employeeClass, orderBy } = useSelector((store) => store?.app?.filters || {})
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "leader Board"
  }, [])

  useEffect(() => {
    dispatch(
      getLeaderboardDataAction({
        date: date,
        page: currentPage,
        employeeClass: employeeClass,
        orderBy: orderBy
      })
    )
  }, [currentPage, date, employeeClass, orderBy])

  useEffect(() => {
    dispatch(appActions.initFilters())
    return () => dispatch(marketingActions.setUserId(""))
  }, [])

  useEffect(() => {
    if (!userId) return
    dispatch(getSingleUserDataGraphAction({ date: date }))
  }, [userId])

  return (
    <Fragment>
      <Container fluid className="p-0">
        <Row>
          <Col lg="12">
            <Header title="Frontline Performance" breadCrumbs={breadCrumbs}></Header>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Box className="d-flex w-100 flex-wrap">
              <InfoCard color="#3ADB76" style={{ paddingLeft: 0 }}>
                Top Servers
              </InfoCard>
              <InfoCard color="#9CC43B">Doing Great</InfoCard>
              <InfoCard color="#FFAE00">Average</InfoCard>
              <InfoCard color="#E67C1B">Underperforming</InfoCard>
              <InfoCard color="#CC4B37" style={{ paddingRight: 0 }}>
                Poor
              </InfoCard>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <PerformanceSummaryTable
              setPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={data?.pages}
              rows={data?.results}
              columns={columns}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col lg="4">
            <DataCard data={inviteMetricsData} />
          </Col>
          <Col lg="4">
            <DataCard data={upgradeMetricsData} />
          </Col>
          <Col lg="4">
            <DataCard data={appUsageData} />
            <AppButton
              onClick={goToTop}
              styles={{ width: "100%", marginTop: 16 }}>
              Back to the top
            </AppButton>
          </Col>
        </Row> */}
      </Container>
    </Fragment>
  )
}

export default GMPerformanceSummary

// const inviteMetricsData = {
//   heading: "Invite Metrics",
//   list: [
//     { key: "Total Signups", field: "203" },
//     { key: "Total Non-Member Guests", field: "5814" },
//     { key: "Invite Rate", field: "3.5%" }
//   ],
//   subData: {
//     heading: "Coaching Opportunity",
//     list: [
//       { key: "Underperforming Servers	", field: "13" },
//       { key: "Lost WCM Opportunity	", field: "13" },
//       { key: "Annualized", field: "600" }
//     ]
//   }
// };

// const upgradeMetricsData = {
//   heading: "Upgrade Metrics",
//   list: [
//     { key: "Total Upgrades", field: "37" },
//     { key: "Total Member Guests", field: "3616" },
//     { key: "Upgrade Rate", field: "1.0%" }
//   ],
//   subData: {
//     heading: "Coaching Opportunity",
//     list: [
//       { key: "Underperforming Servers	", field: "17" },
//       { key: "Lost WCM Opportunity	", field: "18" },
//       { key: "Annualized", field: "216" }
//     ]
//   }
// };

// const appUsageData = {
//   heading: "App Usage",
//   list: [
//     { key: "Team", field: "79%" },
//     { key: "Servers", field: "74%" },
//     { key: "Bartenders", field: "80%" },
//     { key: "Tasting Room Attendants", field: "90%" },
//     { key: "Managers", field: "100%" }
//   ]
// };

const columns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Name",
    label: "Name",
    minWidth: 220
  },
  {
    id: "Has App",
    label: "Has App",
    align: "center",
    minWidth: 90
  },
  {
    id: "Trivia Correct Answers",
    label: "Trivia Correct Answers",
    align: "center",
    minWidth: 90,
    isSorting: true,
    sortId: "correct_answer"
  },
  {
    id: "Trivia Question Played",
    label: "Trivia Question Played",
    align: "center",
    minWidth: 100,
    isSorting: true,
    sortId: "total_attempts"
  },
  {
    id: "Coins Earned",
    label: "Coins Earned",
    align: "center",
    minWidth: 100,
    isSorting: true,
    sortId: "cooper_coins"
  },
  {
    id: "Trivia Correct %",
    label: "Trivia Correct %",
    align: "center",
    minWidth: 100,
    isSorting: true,
    sortId: "user_correct_percent"
  }
]

// const rows = [
//   {
//     id: 1,
//     rank: 1,
//     name: "Carlos Pena",
//     image: "https://randomuser.me/api/portraits/men/31.jpg",
//     hasApp: true,
//     bottles: 36,
//     percentTotal: 15,
//     nonMembers: 300,
//     inviteRate: 10.0
//   },
//   {
//     id: 2,
//     rank: 2,
//     name: "Josephine Flores",
//     image: "https://randomuser.me/api/portraits/men/32.jpg",
//     hasApp: true,
//     bottles: 24,
//     percentTotal: 10.0,
//     nonMembers: 250,
//     inviteRate: 8.0
//   },
//   {
//     id: 3,
//     rank: 3,
//     name: "Robert Fox",
//     image: "https://randomuser.me/api/portraits/men/51.jpg",
//     hasApp: true,
//     bottles: 19,
//     percentTotal: 18.0,
//     nonMembers: 260,
//     inviteRate: 6.2
//   },
//   {
//     id: 4,
//     rank: 4,
//     name: "Arlene McCoy",
//     image: "https://randomuser.me/api/portraits/women/40.jpg",
//     hasApp: true,
//     bottles: 17,
//     percentTotal: 7.0,
//     nonMembers: 280,
//     inviteRate: 5.0
//   },
//   {
//     id: 5,
//     rank: 5,
//     name: "Marvin McKinney",
//     image: "https://randomuser.me/api/portraits/men/31.jpg",
//     hasApp: true,
//     bottles: 16,
//     percentTotal: 6.0,
//     nonMembers: 290,
//     inviteRate: 4.5
//   },
//   {
//     id: 6,
//     rank: 6,
//     name: "Jade Cooper",
//     image: "https://randomuser.me/api/portraits/women/39.jpg",
//     hasApp: true,
//     bottles: 14,
//     percentTotal: 6.0,
//     nonMembers: 270,
//     inviteRate: 4.4
//   },
//   {
//     id: 7,
//     rank: 7,
//     name: "Kristin Watson",
//     image: "https://randomuser.me/api/portraits/women/40.jpg",
//     hasApp: true,
//     bottles: 13,
//     percentTotal: 50,
//     nonMembers: 222,
//     inviteRate: 50
//   },
//   {
//     id: 8,
//     rank: 8,
//     name: "Esther Howard",
//     image: "https://randomuser.me/api/portraits/men/51.jpg",
//     hasApp: true,
//     bottles: 12,
//     percentTotal: 5.0,
//     nonMembers: 220,
//     inviteRate: 4.5
//   },
//   {
//     id: 9,
//     rank: 9,
//     name: "Dianne Russell",
//     image: "https://randomuser.me/api/portraits/men/32.jpg",
//     hasApp: true,
//     bottles: 10,
//     percentTotal: 4.0,
//     nonMembers: 220,
//     inviteRate: 4.5
//   },
//   {
//     id: 10,
//     rank: 10,
//     name: "Ralph Edwards",
//     image: "https://randomuser.me/api/portraits/men/33.jpg",
//     hasApp: true,
//     bottles: 10,
//     percentTotal: 4.0,
//     nonMembers: 220,
//     inviteRate: 3.6
//   },
//   {
//     id: 11,
//     rank: 11,
//     name: "Guy Hawkins",
//     image: "https://randomuser.me/api/portraits/men/34.jpg",
//     hasApp: true,
//     bottles: 10,
//     percentTotal: 4.0,
//     nonMembers: 220,
//     inviteRate: 3.6
//   },
//   {
//     id: 12,
//     rank: 12,
//     name: "Leslie Alexander",
//     image: "https://randomuser.me/api/portraits/women/39.jpg",
//     hasApp: true,
//     bottles: 10,
//     percentTotal: 4.0,
//     nonMembers: 220,
//     inviteRate: 3.6
//   },
//   {
//     id: 13,
//     rank: 13,
//     name: "Taylor Mulder",
//     image: "https://randomuser.me/api/portraits/men/35.jpg",
//     hasApp: false,
//     bottles: 0,
//     percentTotal: 0.0,
//     nonMembers: 120,
//     inviteRate: 0.0
//   },
//   {
//     id: 14,
//     rank: 14,
//     name: "Kate Harang",
//     image: "https://randomuser.me/api/portraits/women/41.jpg",
//     hasApp: false,
//     bottles: 0,
//     percentTotal: 0.0,
//     nonMembers: 120,
//     inviteRate: 0.0
//   },
//   {
//     id: 15,
//     rank: 15,
//     name: "Mara Lidle",
//     image: "https://randomuser.me/api/portraits/women/42.jpg",
//     hasApp: false,
//     bottles: 0,
//     percentTotal: 0.0,
//     nonMembers: 120,
//     inviteRate: 0.0
//   }
// ]
