import axios from "axios";
import { toast } from "react-toastify";
import store from "./redux";
import { appActions } from "./redux/app/slice/app";

axios.interceptors.request.use(
  (config) => {
    const { dispatch } = store;
    const jwtToken = localStorage.getItem("access_token");
    if (jwtToken) {
      config.headers.Authorization = `Bearer ${jwtToken}`;
      dispatch(appActions.setLoadingData(true));
    }
    return config;
  },
  (error) => {
    const { dispatch } = store;

    dispatch(appActions.setIsLoggedInData(false));
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    const { dispatch } = store;
    dispatch(appActions.setMessageData({ text: "", variant: "" }));
    const { message } = response.data || {};

    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "dark"
    });

    dispatch(appActions.setLoadingData(false));
    dispatch(appActions.setMessageData({ text: message, variant: "success" }));
    return response;
  },
  (error) => {
    const errorMessage = error.response?.data?.error?.message[0];
    if (error?.response?.status == 400 || error?.response?.status == 404) {
      const { dispatch } = store;
      dispatch(appActions.setIsLoading(false))
      dispatch(appActions.setLoadingData(false));
      dispatch(appActions.setIsLoading(false))
    }

    if (errorMessage && !(error.response?.data?.code === 401 || error.response?.data?.code === 403)) {
      toast.error(errorMessage, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      });
    }
    if (error?.response?.status == 500) {
      toast.error(error.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      });
    }

    const toastId = "customToast"

    const { dispatch } = store;
    dispatch(appActions.setMessageData({ text: "", variant: "" }));
    const { message } = error || {};
    dispatch(appActions.setLoadingData(false));
    if (error.response?.data?.code === 402 || error.response?.data?.code === 401 || error.response?.data?.code === 403) {
      if (!toast.isActive(toastId.current)) {
        toast.error(errorMessage, {
          toastId: toastId,
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark"
        });
      }

      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      dispatch(appActions.setIsLoggedInData(false));
      dispatch(appActions.setLoadingData(false));
      dispatch(appActions.setIsLoading(false))
    }

    dispatch(appActions.setMessageData({ text: message, variant: "error" }));
    Promise.reject(error);
  }
);
