import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import styles from "../PendingQuestionTable/";
import { styled } from "@mui/system";
import QuestionRow from "./components/QuestionRow";

const StyledTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  "& .MuiTableCell-root": {
    padding: "14px 24px 14px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `2px solid ${theme.palette.background.default}`,
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },

  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}));

const EditPendingQuestion = ({ rows = [], columns = [], contestData, isEdit }) => {
  const checkStatus = (index) => {
    if (
      contestData?.status?.toLowerCase() === "published" &&
      index === columns?.length - 1
    ) {
      const date = new Date(contestData?.start_date);
      return date > new Date();
    }
    return true;
  };

  return (
    <Paper className={styles.container}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <StyledTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <>
                  {checkStatus(index) ? (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      <Typography
                        className={styles.header}
                        variant="body2Regular">
                        {column.label}
                      </Typography>
                    </TableCell>
                  ) : null}
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <QuestionRow
                  isEdit={isEdit}
                  key={row.id}
                  row={row}
                  index={index}
                  contestData={contestData}
                />
              );
            })}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Paper>
  );
};

export default EditPendingQuestion;
