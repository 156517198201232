import React, { useState } from "react"
import Card from "components/Card/Card"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import { Menu, MenuItem, Typography } from "@mui/material"
import trashIcon from "assets/icons/trash.svg"
import { useTheme } from "@mui/system"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { useDispatch } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { getBarItemsAction } from "redux/marketing/actions"
import AppButton from "components/StyledComponents/AppButton"
import ImageDisplay from "components/ImageDisplayer"
import classes from "pages/private/marketing/menu/menu.module.scss"
import Paragraph from "components/ReadMoreParagraph"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import {
  DeleteBarMenuCategoryAction,
  deleteBarItemAction,
  updateBarItemsAction
} from "redux/marketing/actions/user"

function BarListItems({
  data,
  isEdit = false,
  setCategoryModal = () => {},
  setIsEdit = () => {},
  setIsEditModalItem = () => {},
  setCurrentCategoryData = () => {},
  setMenuItemsModal = () => {}
}) {
  const theme = useTheme()
  const [modal, setDeleteModal] = useState(false)
  const [modalItem, setDeleteItemModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [ArchiveModal, setArchiveItemModal] = useState(false)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  function EditCategoryModal(id) {
    dispatch(marketingActions.setMenuCategoryId(id))
    setCategoryModal(true)
    setIsEdit(true)
    setCurrentCategoryData(data)
  }

  function handleMenuPeriodDelete(id) {
    dispatch(marketingActions.setMenuCategoryId(id))
    dispatch(DeleteBarMenuCategoryAction({ mealCategoryId: id }))
  }

  function handleMenuDeleteModal(id) {
    dispatch(marketingActions.setMenuCategoryId(id))
    setDeleteModal(true)
  }

  function handleMenuItemDeleteModal() {
    handleClose()
    setDeleteItemModal(true)
    setAnchorEl(null)
  }

  function handleEditMenuItemModal() {
    setAnchorEl(null)
    setIsEditModalItem(true)
    setMenuItemsModal(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event, id, categoryId) => {
    setAnchorEl(event.currentTarget)
    dispatch(marketingActions.setMenuItemId(id))
    dispatch(marketingActions.setMenuCategoryId(categoryId))
    dispatch(getBarItemsAction())
  }

  function handleMenuItemDelete() {
    dispatch(deleteBarItemAction())
  }

  function handleCloseModal() {
    setDeleteItemModal(false)
    dispatch(marketingActions.setMenuItemId(""))
  }

  function handleArchiveItem() {
    setArchiveItemModal(true)
    setAnchorEl(null)
  }

  function handleCloseArchiveModal() {
    setArchiveItemModal(false)
  }

  function handleArchiveMenuItem() {
    const payload = {
      item_state: "archived"
    }
    dispatch(updateBarItemsAction(payload))
  }

  function handleAddItems(id) {
    setIsEditModalItem(false)
    dispatch(marketingActions.setMenuCategoryId(id))
    setMenuItemsModal(true)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }
  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to Delete “${data?.food_category ?? ""}”?`}
        description={`"${data?.food_category ?? ""}" has ${data?.food_list?.length ?? ""} ${
          data?.food_list?.length <= 1 ? "dish" : "dishes"
        }`}
        style={{ textAlign: "center", width: "80%" }}
        onClose={() => setDeleteModal(false)}
        onDelete={() => handleMenuPeriodDelete(data?.food_category_id)}
        open={modal}></DeleteConfirmationDialog>
      <DeleteConfirmationDialog
        title={`Are You Sure You Want To Delete This Bar Item ?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseModal}
        onDelete={() => handleMenuItemDelete()}
        open={modalItem}></DeleteConfirmationDialog>
      <DeleteConfirmationDialog
        title={`Are You Sure You Want To Archive This Bar Item?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseArchiveModal}
        onDelete={() => handleArchiveMenuItem()}
        open={ArchiveModal}
        btnText="Archive"
        type="warning"
      />
      <Card style={{ height: "fit-content" }}>
        <Row>
          <Col lg="12">
            <div
              className="d-flex justify-content-between align-item-center"
              style={{
                backgroundColor: isEdit && theme.palette.background.default,
                padding: ` ${isEdit ? "12px 16px" : "0px"}`,
                borderRadius: 6
              }}>
              <Typography variant="h2">{data?.food_category}</Typography>
              <div className="d-flex align-items-center">
                {isEdit && (
                  <img
                    className="me-4"
                    onClick={() => handleMenuDeleteModal(data?.food_category_id)}
                    style={{ cursor: "pointer" }}
                    src={trashIcon}
                    alt=""
                  />
                )}
                {isEdit && (
                  <ModeEditIcon
                    onClick={() => EditCategoryModal(data?.food_category_id)}
                    sx={{ color: theme.palette.secondary.main, cursor: "pointer" }}></ModeEditIcon>
                )}
              </div>
            </div>
          </Col>
          <Col lg="12">
            {data?.food_list?.length ? (
              data?.food_list?.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className={classes.itemContainer}
                    style={{
                      border: `${isEdit ? `1px solid ${theme.palette.secondary.main}` : "none"}`,
                      borderBottom: `${
                        !isEdit ? `1px solid #605e5e` : `.5px solid ${theme.palette.secondary.main}`
                      }`,
                      borderRadius: `${isEdit ? "6px" : "0px"}`,
                      padding: `${isEdit ? "8px" : "5px"}`
                    }}>
                    <div className={classes.imageContainer} style={{ height: 250 }}>
                      <ImageDisplay data={item?.image_url}></ImageDisplay>
                    </div>
                    <div
                      style={{
                        // display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        maxWidth: 375,
                        overflowWrap: "break-word"
                      }}>
                      <div>
                        <Typography variant="h4">{item?.name ?? "-"}</Typography>
                      </div>

                      <div>
                        {(item?.ingredient?.length > 0 || isEdit) && (
                          <div className="mt-2">
                            <Typography variant="tag1">{"Ingredients:"}</Typography>
                            <br></br>
                            {item?.ingredient?.map((item, index) => (
                              <Typography key={index} variant="tagItalic" sx={{ opacity: 0.6 }}>
                                {`${item?.name ?? ""}`}
                                {item?.measurement
                                  ? item?.ingredient?.length - 1 > index
                                    ? ` (${item?.measurement}), `
                                    : item?.measurement
                                    ? ` (${item?.measurement}) `
                                    : ""
                                  : item?.ingredient?.length - 1 > index
                                  ? ", "
                                  : ""}
                              </Typography>
                            ))}
                          </div>
                        )}
                        {(item?.allergance?.length > 0 || isEdit) && (
                          <div className="mt-2">
                            <Typography variant="tag1">{"Allergens:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.allergance?.map((allergance, index) => {
                                return (
                                  allergance?.name +
                                    (item?.allergance?.length - 1 > index ? ", " : "") ?? "-"
                                )
                              })}
                            </Typography>
                          </div>
                        )}
                        {(item?.preparation_method || isEdit) && (
                          <div className="mt-2">
                            <Typography variant="tag1">{"Preparation Method:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.preparation_method ?? "-"}
                            </Typography>
                          </div>
                        )}
                        {(item?.garnish || isEdit) && (
                          <div className="mt-2">
                            <Typography variant="tag1">{"Garnish:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.garnish ?? "-"}
                            </Typography>
                          </div>
                        )}
                        {(item?.glassware || isEdit) && (
                          <div className="mt-2">
                            <Typography variant="tag1">{"Glassware:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {item?.glassware ?? "-"}
                            </Typography>
                          </div>
                        )}
                        {(item?.sound_bite || isEdit) && (
                          <div className="mt-2">
                            <Typography variant="tag1">{"Bartender’s Sound Bites:"}</Typography>
                            <br></br>
                            <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                              {
                                <Paragraph
                                  text={item?.sound_bite ?? ""}
                                  maxCharCount={70}></Paragraph>
                              }
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      {item?.item_price > 0 && (
                        <div>
                          <Typography variant="bold">${item?.item_price}</Typography>
                        </div>
                      )}
                      {isEdit && (
                        <div>
                          <MoreVertIcon
                            id="demo-positioned-button"
                            aria-controls={open ? "demo-positioned-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={(e) => handleClick(e, item?.id)}
                            sx={{ cursor: "pointer" }}></MoreVertIcon>
                          <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right"
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right"
                            }}>
                            <MenuItem
                              onClick={() => handleEditMenuItemModal()}
                              sx={{ width: "150px" }}>
                              Edit
                            </MenuItem>
                            <MenuItem onClick={() => handleArchiveItem()} sx={{ width: "150px" }}>
                              Archive
                            </MenuItem>
                            <MenuItem
                              onClick={handleMenuItemDeleteModal}
                              sx={{ color: theme.palette.red.main }}>
                              Delete
                            </MenuItem>
                          </Menu>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })
            ) : (
              <Typography className="mt-4" style={{ opacity: 0.6, textAlign: "center" }}>
                No Items Found
              </Typography>
            )}
          </Col>
          <Col lg="12">
            {isEdit && (
              <AppButton
                className="mt-4"
                onClick={() => handleAddItems(data?.food_category_id)}
                styles={{
                  backgroundColor: theme.palette.background.main,
                  padding: "10px 20px",
                  width: "100%"
                }}>
                + Add New Menu Item
              </AppButton>
            )}
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default React.memo(BarListItems)
