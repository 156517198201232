import React, { useEffect, useRef, useState } from "react"
import {
  Backdrop,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled
} from "@mui/material"
import AppPagination from "components/StyledComponents/AppPagination"
import styles from "./notificationList.module.scss"
import { toTitleCase } from "helpers/functions"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import TableNoRecords from "components/TableNoRecords"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import InfoIcon from "@mui/icons-material/Info"
import { useTheme } from "@mui/system"
import { useDispatch } from "react-redux"
import { appActions } from "redux/app/slice/app"
import AppButton from "components/StyledComponents/AppButton"
import avatar from "assets/images/user/avatarUser.svg"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import {
  deleteNotificationAction,
  getNotificationsAction,
  sendNotificationAction
} from "redux/superAdmin/actions"
import { superAdminAction } from "redux/superAdmin/slice/user"
import moment from "moment"

const NotificationListTable = ({
  columns = [],
  rows = [],
  setPage,
  currentPage,
  totalPage,
  handleEditNotification
}) => {
  const themes = useTheme()
  const [order, setOrder] = useState(orderStat.DESC)
  const [anchorEl, setAnchorEl] = useState(false)
  const openMenu = Boolean(anchorEl)
  const dispatch = useDispatch()
  const rowId = useRef(null)
  const [deleteNotification, setDeleteNotification] = useState(false)
  const [resendNotification, setResendNotification] = useState(false)

  const onOptionsClick = (e, id) => {
    e.stopPropagation()
    rowId.current = id
    setAnchorEl(e.currentTarget)
    dispatch(superAdminAction.setNotificationID(id))
    dispatch(getNotificationsAction())
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  function handleSort() {
    if (order === orderStat.DESC) {
      setOrder("scheduled_date")
      dispatch(appActions.setOrderBy("scheduled_date"))
    } else {
      setOrder("-scheduled_date")
      dispatch(appActions.setOrderBy("-scheduled_date"))
    }
  }

  useEffect(() => {
    setOrder("-scheduled_date")
    dispatch(appActions.setOrderBy("-scheduled_date"))
  }, [])

  function handleDelete() {
    setDeleteNotification(true)
  }

  function handleDeleteNotification() {
    dispatch(deleteNotificationAction(rowId.current))
    rowId.current = null
  }

  function handleResend(id) {
    rowId.current = id
    setResendNotification(true)
  }

  function handleSendNotification() {
    const payload = {
      notification: rowId.current
    }
    dispatch(sendNotificationAction(payload))
    rowId.current = null
  }

  const formatTime = (date, time) => {
    if (!date || !time) return ""
    // convert time to local time
    const initDate = moment(date).format("ddd MMM DD YYYY") + " " + time
    const temp = new Date(initDate)
    const convertedUTCTime = new Date(
      Date.UTC(
        temp.getFullYear(),
        temp.getMonth(),
        temp.getDate(),
        temp.getHours(),
        temp.getMinutes(),
        temp.getSeconds()
      )
    )
    let formatedTime = moment(convertedUTCTime).local().format("hh:mm a")
    // convert date to local time
    const forDate = moment(date).format("ddd MMM DD YYYY") + " " + time
    const newDate = new Date(forDate)
    const convertedUTCDate = new Date(
      Date.UTC(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        newDate.getHours(),
        newDate.getMinutes(),
        newDate.getSeconds()
      )
    )
    let formattedDate = moment(convertedUTCDate).local().format("DD MMM YYYY")
    return `${formattedDate} at ${formatedTime}`
  }

  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to Delete this Notification?`}
        description="  "
        style={{ textAlign: "center", width: "80%" }}
        onClose={() => setDeleteNotification(false)}
        onDelete={() => handleDeleteNotification()}
        open={deleteNotification}
      />
      <DeleteConfirmationDialog
        title={`Are you sure you want to Resend this Notification?`}
        description="  "
        style={{ textAlign: "center", width: "80%" }}
        onClose={() => setResendNotification(false)}
        onDelete={() => handleSendNotification()}
        open={resendNotification}
        btnText="Resend"
        type="warning"
      />
      <Paper>
        <TableContainer sx={{ maxHeight: 700 }}>
          <CustomTable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                      {column.label}{" "}
                      {column?.tooltip && (
                        <Tooltip title={column.tooltip}>
                          <InfoIcon fontSize="small" />
                        </Tooltip>
                      )}
                      {column?.isSorting && (
                        <span onClick={handleSort} style={{ cursor: "pointer" }}>
                          {order === "-scheduled_date" ? (
                            <ArrowDownwardIcon fontSize="small"></ArrowDownwardIcon>
                          ) : (
                            <ArrowUpwardIcon fontSize="small"></ArrowUpwardIcon>
                          )}
                        </span>
                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length ? (
                rows?.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>
                        <div className="d-flex flex-column">
                          <Typography variant="body1Regular">{row?.title ?? "-"}</Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1Regular">
                          {toTitleCase(row?.content ?? "-") ?? "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {row?.employee_class ? (
                          row?.employee_class?.length > 2 ? (
                            <Typography variant="body1Regular">
                              {row?.employee_class
                                ?.map((element) => element.replace(/_/g, " "))
                                ?.slice(0, 2)
                                ?.join(", ")}
                              <Tooltip
                                title={row?.employee_class
                                  ?.map((element) => element.replace(/_/g, " "))
                                  ?.slice(2)
                                  ?.join(", ")}>
                                <span
                                  style={{
                                    color: "#ad916f",
                                    backgroundColor: "#3a312c",
                                    padding: "6px",
                                    borderRadius: "50%",
                                    marginLeft: "5px",
                                    cursor: "pointer"
                                  }}>
                                  +{row?.employee_class?.length - 2}
                                </span>
                              </Tooltip>
                            </Typography>
                          ) : (
                            <Typography variant="body1Regular">
                              {row?.employee_class.slice(0, 3)?.join(", ")}
                            </Typography>
                          )
                        ) : (
                          <Typography variant="body1Regular">-</Typography>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body1Regular">
                          {formatTime(row?.scheduled_date, row?.scheduled_time) || "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <div className={`${styles.tableAvatar}`}>
                          <div className={`${styles.tableAvatar__img}`}>
                            <img src={row?.image_url ? row?.image_url : avatar} />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="body1Regular"
                          className={
                            row?.status?.toLowerCase() === "delivered"
                              ? styles.deliver
                              : row?.status === "failed"
                              ? styles.failure
                              : styles.schedule
                          }>
                          {toTitleCase(row?.status) || "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <AppButton
                          onClick={() => handleResend(row?.id)}
                          sx={{ padding: "10px 20px" }}
                          disabled={row?.status?.toLowerCase() === "scheduled" ? true : false}>
                          Resend
                        </AppButton>
                      </TableCell>
                      <TableCell align="center" onClick={(e) => e.stopPropagation()}>
                        <span onClick={(e) => onOptionsClick(e, row?.id)}>
                          <MoreHorizIcon
                            sx={() => ({
                              height: "24px",
                              width: "24px"
                            })}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableNoRecords colSpan={8} label="No New Notification" />
              )}
            </TableBody>
          </CustomTable>
        </TableContainer>
        <Backdrop sx={{ color: "#fff", zIndex: 1300 }} open={openMenu} onClick={handleMenuClose}>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            PaperProps={{
              sx: {
                marginTop: "15px",
                backgroundColor: themes.palette.background.default,
                borderRadius: "12px",
                "& .MuiList-root": {
                  backgroundColor: themes.palette.background.default,
                  width: "225px",
                  boxSizing: "border-box",
                  padding: "10px 0"
                }
              }
            }}>
            <StyledMenuItem onClick={() => handleEditNotification()}>
              <Typography variant="body1Regular">Edit</Typography>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleDelete}>
              <Typography sx={{ color: themes.palette.red.main }} variant="body1Regular">
                Delete
              </Typography>
            </StyledMenuItem>
          </Menu>
        </Backdrop>
      </Paper>
      {rows?.length ? (
        <AppPagination onPageChange={setPage} totalPages={totalPage} currentPage={currentPage} />
      ) : (
        ""
      )}
    </>
  )
}

export default NotificationListTable

const orderStat = {
  ASC: "scheduled_date",
  DESC: "-scheduled_date"
}

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      borderBottom: "none"
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "sticky",
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "12px 24px"
}))
