import { List, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { getFormattedDate } from "helpers/functions";
import React, { Fragment } from "react";
import { Col } from "react-bootstrap";

function ContestDetails({ contestDetails }) {
  const theme = useTheme();

  const duration = `${getFormattedDate(
    contestDetails?.start_date
  )} - ${getFormattedDate(contestDetails?.end_date)}`;

  const styles = {
    list: {
      display: "flex",
      flexDirection: "column",
      marginTop: "14px",
      marginBottom: "18px"
    },
    label: {
      color: theme.palette.text.secondary,
      opacity: 0.6,
      marginBottom: "4px"
    }
  };

  return (
    <Fragment>
      <Col lg="4" className="ps-0">
        <List sx={styles.list}>
          <Typography sx={styles.label} variant="caption3">
            CONTEST NAME
          </Typography>
          <Typography variant="body1Regular">
            {contestDetails?.name ?? "-"}
          </Typography>
        </List>
      </Col>
      <Col lg="4" className="ps-0">
        <List sx={styles.list}>
          <Typography sx={styles.label} variant="caption3">
            DURATION
          </Typography>
          <Typography variant="body1Regular">{duration}</Typography>
        </List>
      </Col>
      <Col lg="4" className="ps-0">
        <List sx={styles.list}>
          <Typography sx={styles.label} variant="caption3">
            SCOPE
          </Typography>
          <Typography variant="body1Regular">
            {contestDetails?.scope}
          </Typography>
        </List>
      </Col>
      <Col lg="4" className="ps-0">
        <List sx={styles.list}>
          <Typography sx={styles.label} variant="caption3">
            CREATION DATE
          </Typography>
          <Typography variant="body1Regular">
            {getFormattedDate(contestDetails?.creation_date)}
          </Typography>
        </List>
      </Col>
      <Col lg="4" className="ps-0">
        <List sx={styles.list}>
          <Typography sx={styles.label} variant="caption3">
            CREATED BY
          </Typography>
          <Typography variant="body1Regular">
            {contestDetails?.created_by}
          </Typography>
        </List>
      </Col>
      <Col lg="4" className="ps-0">
        <List sx={styles.list}>
          <Typography sx={styles.label} variant="caption3">
            PUBLISHED WEEKLY ON
          </Typography>
          <Typography variant="body1Regular">
            {contestDetails?.published_week_day && `Every ${(contestDetails?.published_week_day ?? "").toLowerCase()}`}
          </Typography>
        </List>
      </Col>
    </Fragment>
  );
}

export default ContestDetails;
