import React from "react";
import { Box, Typography } from "@mui/material";
import styles from "../../PendingQuestionTable/PendingQuestionTable.module.scss";
import ToggleSwitch from "components/StyledComponents/ToggleSwitch";
import { useDispatch } from "react-redux";
import { setQuestionStatusEditTriviaAction } from "redux/L&D/actions";

const SuggestedBy = ({ row, index }) => {
  const dispatch = useDispatch();

  const handleStatusChange = () => {
    dispatch(
      setQuestionStatusEditTriviaAction({
        questionIndex: index,
        questionId: row.id,
        status:
          row?.status?.toLowerCase() === "pending" ? "Approved" : "Pending"
      })
    );
  };

  return (
    <Box className="d-flex flex-column justify-content-between">
      <Box
        className={styles.suggestedBy}
        style={{ visibility: row.suggested_by ? "" : "hidden" }}>
        <Typography variant="caption3">SUGGESTED BY</Typography>
        {row?.suggested_by?.image ? (
          <img
            className={styles.avatar}
            src={row?.suggested_by?.image}
            alt=""
          />
        ) : null}
        <Typography variant="body2Regular">
          {row?.suggested_by?.name}
        </Typography>
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            marginTop: "2px"
          })}
          variant="tag1">
          {row?.suggested_by?.role ?? ""}
        </Typography>
      </Box>
      {row.suggested_by ? (
        <Box className="d-flex">
          <ToggleSwitch
            checked={row?.status?.toLowerCase() === "approved"}
            onChange={handleStatusChange}
            className="me-3"
          />
          {row?.status?.toLowerCase() === "approved" ? (
            <Typography variant="body1Regular">Approved</Typography>
          ) : (
            <Typography
              sx={(theme) => ({ color: theme.palette.text.secondary })}
              variant="body1Regular">
              Approve
            </Typography>
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export default SuggestedBy;
