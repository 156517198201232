import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  styled,
  Typography
} from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import styles from "components/DMVGenerateQuestion/GenerateQyestion.module.scss"
import CloseIcon from "@mui/icons-material/Close"
import AppButton from "components/StyledComponents/AppButton"
import { useDispatch, useSelector } from "react-redux"
import { Col, Row } from "react-bootstrap"
import SearchIcon from "@mui/icons-material/Search"
import Card from "components/Card/Card"
import classes from "pages/private/marketing/menu/menu.module.scss"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { toast } from "react-toastify"
import { useDebounce } from "hooks/utils"
import { useTheme } from "@mui/system"
import SearchTextField from "components/StyledComponents/SearchTextField"
import CMSSpiritsSubMenuNavBar from "components/cmsSpiritsSubNavBar"
import ImageDisplay from "components/ImageDisplayer"
import {
  //  getSpiritCMSAction,
  importSpiritItemAction
} from "redux/marketing/actions/user"
import { getDMVSpiritSubMenuistAction, getImportSpiritAction } from "redux/DMV/actions/user"

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  }
}))

const ImportSpiritsModal = ({ onClose = () => {} }) => {
  const theme = useTheme()
  const [searchValue, setSearchValue] = useState("")
  const dispatch = useDispatch()

  const { menuCategory, spiritData } = useSelector((state) => state.dmv)

  const debouncedSearchTerm = useDebounce(searchValue, 500)

  const handleSearchField = (e) => {
    setSearchValue(e.target.value)
  }

  const [selectedItem, setSelectedItem] = useState([])

  const handleSelect = (data) => {
    if (selectedItem?.length > 0) {
      const itemsData = selectedItem?.filter(
        (item) => item?.spirit_category_id === data?.spirit_category_id
      )
      if (itemsData?.length > 0) {
        setSelectedItem(
          selectedItem?.filter((item) => item?.spirit_category_id !== data?.spirit_category_id)
        )
      } else {
        setSelectedItem([...selectedItem, data])
      }
    } else {
      setSelectedItem([...selectedItem, data])
    }
  }

  // Function to add an item to the "spirit_list"
  const addSpiritItem = (categoryId, newItem, parentItem) => {
    setSelectedItem((prevData) => {
      // Check if the category with the given ID exists
      const categoryIndex = prevData.findIndex(
        (category) => category.spirit_category_id === categoryId
      )

      if (categoryIndex !== -1) {
        // Category exists, clone the array and add the new item to the "spirit_list"
        const updatedData = [...prevData]
        updatedData[categoryIndex].spirit_list.push(newItem)
        return updatedData
      } else {
        // Category doesn't exist, create a new category and add the new item
        const newCategory = {
          spirit_category: parentItem?.spirit_category,
          spirit_category_id: categoryId,
          spirit_list: [newItem]
        }
        return [...prevData, newCategory]
      }
    })
  }

  // Function to delete an item from the "spirit_list" based on the item's ID
  const deleteItemFromSpiritList = (categoryId, itemId) => {
    setSelectedItem((prevData) => {
      return prevData
        .map((category) => {
          if (category.spirit_category_id === categoryId) {
            // Clone the category and filter the "spirit_list" to remove the item with the specified ID
            const updatedCategory = { ...category }
            updatedCategory.spirit_list = updatedCategory.spirit_list.filter(
              (item) => item.id !== itemId
            )

            // Check if the category becomes empty, and remove it from the array
            if (updatedCategory.spirit_list.length === 0) {
              return null // Returning null will remove the category
            }

            return updatedCategory
          }
          return category
        })
        .filter(Boolean) // Remove any null entries from the array
    })
  }

  const handleSelectItem = (categoryID, id, items, item) => {
    let isItemCategorySelected = selectedItem?.filter(
      (item) => item?.spirit_category_id === categoryID
    )
    if (isItemCategorySelected) {
      const isItemAvailable = isItemCategorySelected?.[0]?.spirit_list?.filter(
        (item) => item?.id === id
      )
      if (isItemAvailable?.length > 0) {
        deleteItemFromSpiritList(categoryID, id)
      } else {
        addSpiritItem(categoryID, items, item)
      }
    } else {
      addSpiritItem(categoryID, items, item)
    }
  }

  const handleImport = () => {
    let ids = []
    if (selectedItem?.length > 0) {
      for (let i = 0; i < selectedItem?.length; i++) {
        for (let j = 0; j < selectedItem[i]?.spirit_list?.length; j++) {
          ids.push(selectedItem[i]?.spirit_list[j].id)
        }
      }
      const payload = {
        ids
      }
      dispatch(importSpiritItemAction(payload))
      onClose()
      dispatch(getDMVSpiritSubMenuistAction())
    } else {
      toast.error("Please select atleast one item!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  const handleCancel = () => {
    onClose()
  }

  const checkSelectedItems = (spiritID, itemID) => {
    const isItemCategorySelected = selectedItem?.filter(
      (item) => item?.spirit_category_id === spiritID
    )
    if (isItemCategorySelected) {
      const isItemAvailable = isItemCategorySelected?.[0]?.spirit_list?.filter(
        (item) => item?.id === itemID
      )
      if (isItemAvailable?.length > 0) {
        return true
      } else {
        return false
      }
    }
  }

  const checkSelectAll = (data) => {
    const isItemSelected = selectedItem?.filter(
      (item) => item?.spirit_category_id === data?.spirit_category_id
    )
    if (data?.spirit_list?.length === isItemSelected?.[0]?.spirit_list?.length) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    dispatch(getImportSpiritAction(debouncedSearchTerm))
  }, [debouncedSearchTerm])

  return (
    <StyledDialog open={true} maxWidth="">
      <DialogContent id="editQuestionDialog" className={styles.dialogBox}>
        <React.Fragment>
          <Box className="d-flex justify-content-center position-relative">
            <Typography variant="h2">Select Menu Item</Typography>
            <Box className={styles.closeButton}>
              <span role="button" onClick={onClose}>
                <IconButton sx={(theme) => ({ color: theme.palette.text.primary })}>
                  <CloseIcon sx={{ height: "14px", width: "14px" }} />
                </IconButton>
              </span>
            </Box>
          </Box>
          <Box height={40} />
          <Box sx={{ padding: "0 11px" }}>
            <Row className="mt-4">
              <Col lg="12">
                <SearchTextField
                  id="search"
                  name="search"
                  value={searchValue}
                  onChange={handleSearchField}
                  fullWidth
                  type="search"
                  placeholder="Search Menu Item"
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          backgroundColor: theme.palette.secondary.main
                        }}>
                        <SearchIcon htmlColor="#C4C4C4" />
                      </InputAdornment>
                    )
                  }}
                  style={{
                    border: "1px solid #C4C4C4",
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: 6
                  }}
                />
              </Col>
            </Row>
            <Grid item mt={4}>
              <Grid container xs={12}>
                <CMSSpiritsSubMenuNavBar activeTab={menuCategory?.name}></CMSSpiritsSubMenuNavBar>
              </Grid>
            </Grid>
            <Row>
              {spiritData?.length === 0 && (
                <Card className="mb-2">
                  <Grid item>
                    <Typography className="mt-4" style={{ opacity: 0.6, textAlign: "center" }}>
                      No Items Found
                    </Typography>
                  </Grid>
                </Card>
              )}
              {spiritData?.map((item, index) =>
                item?.spirit_list?.length > 0 ? (
                  <Card className="mb-2" key={index}>
                    <Col lg={12}>
                      <Grid container>
                        <Grid item lg={10}>
                          <div className="d-flex justify-content-between align-item-center">
                            <Typography variant="h2">{item?.spirit_category}</Typography>
                          </div>
                        </Grid>
                        <Grid item lg={2}>
                          <Grid container justifyContent="flex-end" alignItems="center">
                            <Grid item>
                              <div className="d-flex align-items-center">
                                {checkSelectAll(item) ? (
                                  <IconButton
                                    sx={(theme) => ({
                                      color: theme.palette.secondary.main
                                    })}>
                                    <CheckCircleIcon onClick={() => handleSelect(item)} />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    sx={(theme) => ({
                                      color: theme.palette.secondary.main
                                    })}>
                                    <RadioButtonUncheckedIcon onClick={() => handleSelect(item)} />
                                  </IconButton>
                                )}
                              </div>
                            </Grid>
                            <Grid item>
                              <Typography>Select All</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        justifyContent={item?.spirit_list?.length === 0 ? "center" : ""}>
                        {item?.spirit_list?.length > 0 ? (
                          item?.spirit_list?.map((items, index) => {
                            return (
                              <Grid item key={index}>
                                <div
                                  className={classes.itemContainer}
                                  style={{
                                    gridTemplateColumns: `0.1fr 1fr 9fr`
                                  }}>
                                  <div className="d-flex align-items-center">
                                    {checkSelectedItems(item?.spirit_category_id, items?.id) ? (
                                      <IconButton
                                        sx={(theme) => ({
                                          color: theme.palette.secondary.main
                                        })}>
                                        <CheckCircleIcon
                                          onClick={() =>
                                            handleSelectItem(item?.spirit_category_id, items?.id)
                                          }
                                        />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        sx={(theme) => ({
                                          color: theme.palette.secondary.main
                                        })}>
                                        <RadioButtonUncheckedIcon
                                          onClick={() =>
                                            handleSelectItem(
                                              item?.spirit_category_id,
                                              items?.id,
                                              items,
                                              item
                                            )
                                          }
                                        />
                                      </IconButton>
                                    )}
                                  </div>
                                  <div className={classes.imageContainer}>
                                    <ImageDisplay data={items?.image_url}></ImageDisplay>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      maxWidth: 375,
                                      overflowWrap: "break-word"
                                    }}>
                                    <div>
                                      <Typography variant="h4">
                                        {items?.name ?? "-"}
                                        {items?.is_newly_added ? (
                                          <span className={classes.new_tag}>New</span>
                                        ) : (
                                          ""
                                        )}
                                      </Typography>
                                    </div>

                                    <div>
                                      <div className="mt-2">
                                        <Typography variant="tag1">{"Description:"}</Typography>
                                        <br></br>
                                        <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                                          {items?.description ?? "-"}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            )
                          })
                        ) : (
                          <Grid item>
                            <Typography
                              className="mt-4"
                              style={{ opacity: 0.6, textAlign: "center" }}>
                              No Items Found
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Col>
                  </Card>
                ) : (
                  <Card className="mb-2" key={index}>
                    <Grid container justifyContent="center">
                      <Typography className="mt-4" style={{ opacity: 0.6, textAlign: "center" }}>
                        No Items Found
                      </Typography>
                    </Grid>
                  </Card>
                )
              )}
            </Row>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <AppButton className="mt-3 px-5" variant="outlined" onClick={handleCancel}>
                  Cancel
                </AppButton>
              </Grid>
              <Grid item>
                <AppButton className="mt-3 px-5" variant="outlined" onClick={handleImport}>
                  Import
                </AppButton>
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
      </DialogContent>
    </StyledDialog>
  )
}

export default React.memo(ImportSpiritsModal)
