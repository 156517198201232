import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { formatDate } from "helpers/functions"
import { validationSchemaDMVWineOfMonth } from "helpers/validationSchema"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { DMVActions } from "redux/DMV/slice/user"
import { Prompt, useHistory } from "react-router-dom"
import AppButton from "components/StyledComponents/AppButton"
import CombinedForm from "./components/CombinedForm"
import { Formik } from "formik"
import { createTastingNotesAction } from "redux/DMV/actions"

const DMVTastingNotesConfig = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [initialValues, setInitialValues] = useState({})
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { wineImage, isSubmitted, flavourImages, videoThumbnail } = useSelector(
    (store) => store?.dmv?.tastingNotes ?? {}
  )

  const handleSubmit = (values) => {
    setIsFormSubmitting(true)
    var date = new Date(values?.week)
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const payload = {
      name: values.wine,
      start_date: formatDate(firstDay),
      end_date: formatDate(lastDay),
      grape_varietal: values.grapeVarietals,
      origin: values.origin,
      personality: values.personality,
      video_url: values.videoUrl || null,
      wine_type: values?.wineType,
      wine_color: values?.week_answer,
      thumbnail: values.videoUrl ? videoThumbnail : null,
      ...(wineImage ? { image: wineImage } : {}),
      ...(values?.menuPairing[0].name === ""
        ? {}
        : {
            menu_pairing: values?.menuPairing?.map((el, index) => ({
              name: el.name,
              image: flavourImages[index]
            }))
          }),
      tasting_notes: values?.tastingNotesSet?.map((el) => ({
        flavor: el.flavour
      })),
      characteristics: values.wineCharateristicsSet?.map(({ characteristic, level }) => ({
        characteristic,
        level
      }))
    }
    console.log(payload)
    dispatch(createTastingNotesAction(payload))
  }

  const resetFormikData = () => {
    setInitialValues({
      week: new Date(),
      wine: "",
      origin: "",
      week_answer: "lemon",
      personality: "",
      grapeVarietals: "",
      wineImage: null,
      videoThumbnail: null,
      videoUrl: "",
      wineType: "white_wine",
      wine_color: "",
      wineCharateristicsSet: [
        {
          characteristic: "",
          level: ""
        }
      ],
      tastingNotesSet: [
        {
          flavour: ""
        }
      ],
      menuPairing: [
        {
          name: "",
          image: null
        }
      ]
    })
  }

  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ""
  }

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser)
    return () => {
      window.removeEventListener("beforeunload", alertUser)
    }
  }, [])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Tasting Notes Config"
    dispatch(DMVActions.initTastingNotes())
    resetFormikData()
  }, [])

  useEffect(() => {
    if (isSubmitted) {
      dispatch(DMVActions.setIsTastingNotesSubmitted(false))
      history.push("/super-admin/dashboard")
    }
  }, [isSubmitted])

  return (
    <Container fluid className="p-0 mb-5">
      <Row>
        <Col lg="12">
          <Header title="Tasting Notes Config" breadCrumbs={breadCrumbs} back={true}>
            <AppButton
              onClick={() => history.push("/super-admin/dashboard/tasting-notes-config/list")}>
              View Tasting Notes
            </AppButton>
          </Header>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchemaDMVWineOfMonth}
            onSubmit={handleSubmit}>
            {(formik) => (
              <>
                <Prompt
                  when={formik.dirty && !isFormSubmitting}
                  message={
                    "Changes you made may not be saved. Are you sure you want to leave this page ?"
                  }
                />
                <CombinedForm formik={formik} formReset={resetFormikData} />
              </>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  )
}

export default DMVTastingNotesConfig