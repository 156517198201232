import { Box, Grid, IconButton, Typography } from "@mui/material"
import Card from "components/Card/Card"
import AppButton from "components/StyledComponents/AppButton"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import Slider from "@mui/material/Slider"
import SelectMenuItems from "./components/QuestionsModal"
import { learningDevelopmentActions } from "redux/L&D/slice/user"
import { useDispatch, useSelector } from "react-redux"
import ImageDisplay from "components/ImageDisplayer"
import classes from "pages/private/marketing/menu/menu.module.scss"
import { useTheme } from "@mui/system"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { generateQuestionAction } from "redux/L&D/actions/user"
import QuestionsBanks from "pages/private/learning_development/QuestionBank/components/QuestionBanks"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiSlider-thumb": {
      color: "var(--status-negative, #CC4B37)"
    },
    "& .MuiSlider-mark": {
      display: "none"
    }
  }
}))

const GenerateQuestion = () => {
  const theme = useTheme()
  const sliderClass = useStyles()
  function valuetext(value) {
    return `${value}`
  }
  const dispatch = useDispatch()
  const { selectedMenuItems, generatedQuestions } = useSelector(
    (state) => state?.learningDevelopment || []
  )

  const [noOfQuestion, setNoOfQuestions] = useState(1)
  const [questionModal, setQuestionModal] = useState(false)

  const handleSelectMenuItems = () => {
    setQuestionModal((prevQuestionModal) => !prevQuestionModal)
    dispatch(learningDevelopmentActions.setGenerate_Question_MenuLink("dinner"))
  }

  const handleRemoveMenuItem = (ID) => {
    const updatedList = selectedMenuItems.filter((item) => item?.id !== ID)
    dispatch(learningDevelopmentActions.setSelectedMenuItems(updatedList))
  }

  const handleQuestionCount = (event) => {
    setNoOfQuestions(event.target.value)
  }

  //generate question on the basis of menu items
  const handleGenerateQuestion = () => {
    let menu_ids = []
    for (var k = 0; k < selectedMenuItems.length; k++) {
      menu_ids.push({ id: selectedMenuItems?.[k]?.id, type: selectedMenuItems?.[k]?.category })
    }
    const payload = {
      menu_ids,
      question_number: noOfQuestion,
      platform: "SCARPETTA"
    }
    dispatch(generateQuestionAction(payload))
  }

  useEffect(() => {
    dispatch(learningDevelopmentActions.setSelectedMenuItems([]))
    dispatch(learningDevelopmentActions.setGeneratedQuestions([]))
  }, [])

  return (
    <>
      {questionModal && <SelectMenuItems onClose={handleSelectMenuItems} />}
      <Card>
        <Box>
          <Row>
            <Typography variant="caption2">Step 1</Typography>
          </Row>
          <Row
            className={selectedMenuItems?.length > 0 ? "mt-4" : "mt-0"}
            style={{ marginLeft: 5 }}>
            <Col lg="12">
              <Grid container spacing={2}>
                {selectedMenuItems?.map((item) => (
                  <Grid
                    item
                    xs={item?.image_url || item?.image ? 2 : 3}
                    className={classes.selectedTriviaItems}
                    style={{
                      border: `1px solid ${theme.palette.secondary.main}`,
                      borderBottom: `1px solid ${theme.palette.secondary.main}`,
                      borderRadius: "6px",
                      padding: "8px",
                      marginRight: 15,
                      marginTop: 10
                    }}
                    key={item?.id}>
                    <div
                      className={classes.imageContainer}
                      style={
                        item?.image_url || item?.image
                          ? { height: 50, width: 50 }
                          : { height: 100, width: 113 }
                      }>
                      <ImageDisplay
                        data={item?.image_url ? item?.image_url : item?.image}></ImageDisplay>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        maxWidth: 300,
                        overflowWrap: "break-word"
                      }}>
                      <div className="d-flex justify-content-start align-items-center">
                        <Typography variant="h4">{item?.name ?? "-"}</Typography>
                      </div>
                    </div>
                    <div role="button" className="d-flex justify-content-end align-items-start">
                      <IconButton
                        sx={(theme) => ({ color: theme.palette.text.primary })}
                        onClick={() => handleRemoveMenuItem(item?.id)}>
                        <HighlightOffIcon sx={{ height: "20px", width: "20px" }} />
                      </IconButton>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <AppButton onClick={handleSelectMenuItems} styles={{ padding: "10px 30px" }}>
                {selectedMenuItems?.length > 0 ? `Add Menu Items` : `Select Menu Items`}
              </AppButton>
            </Col>
          </Row>
          <Row className="mt-4">
            <Typography variant="caption2">Step 2</Typography>
          </Row>
          <Row className="mt-2 mb-1">
            <Typography variant="body1Regular" color={theme.palette.text.secondary}>
              Number of Questions per item
            </Typography>
          </Row>
          <Row>
            <Col lg="3">
              <Slider
                className={sliderClass.root}
                aria-label="Always visible"
                defaultValue={1}
                getAriaValueText={valuetext}
                step={1}
                marks={marks}
                valueLabelDisplay="off"
                color="secondary"
                min={1}
                max={5}
                value={noOfQuestion}
                onChange={handleQuestionCount}
              />
            </Col>
          </Row>
        </Box>
      </Card>
      <Row className="mt-4">
        <Col>
          <AppButton
            disabled={selectedMenuItems?.length > 0 ? false : true}
            onClick={handleGenerateQuestion}>
            Generate Questions
          </AppButton>
        </Col>
      </Row>
      {generatedQuestions?.length > 0 && (
        <Row className="mt-4">
          <Col lg="12">
            <QuestionsBanks
              title="Question Bank"
              questions={generatedQuestions}
              showImage={false}
              showPagination={false}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

const marks = [
  {
    value: 1,
    label: "1"
  },
  {
    value: 2,
    label: "2"
  },
  {
    value: 3,
    label: "3"
  },
  {
    value: 4,
    label: "4"
  },
  {
    value: 5,
    label: "5"
  }
]

export default GenerateQuestion
