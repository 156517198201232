import * as React from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { makeStyles } from "@material-ui/core"
import OnboardingBg from "assets/images/AmericanCutOnBoarding.JPG"
import backgroundLogin from "assets/images/backgrounds/loginText.png"

const useStyles = makeStyles(() => ({
  backgroundImg: {
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    maxWidth: "fit-content",
    objectFit: "cover",
    objectPosition: "0px 0px"
  },
  logo: {
    position: "absolute",
    top: 35,
    left: 35
  },
  leftBannerContainer: {
    position: "relative",
    backgroundImage: `url(${OnboardingBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center"
  },
  bottomTextBanner: {
    position: "absolute",
    bottom: 20,
    background: "rgba(0, 0, 0, 0.4)",
    width: "fit-content",
    marginRight: 30,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    padding: "10px 100px",
    borderRadius: 6,
    backdropFilter: "blur(22px)",
    "@media (max-width: 920px)": {
      padding: "10px 20px"
    }
  },
  rightBannerContainer: {
    backgroundImage: `url(${backgroundLogin})`,
    mixBlendMode: "screen",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  TextColor: { color: "#fff" }
}))

export default function PublicLayout({ children }) {
  const classes = useStyles()
  const isAuth = location.pathname.includes("auth")
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {isAuth ? (
          <>
            <Grid item xs={4} md={5} className={classes.leftBannerContainer}></Grid>
            <Grid item xs={8} md={7} className={classes.rightBannerContainer}>
              {children}
            </Grid>
          </>
        ) : (
          <Grid item xs={12} className={`${classes.rightBannerContainer} ${classes.TextColor}`}>
            {children}
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
