import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import CreateIcon from "@mui/icons-material/Create"
import { useDispatch, useSelector } from "react-redux"
import classes from "./menu.module.scss"
import MenuListContainer from "../../../../components/menuListContainer/index"
import {
  getBarMenuPeriodAction,
  getMenuPeriodAction,
  getWineMenuPeriodAction
} from "redux/marketing/actions"
import { Grid, Typography } from "@mui/material"
import { useHistory } from "react-router-dom"
import { marketingActions } from "redux/marketing/slice/user"
import MenuNavBar from "components/menuNavBar"
import SubMenuBar from "components/subMenuBar"
import WineListItems from "components/WineListItems"
import Card from "components/Card/Card"
import BarListItems from "components/BarListItem"
import { getNewSpiritCountAction, getSpiritCMSAction } from "redux/marketing/actions/user"
import SpiritsMenuListContainer from "components/spiritsMenuListConatiner"
import SpiritsSubMenuBar from "components/SpiritsSubMenuBar"
import DinnerSubMenuBar from "components/subMenuBarDinner"
import DMV_SpiritSummary from "components/DMVSpiritSummary"
import ImportSpiritsModal from "components/ImportSpirits"

const menuText = {
  BARMENU: "bar menu",
  WINEBAR: "wine by glass",
  DINNER: "dinner",
  LUNCH: "lunch",
  SPIRITS: "spirits"
}

function Menu() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()
  const { linkCategory } = useSelector((state) => state?.marketing?.menu || {})
  const { menuPeriod, barMenuPeriod, wineMenuPeriod, spiritData } = useSelector(
    (state) => state?.marketing?.menu || []
  )
  const { menuCategory, SpiritSubMenu } = useSelector((state) => state.dmv)
  const [importSpiritModal, setImportSpiritModal] = useState(false)

  const handleRouting = () => {
    const itemsLink = {
      item: linkCategory?.item,
      category: linkCategory?.category,
      list: "Detailed View"
    }
    dispatch(marketingActions.setMenuLink(itemsLink))
    history.push("/marketing/dashboard/explore-menu/edit")  
  }

  const handleArchivedRouting = () => {
    dispatch(marketingActions.setArchivedMenu(true))
    history.push("/marketing/dashboard/explore-menu/archived-items") 
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Menu"
  }, [])

  useEffect(() => {
    if (linkCategory?.item === menuText.BARMENU) {
      dispatch(getBarMenuPeriodAction())
    } else if (linkCategory?.item === menuText.WINEBAR) {
      dispatch(getWineMenuPeriodAction())
    } else if (linkCategory?.item === menuText.SPIRITS) {
      dispatch(getSpiritCMSAction())
      dispatch(getNewSpiritCountAction())
    } else {
      dispatch(getMenuPeriodAction())
    }
  }, [linkCategory?.item, linkCategory?.category])

  function handleImportSpirit() {
    setImportSpiritModal(true)
  }

  return (
    <Fragment>
      {importSpiritModal && (
        <ImportSpiritsModal open={importSpiritModal} onClose={() => setImportSpiritModal(false)} />
      )}
      <Container fluid className="p-0">
        <Row>
          <Col lg="12">
            <Header title={`Menu`} breadCrumbs={breadCrumbs}>
              <Grid container spacing={2}>
                <Grid item>
                  <AppButton variant="contained" onClick={handleArchivedRouting}>
                    Archived Items
                  </AppButton>
                </Grid>
                {linkCategory?.item === menuText.SPIRITS && (
                  <Grid item sx={{ position: "relative" }}>
                    <AppButton variant="contained" onClick={handleImportSpirit}>
                      Import Spirit
                    </AppButton>
                    {linkCategory?.item === menuText.SPIRITS && SpiritSubMenu?.[0]?.dmv_total_count && (
                      <Grid item sx={{ top: "6px" }} className={classes.ellipse_gold}>
                        {SpiritSubMenu?.[0]?.dmv_total_count}
                      </Grid>
                    )}
                  </Grid>
                )}
                <Grid item>
                  <Grid sx={{ position: "relative" }}>
                    <AppButton onClick={handleRouting}>
                      <CreateIcon
                        sx={(theme) => ({ color: theme.palette.secondary.main })}
                        className="me-2"></CreateIcon>
                      Edit Menu
                    </AppButton>
                  </Grid>
                </Grid>
              </Grid>
            </Header>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="12">
            <MenuNavBar activeTab={linkCategory?.item}></MenuNavBar>
          </Col>
        </Row>
        {linkCategory?.item === menuText.LUNCH && (
          <Row className="mt-4">
            <Col lg="12">
              <SubMenuBar activeTab={linkCategory?.category}></SubMenuBar>
            </Col>
          </Row>
        )}
        {linkCategory?.item === menuText.SPIRITS && (
          <Row className="mt-4">
            <Col lg={12}>
              <SpiritsSubMenuBar isCMS={true} activeTab={menuCategory?.name} />
            </Col>
          </Row>
        )}
        {linkCategory?.item === menuText.DINNER && (
          <Row className="mt-4">
            <Col lg="12">
              <DinnerSubMenuBar activeTab={linkCategory?.category}></DinnerSubMenuBar>
            </Col>
          </Row>
        )}
      </Container>
      {(linkCategory?.item === menuText.DINNER || linkCategory?.item === menuText.LUNCH) && (
        <Container fluid className="p-0 mt-4">
          <div className={classes.menuContainer}>
            {menuPeriod?.length > 0 ? (
              menuPeriod?.map((menuItem, idx) => {
                return <MenuListContainer data={menuItem} key={idx}></MenuListContainer>
              })
            ) : (
              <Typography>No Data Found</Typography>
            )}
          </div>
        </Container>
      )}
      {linkCategory?.item === menuText.BARMENU && (
        <Container fluid className="p-0 mt-4">
          <div className={classes.menuContainer}>
            {barMenuPeriod?.length > 0 ? (
              barMenuPeriod?.map((menuItem, idx) => {
                return <BarListItems data={menuItem} key={idx}></BarListItems>
              })
            ) : (
              <Typography>No Data Found</Typography>
            )}
          </div>
        </Container>
      )}
      {linkCategory?.item === menuText.WINEBAR && (
        <Container fluid className="p-0 mt-4">
          <Card>
            <div className={classes.menuContainer}>
              {wineMenuPeriod?.length > 0 ? (
                wineMenuPeriod?.map((menuItem, idx) => {
                  return <WineListItems foodList={menuItem} key={idx}></WineListItems>
                })
              ) : (
                <Typography>No Data Found</Typography>
              )}
            </div>
          </Card>
        </Container>
      )}
      {linkCategory?.item === menuText.SPIRITS && (
        <>
          <Grid item sx={{ maxWidth: "50%" }}>
            {SpiritSubMenu?.map(
              (foodList, i) =>
                foodList?.id === menuCategory?.name &&
                (foodList?.description ||
                  foodList?.image_url ||
                  foodList?.unique_facts?.length > 0) && (
                  <DMV_SpiritSummary key={i} isEdit={false} foodList={foodList} isCMS={true} />
                )
            )}
          </Grid>
          <Container fluid className="p-0 mt-4">
            <div className={classes.spiritContainer}>
              {spiritData?.length > 0 ? (
                spiritData?.map((menuItem, idx) => {
                  return (
                    <SpiritsMenuListContainer data={menuItem} key={idx}></SpiritsMenuListContainer>
                  )
                })
              ) : (
                <Typography>No Data Found</Typography>
              )}
            </div>
          </Container>
        </>
      )}
    </Fragment>
  )
}

export default Menu
