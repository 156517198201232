import { Box, Grid, styled, Typography } from "@mui/material"
import DetailField from "components/DetailField"
import { getFormattedDate, splitAndMergeString } from "helpers/functions"
import React from "react"
import { toTitleCase } from "../../../../../helpers/functions"

const ImageContainer = styled(Box)(() => ({
  "& img": {
    maxHeight: 200,
    maxWidth: 500
  }
}))

function getString(string) {
  if (string) {
    var newStr = string?.replace(/_/g, " ")
    return toTitleCase(newStr)
  }
}
const BasicDetails = ({ data }) => {
  return (
    <Grid container spacing={4}>
      <Grid item md={3} sm={4}>
        <DetailField
          label="Start Date"
          value={data?.start_date ? getFormattedDate(data.start_date) : "-"}
        />
      </Grid>
      <Grid item md={3} sm={4}>
        <DetailField
          label="End Date"
          value={data?.end_date ? getFormattedDate(data.end_date) : "-"}
        />
      </Grid>
      <Grid item md={3} sm={4}>
        <DetailField label={"Wine"} value={data?.name ?? "-"} />
      </Grid>
      <Grid item md={3} sm={4}>
        <DetailField label="Origin" value={data?.origin ?? "-"} />
      </Grid>
      <Grid item md={3} sm={4}>
        <DetailField label="Personality" value={data?.personality ?? "-"} />
      </Grid>
      <Grid item md={3} sm={4}>
        <DetailField label={"Grape Varietals"} value={data?.grape_varietal ?? "-"} />
      </Grid>
      <Grid item md={3} sm={4}>
        <DetailField label={"Wine Type"} value={getString(data?.wine_type ?? "-")} />
      </Grid>
      {data?.wine_type === "transparent" ? null : (
        <Grid item md={3} sm={4}>
          <DetailField
            label={"Wine Color"}
            value={data?.wine_color ? splitAndMergeString(data?.wine_color) : "-"}
          />
        </Grid>
      )}
      <Grid item lg={12}>
        <Box className="w-100 mb-2">
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            WINE IMAGE
          </Typography>
        </Box>
        {data.image ? (
          <ImageContainer>
            <img src={data.image} alt="" />
          </ImageContainer>
        ) : null}
      </Grid>
    </Grid>
  )
}

export default BasicDetails
