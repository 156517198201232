import React, { useState } from "react"
import Card from "components/Card/Card"
import { Box, Typography, Dialog, styled, IconButton } from "@mui/material"
import CustomDivider from "components/StyledComponents/Divider"
import { useTheme } from "@mui/system"
import ImportTab from "./ImportTab"
import CloseIcon from "@mui/icons-material/Close"
import { Formik } from "formik"
import { validationSchemaWineOfTheMonth } from "helpers/validationSchema"
import { useDispatch, useSelector } from "react-redux"
import { formatDate } from "helpers/functions"
import { uploadWineOfTheMonthAction } from "redux/marketing/actions"
// import { uploadWineOfTheMonthAction } from "redux/marketing/actions"
import { marketingActions } from "redux/marketing/slice/user"

const initialValues = {
  month: "",
  nameOfTheWine: "",
  videoThumbnail: "",
  videoURL: "",
  wineImageVideo: "",
  wineRegion: "",
  grapeVarietals: "",
  description: "",
  tastingNotes: [],
  menuPairing: [
    {
      image: null,
      title: ""
    }
  ]
}

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: "1100px"
  }
}))

function UploadWineOfTheMonthModal({ open, onClose, isEdit = false }) {
  const theme = useTheme()
  const [activeTab, setActiveTab] = useState("Import")
  const [initialFormValue] = useState(initialValues)
  const tabs = ["Import"]
  const dispatch = useDispatch()
  const { videoThumbnailImage, bottleimage, flavourImages } = useSelector(
    (store) => store?.marketing?.wineOfTheMonth || {}
  )

  const styles = {
    card: {
      margin: 0
    },
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center"
    },
    divider: {
      backgroundColor: theme.palette.secondary.main,
      height: 3,
      opacity: 1,
      position: "relative",
      top: "12px",
      left: "-23%",
      width: "150%"
    },
    mainDivider: {
      backgroundColor: theme.palette.background.main,
      opacity: 1,
      marginTop: "10px"
    },
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    }
  }

  function handleSubmit(values) {
    const payload = {
      month: formatDate(values?.month.toUTCString()),
      name: values?.nameOfTheWine,
      thumbnail: videoThumbnailImage,
      video_url: values?.videoURL,
      image: bottleimage,
      wine_region: values?.wineRegion,
      gape_variatals: values?.grapeVarietals,
      description: values?.description,
      menu_tasting_notes: values.tastingNotes,
      menu_pairing: values?.menuPairing?.map((el, index) => {
        return { name: el?.title, image: flavourImages[index] }
      })
    }

    console.log("values", values)

    dispatch(uploadWineOfTheMonthAction(payload))
    dispatch(marketingActions.setWineMonthPDFThumbnail(null))
    dispatch(marketingActions.setWineMonthVideoThumbnail(null))
    onClose()
  }

  return (
    <StyledDialog open={open} fullWidth>
      <Card style={styles.card}>
        <IconButton onClick={onClose} sx={styles.closeBtn}>
          <CloseIcon sx={{ height: "14px", width: "14px" }} />
        </IconButton>
        {/* Headings */}
        <Box sx={styles.headingContainer}>
          {tabs.map((tab, index) => (
            <Box sx={styles.heading} key={index}>
              <Typography
                onClick={() => setActiveTab(tab)}
                variant="body1Regular"
                sx={[
                  tab === activeTab ? { color: theme.palette.secondary.main } : {},
                  { cursor: "pointer" }
                ]}>
                {tab}
              </Typography>
              {tab === activeTab && <CustomDivider sx={styles.divider} />}
            </Box>
          ))}
        </Box>
        <CustomDivider sx={styles.mainDivider} />

        <Box>
          {/* Upload Tab */}
          <Formik
            initialValues={initialFormValue}
            enableReinitialize={true}
            validationSchema={validationSchemaWineOfTheMonth}
            onSubmit={handleSubmit}>
            {(formik) => (
              <>
                {/* Import Tab */}
                {activeTab === "Import" && <ImportTab isEdit={isEdit} formik={formik} />}
              </>
            )}
          </Formik>

          {/* {activeTab === "Upload" && <UploadTab formik={formik} />} */}
          {/* Additional Tab */}
          {/* {activeTab === "Additional" && <AdditionalTab />} */}
        </Box>
      </Card>
    </StyledDialog>
  )
}

export default UploadWineOfTheMonthModal
