import React, { useState } from "react";
import { Typography, Grid, IconButton } from "@mui/material";
import { Formik } from "formik";
import styles from "./DMVlogin.module.scss";
import AppTextField from "components/StyledComponents/AppTextField";

import AppButton from "components/StyledComponents/AppButton";
import Visibility from "assets/icons/VisibilityIcon.svg";
import VisibilityOff from "assets/icons/VisibilityOffIcon.svg";
import { validationSchemaLogin } from "helpers/validationSchema";
import CheckBoxBlueIcon from "components/CheckBoxBlue";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { loginAction } from "redux/L&D/actions/index";
import { Redirect } from "react-router";

const DMVLogin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isVisible, setVisible] = useState(false);
  const isLoggState = useSelector((res) => res?.app?.isLoggedIn);

  const userLogin = (e) => {
    dispatch(
      loginAction({
        email: e.email,
        password: e.password
      })
    );
  };

  if (isLoggState) {
    return <Redirect to={location?.state?.from} />;
  }

  return (
    <section className={styles.mainContainer}>
      <div className={styles.innerContainer}>
        <Typography className={styles.heading} variant="h2">
          Login to your account
        </Typography>
        <Formik
          initialValues={{
            email: "",
            password: "",
            rememberMe: false
          }}
          validationSchema={validationSchemaLogin}
          onSubmit={userLogin}>
          {(formik) => (
            <React.Fragment>
              <Grid item xs={12}>
                <AppTextField
                  id="email"
                  fullWidth
                  label="Email"
                  isRequired={true}
                  placeholder="Enter Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>

              <Grid item xs={12}>
                <AppTextField
                  id="password"
                  fullWidth
                  type="password"
                  label="Password"
                  style={{ marginTop: 35 }}
                  isRequired={true}
                  placeholder="Enter Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    type: isVisible ? "text" : "password",
                    disableUnderline: true,
                    endAdornment: (
                      <IconButton
                        style={{ margin: 0 }}
                        aria-label="toggle password visibility"
                        onClick={() => setVisible(!isVisible)}
                        edge="end"
                        className={styles.imageSrc}>
                        {isVisible ? (
                          <img src={Visibility} alt="alt" />
                        ) : (
                          <img src={VisibilityOff} alt="alt" />
                        )}
                      </IconButton>
                    )
                  }}
                />
              </Grid>
              <Grid
                container
                className={styles.extrabuttons}
                justifyContent="space-between"
                alignItems="center">
                <button
                  className={styles.rememberMe}
                  onClick={() =>
                    formik.setFieldValue(
                      "rememberMe",
                      !formik.values.rememberMe
                    )
                  }>
                  <CheckBoxBlueIcon checked={formik.values.rememberMe} />
                  <Typography variant="body2Regular">Remember Me</Typography>
                </button>
                <button className={styles.forgotPassword}>
                  <Typography variant="body2Regular">
                    Forgot your password?
                  </Typography>
                </button>
              </Grid>

              <Grid item xs={12}>
                <AppButton
                  styles={{
                    background:
                      "linear-gradient(97.14deg, #4FACE9 -118.91%, #274C9A 64.39%)",
                    border: "none"
                  }}
                  className={styles.btnSubmit}
                  variant="contained"
                  onClick={formik.handleSubmit}>
                  Log In
                </AppButton>
              </Grid>
            </React.Fragment>
          )}
        </Formik>
      </div>
      {/* <footer className={styles.footer}>
        <img src={DMVLogo} alt="" />
        <Typography variant="body1Regular">
          Restaurant Conversion Platform Powered By DM Ventures
        </Typography>
      </footer> */}
    </section>
  );
};

export default DMVLogin;
