import Header from "components/Header/Header"
import { Formik } from "formik"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { dateToUTC, formatDate } from "helpers/functions"
import { validationSchemaDMVWineOfMonth } from "helpers/validationSchema"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Prompt, useHistory } from "react-router-dom"
import { editTastingNotesAction, getTastingNotesDataAction } from "redux/DMV/actions"
import { DMVActions } from "redux/DMV/slice/user"
import CombinedForm from "./components/CombinedForm"

const EditTastingNotes = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [initialValues, setInitialValues] = useState({})
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const dispatch = useDispatch()
  const tastingNotesData = useSelector((store) => store?.dmv?.tastingNotes?.tastingNotesData || {})
  const history = useHistory()
  const { wineImage, isSubmitted, videoThumbnail, flavourImages } = useSelector(
    (store) => store?.dmv?.tastingNotes ?? {}
  )

  // reset Data
  const resetFormikData = () => {
    setInitialValues({
      week: new Date(),
      wine: "",
      origin: "",
      personality: "",
      grapeVarietals: "",
      week_answer: "",
      pairing: "",
      wineImage: null,
      videoUrl: "",
      videoThumbnail: null,
      wineCharateristicsSet: [
        {
          characteristic: "",
          level: ""
        }
      ],
      menuPairing: [
        {
          name: "",
          image: null
        }
      ],
      tastingNotesSet: [
        {
          flavour: ""
        }
      ]
    })
  }

  const initForm = () => {
    setInitialValues({
      week: dateToUTC(tastingNotesData?.wine?.start_date).toDate(),
      wine: tastingNotesData?.wine?.name ?? "",
      origin: tastingNotesData?.wine?.origin ?? "",
      personality: tastingNotesData?.wine?.personality ?? "",
      wineType: tastingNotesData?.wine?.wine_type ?? "",
      grapeVarietals: tastingNotesData?.wine?.grape_varietal ?? "",
      week_answer: tastingNotesData?.wine?.wine_color,
      wineImage: { id: "", url: tastingNotesData?.wine?.image ?? null },
      videoThumbnail: tastingNotesData?.wine?.thumbnail_url
        ? { id: "", url: tastingNotesData?.wine?.thumbnail_url }
        : null,
      videoUrl: tastingNotesData?.wine?.video_url ?? "",
      wineCharateristicsSet: tastingNotesData?.characteristics,
      tastingNotesSet: tastingNotesData?.tasting_notes?.map((el) => ({
        id: el.id,
        flavour: el.flavor
      })),
      menuPairing: tastingNotesData?.menu_pairing?.map((el) => ({
        id: el.id,
        name: el.name,
        image: el.image
          ? {
              id: el?.image_id,
              url: el.image
            }
          : ""
      }))
    })
  }

  const handleSubmit = (values) => {
    setIsFormSubmitting(true)
    const endDate = new Date(values.week)
    endDate.setDate(values.week.getDate() + 30)
    const payload = {
      name: values.wine,
      start_date: formatDate(values.week),
      end_date: formatDate(endDate),
      grape_varietal: values.grapeVarietals,
      origin: values.origin,
      personality: values.personality,
      video_url: values.videoUrl || null,
      wine_type: values?.wineType,
      wine_color: values?.week_answer,
      thumbnail: values.videoUrl ? videoThumbnail : null,
      ...(wineImage ? { image: wineImage } : {}),
      ...(values?.menuPairing[0]?.name === ""
        ? { menu_pairing: [] }
        : {
            menu_pairing: values?.menuPairing?.map((el, index) => {
              return {
                // ...(el?.id ? { id: el?.id } : {}),
                name: el?.name,
                ...(el?.image?.id || flavourImages[index]
                  ? {
                      image: el?.image?.id
                        ? el?.image?.id
                        : flavourImages[index]
                        ? flavourImages[index]
                        : null
                    }
                  : {})
              }
            })
          }),
      tasting_notes: values?.tastingNotesSet?.map((el) => ({
        flavor: el.flavour
      })),
      characteristics: values.wineCharateristicsSet?.map(({ characteristic, level }) => ({
        characteristic,
        level
      }))
    }
    dispatch(editTastingNotesAction(payload))
  }

  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ""
  }

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser)
    return () => {
      window.removeEventListener("beforeunload", alertUser)
    }
  }, [])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Edit Tasting Notes - DMV"
    dispatch(getTastingNotesDataAction())
    dispatch(DMVActions.initTastingNotes())
  }, [])

  useEffect(() => {
    if (isSubmitted) {
      dispatch(DMVActions.setIsTastingNotesSubmitted(false))
      history.push("/super-admin/dashboard/tasting-notes-config/list")
    }
  }, [isSubmitted])

  useEffect(() => {
    if (tastingNotesData) {
      initForm()
    }
  }, [tastingNotesData])

  return (
    <Container fluid className="p-0  ">
      <Row>
        <Col lg="12">
          <Header title="Edit Tasting Notes" breadCrumbs={breadCrumbs} back={true} />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchemaDMVWineOfMonth}
            onSubmit={handleSubmit}>
            {(formik) => (
              <>
                <Prompt
                  when={formik.dirty && !isFormSubmitting}
                  message={
                    "Changes you made may not be saved. Are you sure you want to leave this page ?"
                  }
                />
                <CombinedForm formik={formik} formReset={resetFormikData} isEditMode={true} />
              </>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  )
}

export default EditTastingNotes