import { Dialog, DialogContent, Grid, IconButton, styled, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import styles from "./EditQuestion.module.scss"
import CloseIcon from "@mui/icons-material/Close"
import AppSelect from "components/StyledComponents/AppSelect"
import AppTextField from "components/StyledComponents/AppTextField"
import ImageIcon from "assets/icons/ImageIcon.svg"
import { Formik } from "formik"
import { validationSchemaEditGamificationQuestion, validationSchemaEditSuggestedQuestion } from "helpers/validationSchema"
import AppButton from "components/StyledComponents/AppButton"
import DeleteImageIcon from "assets/icons/closeIcon.svg"
import { useDispatch, useSelector } from "react-redux"
import {
  editSuggestedQuestionAction,
  getTriviaCategoriesAction,
  uploadSuggestedQuestionImageAction
} from "redux/L&D/actions"
import { learningDevelopmentActions } from "redux/L&D/slice/user"
import DraggableOptions from "components/DraggableOptions"
import ImageUploadPopup from "../../trivia-contest/components/ImageUploadPopup"
import { editDMVAutoSuggestedQuestionAction } from "redux/DMV/actions/user"

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  }
}))

const difficultyLevelChoices = [
  { label: "Beginner", value: "Beginner" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Advance", value: "Advance" }
]

const EditQuestion = ({
  onClose = () => {},
  data = {},
  isEdit,
  currentPage = 0,
  isGamification
}) => {
  const [question, setQuestion] = useState({
    optionSet: [{ name: "", isCorrect: false }]
  })
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const triviaCategories = useSelector(
    (store) => store?.learningDevelopment?.trivia?.triviaContest?.triviaCategories
  )
  const { image } = useSelector((store) => store?.learningDevelopment?.suggestedQuestion)
  const dispatch = useDispatch()

  const onImagePopupOpen = () => {
    setIsImagePopupOpen(true)
  }

  const onImagePopupClose = () => {
    setIsImagePopupOpen(false)
  }

  const onImageUpload = (newImage) => {
    let data = new FormData()
    data.append("media", newImage)
    data.append("media_type", "IMAGE")
    data.append("content_type", newImage.type)
    dispatch(uploadSuggestedQuestionImageAction(data))
  }

  const onImageDelete = () => {
    setSelectedImage(null)
    dispatch(learningDevelopmentActions.setSuggestedQuestionImage(null))
  }

  const initFormikData = () => {
    const category = triviaCategories?.find((e) => e?.name === data?.category)?.id
    setQuestion({
      questionId: data.id,
      question: data?.title ?? "",
      category: category ?? "",
      difficultyLevel: data.level,
      duration: data.duration,
      optionSet: data?.answer?.map((option) => ({
        id: option.id,
        name: option.answer,
        isCorrect: option.is_correct
      }))
    })
    dispatch(
      learningDevelopmentActions.setSuggestedQuestionImage({
        id: data.image_id,
        url: data.image
      })
    )
  }

  const handleSubmit = (values) => {
    if (isGamification) {
      let payload = {
        image: image?.id || null,
        title: values.question,
        answers: values?.optionSet?.map((option) => ({
          id: option.id,
          answer: option.name,
          is_correct: option.isCorrect
        }))
      }
      dispatch(
        editDMVAutoSuggestedQuestionAction({ questionId: data.id, data: payload, currentPage })
      )
    } else {
      let payload = {
        image: image?.id || null,
        title: values.question,
        category: values.category,
        level: values.difficultyLevel,
        answers: values?.optionSet?.map((option) => ({
          id: option.id,
          answer: option.name,
          is_correct: option.isCorrect
        }))
      }
      dispatch(editSuggestedQuestionAction({ questionId: data.id, data: payload, currentPage }))
    }

    onClose()
  }

  useEffect(() => {
    initFormikData()
  }, [data, triviaCategories])

  useEffect(() => {
    dispatch(getTriviaCategoriesAction())
  }, [])

  return (
    <StyledDialog open={true} onClose={onClose} maxWidth="">
      <ImageUploadPopup
        onClose={onImagePopupClose}
        open={isImagePopupOpen}
        aspectHeight={3}
        aspectWidth={4}
        {...{
          selectedImage,
          setSelectedImage,
          onImageUpload,
          onImageDelete
        }}
      />
      <DialogContent id="editQuestionDialog" className={styles.dialogBox}>
        <Formik
          initialValues={question}
          validationSchema={
            isGamification
              ? validationSchemaEditGamificationQuestion
              : validationSchemaEditSuggestedQuestion
          }
          enableReinitialize={true}
          onSubmit={handleSubmit}>
          {(formik) => (
            <React.Fragment>
              <Box className="d-flex justify-content-center position-relative">
                <Typography variant="h2">{`Edit ${
                  data?.suggested_by ? "Suggested " : ""
                }Question`}</Typography>
                <Box className={styles.closeButton}>
                  <span role="button" onClick={onClose}>
                    <IconButton sx={(theme) => ({ color: theme.palette.text.primary })}>
                      <CloseIcon sx={{ height: "14px", width: "14px" }} />
                    </IconButton>
                  </span>
                </Box>
              </Box>
              <Box height={40} />
              <Box sx={{ padding: "0 11px" }}>
                {data?.is_added_manually && (
                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <AppSelect
                        id="category"
                        name="category"
                        disabled={isEdit}
                        menuItems={triviaCategories?.map((e) => ({
                          label: e.name,
                          value: e.id
                        }))}
                        label="Trivia Category"
                        isRequired={true}
                        value={formik.values.category}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <AppSelect
                        id="difficultyLevel"
                        name="difficultyLevel"
                        menuItems={difficultyLevelChoices}
                        label="Difficulty Level"
                        isRequired={true}
                        value={formik.values.difficultyLevel}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                )}
                <Box height={35} />
                <Typography variant="h2" sx={{ fontSize: 20 }}>
                  Question
                </Typography>
                <Box height={19} />
                <Box className="d-flex">
                  <AppTextField
                    id="question"
                    name="question"
                    placeholder="Enter Question"
                    value={formik.values.question}
                    onChange={formik.handleChange}
                    className="flex-grow-1 flex-shrink-1"
                    fullWidth
                    error={formik.touched.question && Boolean(formik.errors.question)}
                    helperText={formik.touched.question && formik.errors.question}
                  />
                  <img
                    role="button"
                    onClick={onImagePopupOpen}
                    src={ImageIcon}
                    className="flex-shrink-0 ms-4"
                    alt="Upload Image"
                  />
                </Box>
                {image?.url ? (
                  <Box className="mt-4 position-relative" sx={{ width: "fit-content" }}>
                    <img
                      style={{ height: 182, borderRadius: 6, width: "100%" }}
                      src={image.url}
                      alt=""
                    />
                    <img
                      role="button"
                      src={DeleteImageIcon}
                      alt=""
                      className="position-absolute"
                      style={{ top: 12, right: 12 }}
                      onClick={onImageDelete}
                    />
                  </Box>
                ) : null}
                <DraggableOptions
                  formik={formik}
                  getOptionValues={(values = formik.values) => values?.optionSet}
                  getOptionPath={() => `optionSet`}
                />
                <Box className="d-flex justify-content-center">
                  <AppButton className="mt-3 px-5" variant="outlined" onClick={formik.handleSubmit}>
                    Save
                  </AppButton>
                </Box>
              </Box>
            </React.Fragment>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  )
}

export default EditQuestion
