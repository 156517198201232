import ButtonNav from "components/ButtonNav"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { learningDevelopmentActions } from "redux/L&D/slice/user"

const tabItems = {
  DINNER: "dinner",
  // LUNCH: "lunch",
  BAR_MENU: "bar menu",
  BRUNCH: "wine by glass",
  SPIRITS: "spirits"
}

function TriviaMenuNavBar(props) {
  const [menu] = useState(menuNav)
  const dispatch = useDispatch()

  function handleButtonNav(item) {
    dispatch(learningDevelopmentActions.setGenerate_Question_MenuLink(item))
  }

  return (
    <>
      {menu?.map((item, i) => {
        return (
          <ButtonNav
            style={{ marginRight: 12 }}
            onPress={() => handleButtonNav(item?.value)}
            key={i}
            isActive={props.activeTab === item.value ? true : false}>
            {item.name}
          </ButtonNav>
        )
      })}
    </>
  )
}

export default TriviaMenuNavBar

const menuNav = [
  {
    name: "Dinner",
    isActive: true,
    value: tabItems?.DINNER
  },
  // {
  //   name: "Lunch",
  //   isActive: false,
  //   value: tabItems?.LUNCH
  // },
  {
    name: "Bar Menu",
    isActive: false,
    value: tabItems?.BAR_MENU
  },
  {
    name: "Wine By Glass",
    isActive: false,
    value: tabItems?.BRUNCH
  },
  {
    name: "Spirits",
    isActive: false,
    value: tabItems?.SPIRITS
  }
]
