import React, { useEffect, useState } from "react"
import { Fragment } from "react"
import { Container } from "react-bootstrap"
import { Typography } from "@mui/material"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
// import SuggestorAnswers from "./components/SuggestorAnswers/SuggestorAnswers"
import RestaurantTable from "./components/RestaurantTable/RestaurantTable"
import Header from "components/Header/Header"

import { createBreadcrumbs } from "helpers/breadCrumbs"
import Modal from "components/Modal/Modal"
import CorrectAnswerModal from "components/CorrectAnswerModal/CorrectAnswerModal"
import { useDispatch, useSelector } from "react-redux"
import {
  getAllQuestionsByTriviaAction,
  getCorrectAnswersByTriviaAction,
  getIncorrectAnswersByTriviaAction,
  getResturantListByTriviaAction,
  getTopSuggestorsByTriviaAction,
  getViewTriviaContestDetailsAction,
  getViewTriviaQuestionsAction
} from "redux/L&D/actions"
import QuestionTable from "components/QuestionTable"
import { appActions } from "redux/app/slice/app"
import { getLastDayOfMonth } from "helpers/functions"
import AllTriviaQuestionTable from "./components/AllQuestionsAnalytics"

function TriviaAnalyticsSubScreen() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const {
    triviaId,
    restaurantList,
    correctAnswers,
    incorrectAnswers
    //  topSuggestors
  } = useSelector((store) => store?.learningDevelopment?.trivia?.contestAnalytics || [])
  const { allQuestions } = useSelector((store) => store?.learningDevelopment || [])

  const [currentPage, setCurrentPage] = useState(1)
  const { contestData } = useSelector(
    (store) => store?.learningDevelopment?.trivia?.viewTrivia || {}
  )

  const { date, employeeClass, restaurant, orderBy } = useSelector(
    (store) => store?.app?.filters || {}
  )

  const dispatch = useDispatch()

  const handleClose = () => {
    setModalOpen(false)
  }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Trivia Contest"
    const dispatchActionsSequentially = async () => {
      dispatch(appActions.setIsLoading(true))
      await dispatch(getViewTriviaContestDetailsAction())
      await delay(1000)
      await dispatch(getViewTriviaQuestionsAction())
      await delay(1000)
      dispatch(appActions.setIsLoading(false))
    }
    dispatchActionsSequentially()
  }, [])

  useEffect(() => {
    if (contestData.end_date) {
      dispatch(appActions.initFilters({ date: contestData.end_date }))
    }
  }, [contestData.end_date])

  useEffect(() => {
    const newDate = getLastDayOfMonth(date)

    const dispatchActionsSequentially = async () => {
      if (triviaId) {
        dispatch(appActions.setIsLoading(true))
        await dispatch(
          getResturantListByTriviaAction({
            date: newDate,
            employeeClass: employeeClass,
            restaurant: restaurant
          })
        )
        await delay(1000)
        await dispatch(
          getCorrectAnswersByTriviaAction({
            date: newDate,
            employeeClass: employeeClass,
            restaurant: restaurant
          })
        )
        await delay(1000)
        await dispatch(
          getIncorrectAnswersByTriviaAction({
            date: newDate,
            employeeClass: employeeClass,
            restaurant: restaurant
          })
        )
        await delay(1000)
        await dispatch(
          getTopSuggestorsByTriviaAction({
            date: newDate,
            employeeClass: employeeClass,
            restaurant: restaurant
          })
        )
        await delay(1000)
        dispatch(appActions.setIsLoading(false))
      }
    }
    dispatchActionsSequentially()
  }, [triviaId, date, employeeClass, restaurant])

  useEffect(() => {
    const newDate = getLastDayOfMonth(date)
    dispatch(
      getAllQuestionsByTriviaAction({
        page: currentPage,
        date: newDate,
        employeeClass: employeeClass,
        restaurant: restaurant,
        orderBy
      })
    )
  }, [currentPage, date, employeeClass, restaurant, orderBy])

  useEffect(() => {
    setCurrentPage(1)
  }, [date, employeeClass, restaurant, orderBy])

  return (
    <Fragment>
      <Modal open={modalOpen} onClose={handleClose}>
        <CorrectAnswerModal modalContent={modalContent} />
      </Modal>
      <Container fluid className="p-0 ">
        <Row>
          <Col lg="12">
            <Header
              title="Trivia Analytics"
              breadCrumbs={breadCrumbs}
              style={{ marginBottom: "0" }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <RestaurantTable columns={restaurantColumn} rows={restaurantList}></RestaurantTable>
          </Col>
        </Row>
      </Container>
      <Container fluid className="p-0 mt-5">
        <Typography variant="h2" className="mb-2">
          Trivia Questions Answered Most Correctly
        </Typography>
        <Row>
          <Col lg="12">
            <QuestionTable
              isCorrectAnswer={true}
              rows={correctAnswers ?? []}
              columns={correctColumns}
              setModalOpen={setModalOpen}
              setModalContent={setModalContent}
            />
          </Col>
        </Row>
      </Container>
      <Container fluid className="p-0 mt-5">
        <Typography variant="h2" className="mb-2">
          Trivia Questions Answered Most Incorrectly
        </Typography>
        <Row>
          <Col lg="12">
            <QuestionTable
              isCorrectAnswer={false}
              rows={incorrectAnswers ?? []}
              columns={IncorrectColumns}
              setModalOpen={setModalOpen}
              setModalContent={setModalContent}
            />
          </Col>
        </Row>
      </Container>
      {/* <Container fluid className="p-0 mt-5">
        <Typography variant="h2" className="mb-2">
          Top Suggestors For Trivia Questions
        </Typography>
        <Row>
          <Col lg="12">
            <SuggestorAnswers columns={SuggestorColumn} rows={topSuggestors}></SuggestorAnswers>
          </Col>
        </Row>
      </Container> */}
      <Container fluid className="p-0 mt-5">
        <Typography variant="h2" className="mb-2">
          All Trivia Questions
        </Typography>
        <Row>
          <Col lg="12">
            <AllTriviaQuestionTable
              rows={allQuestions?.data ?? []}
              columns={AllQuestionsColumns}
              setModalOpen={setModalOpen}
              setModalContent={setModalContent}
              setPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={allQuestions?.pages}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default TriviaAnalyticsSubScreen

const restaurantColumn = [
  {
    id: "1",
    label: "Restaurant",
    minWidth: 100,
    align: "left"
  },
  {
    id: "2",
    label: "# Of Attempts",
    minWidth: 100,
    align: "center"
  },
  {
    id: "3",
    label: "% Correct Answered",
    minWidth: 100,
    align: "center"
  }
  // {
  //   id: "4",
  //   label: "Invite Rate %",
  //   align: "right",
  //   minWidth: 100
  // }
]

const correctColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Questions",
    label: "Questions",
    minWidth: 170
  },
  {
    id: "Correct Answer",
    label: "Correct Answer",
    align: "center",
    minWidth: 100
  },
  {
    id: "Correct Answers",
    label: "Correct Answers",
    align: "center",
    minWidth: 100
  },
  {
    id: "Players Answered",
    label: "Players Answered",
    align: "center",
    minWidth: 100
  },
  {
    id: "% Correct Answered",
    label: "% Correct Answered",
    align: "center",
    minWidth: 50
  }
]

const IncorrectColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Questions",
    label: "Questions",
    minWidth: 170
  },
  {
    id: "Correct Answer",
    label: "Correct Answer",
    align: "center",
    minWidth: 100
  },
  {
    id: "Incorrect Answers",
    label: "Incorrect Answers",
    align: "center",
    minWidth: 100
  },
  {
    id: "Players Answered",
    label: "Players Answered",
    align: "center",
    minWidth: 100
  },
  {
    id: "% Incorrect Answered",
    label: "% Incorrect Answered",
    align: "center",
    minWidth: 50
  }
]

// const SuggestorColumn = [
//   {
//     id: "Rank",
//     label: "Rank",
//     minWidth: 50
//   },
//   {
//     id: "name",
//     label: "Name",
//     minWidth: 170
//   },
//   {
//     id: "Restaurant",
//     label: "Restaurant",
//     align: "center",
//     minWidth: 100
//   },
//   {
//     id: "User Type",
//     label: "User Type",
//     align: "center",
//     minWidth: 100
//   },
//   {
//     id: "Role",
//     label: "Role",
//     align: "center",
//     minWidth: 100
//   },
//   {
//     id: "# Of Questions Suggested",
//     label: "# Of Questions Suggested",
//     align: "center",
//     minWidth: 50
//   }
// ]

const AllQuestionsColumns = [
  { id: "Rank", label: "Rank", minWidth: 55 },
  { id: "Questions", label: "Questions", minWidth: 170 },
  {
    id: "Correct Answer",
    label: "Correct Answer",
    align: "center",
    minWidth: 170
  },
  {
    id: "Players Answered",
    label: "Players Answered",
    align: "center",
    minWidth: "fit-content",
    isSorting: true,
    sortId: "players_answered"
  },
  {
    id: "Total Correct Answers",
    label: "Total Correct Answers",
    align: "center",
    minWidth: "fit-content",
    isSorting: true,
    sortId: "correct_count"
  },
  {
    id: "Total Incorrect Answers",
    label: "Total Incorrect Answers",
    align: "center",
    minWidth: "fit-content",
    isSorting: true,
    sortId: "incorrect_count"
  },
  {
    id: "% Correct Answered",
    label: "% Correct Answered",
    align: "center",
    minWidth: "fit-content",
    isSorting: true,
    sortId: "percentage_correct"
  },
  {
    id: "% Incorrect Answered",
    label: "% Incorrect Answered",
    align: "center",
    minWidth: "fit-content",
    isSorting: true,
    sortId: "percentage_incorrect"
  }
]
