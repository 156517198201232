import React, { Fragment, useRef, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import UploadIcon from "assets/icons/UploadIcon.svg";
import TransparentImageIcon from "assets/icons/TransparentImageIcon.svg";
import RedCloseIcon from "assets/icons/RedCloseIcon.svg";
import GreenCheckIcon from "assets/icons/GreenCheckIcon.svg";
import AppButton from "components/StyledComponents/AppButton";
import DashedBorder from "assets/icons/DashedBorder.svg";
import styles from "../UserModal.module.scss";

function DragAndDropUser({ type, setAllFilesSelected, onChange, setDisable }) {
  const inputFile = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const theme = useTheme();
  const onBrowseClick = () => {
    inputFile.current.click();
  };

  const saveFiles = (files) => {
    setSelectedFile(files[0]);
    onChange(files[0]);
    setAllFilesSelected((prev) =>
      type === "CSV"
      && { ...prev, csvUploaded: true }
    );
  };

  const onFilesSelected = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      saveFiles(files);
    }
    setDisable(true)
    setFileError("");
    if (!(files[0].name).includes('xlsx')) {
      setFileError("Please Upload a .xlsx file  ")
      setDisable(false)
    }
  };

  const handleDelete = () => {
    setSelectedFile(null);
    setAllFilesSelected({})
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      saveFiles(files);
    }
  };

  return (
    <Fragment>
      {selectedFile ? (
        <Box>
          <Box
            className={styles.uploadCtr}
            style={{ height: "auto", marginBottom: 30 }}>
            <Typography variant="body1Regular">
              {type === "Video" ? "Video" : ""}
            </Typography>
            <Box className={styles.imageListItem}>
              <Box className={styles.imageItemInnerCtr}>
                <img src={TransparentImageIcon} alt="" className="me-2" />
                <Typography className="ms-1 me-3" variant="body1Regular">
                  {selectedFile?.name}
                </Typography>
                <img src={GreenCheckIcon} alt="" />
              </Box>
              <img
                className={styles.deleteBtn}
                src={RedCloseIcon}
                onClick={handleDelete}
                alt="delete"
              />
            </Box>
          </Box>
          <Typography variant="body1Regular" sx={{color:theme.palette.error.main}}>
            {`${fileError}`}
          </Typography>
        </Box>
      ) : (
        <Box>
          <div
            className={styles.uploadCtr}
            onDragOver={handleDragOver}
            onDrop={handleDrop}>
            <img src={DashedBorder} alt="" className={styles.borderImage} />
            <Box className={styles.innerUploadCtr}>
              <img src={UploadIcon} alt="" />
              <Typography variant="h3Italic">Drag And Drop {type}</Typography>
              <Typography
                className={styles.imgUploadHelperText}
                variant="body1Regular">
                or select files from device
              </Typography>
              <AppButton className={styles.browseBtn} onClick={onBrowseClick}>
                Browse
              </AppButton>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={onFilesSelected}
              />
            </Box>
          </div>
        </Box>
      )}
    </Fragment>
  );
}

export default DragAndDropUser;
