import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppSelect from "components/StyledComponents/AppSelect";
import AppTextField from "components/StyledComponents/AppTextField";
import DraggableOptions from "../../../../../../components/DraggableOptions";
import ImageIcon from "assets/icons/ImageIcon.svg";
import ImageUploadPopup from "../ImageUploadPopup";
import { useDispatch, useSelector } from "react-redux";
import { uploadQuestionImageAction } from "redux/L&D/actions";
import CloseIcon from "assets/icons/closeIcon.svg";
import { learningDevelopmentActions } from "redux/L&D/slice/user";

const difficultyLevelChoices = [
  { label: "Beginner", value: "Beginner" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Advance", value: "Advance" }
];

const QuestionForm = ({ formik, questionIndex, questionImageData }) => {
  const [questionText, setQuestionText] = useState("");
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const dispatch = useDispatch();
  const { triviaCategories } = useSelector(
    (store) => store?.learningDevelopment?.trivia?.triviaContest
  );

  const onImagePopupOpen = () => {
    setIsImagePopupOpen(true);
  };

  const onImagePopupClose = () => {
    setIsImagePopupOpen(false);
  };

  const questionErrors =
    (formik.errors.questionSet &&
      formik.errors.questionSet.length &&
      formik.errors.questionSet[questionIndex]) ||
    {};
  const questionTouched =
    (formik.touched.questionSet &&
      formik.touched.questionSet.length &&
      formik.touched.questionSet[questionIndex]) ||
    {};

  const getOptionValues = (values = formik.values) => {
    return values?.questionSet?.[questionIndex]?.optionSet;
  };

  const getOptionPath = () => {
    return `questionSet[${questionIndex}].optionSet`;
  };

  const onQuestionTextChange = (e) => {
    setQuestionText(e.target.value);
    const timeOutId = setTimeout(
      () =>
        formik.setFieldValue(
          `questionSet[${questionIndex}].question`,
          e.target.value
        ),
      800
    );
    return () => clearTimeout(timeOutId);
  };

  const onImageUpload = (newImage) => {
    let data = new FormData();
    data.append("media", newImage);
    data.append("media_type", "IMAGE");
    data.append("content_type", newImage.type);

    dispatch(
      uploadQuestionImageAction({
        index: questionIndex,
        data: data
      })
    );
  };

  const onImageDelete = () => {
    setSelectedImage(null);
    dispatch(
      learningDevelopmentActions.setUploadedImages({
        index: questionIndex,
        data: null
      })
    );
  };

  const onCategorySelect = (e) => {
    formik.setFieldValue(`questionSet[${questionIndex}].category`, e.value);
    formik.setFieldValue(`questionSet[${questionIndex}].categoryName`, e.label);
  };

  useEffect(() => {
    setQuestionText(
      formik.values?.questionSet?.[questionIndex]?.question ?? ""
    );
  }, []);


  return (
    <React.Fragment>
      <ImageUploadPopup
        {...{ setSelectedImage, selectedImage, onImageDelete, onImageUpload }}
        open={isImagePopupOpen}
        onClose={onImagePopupClose}
        aspectHeight={3}
        aspectWidth={4}
      />
      <Box sx={{ padding: "0 11px" }}>
        <Grid container spacing={4}>
          <Grid item md={4} sm={6}>
            <AppSelect
              id={`questionSet[${questionIndex}].category`}
              name={`questionSet[${questionIndex}].category`}
              menuItems={
                triviaCategories?.map(({ id, name }) => ({
                  label: name,
                  value: id
                })) ?? []
              }
              label="Trivia Category"
              isRequired={true}
              value={formik?.values?.questionSet?.[questionIndex]?.category}
              onMenuClick={onCategorySelect}
              error={questionErrors.category}
              touched={questionTouched.category}
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <AppSelect
              id={`questionSet[${questionIndex}].difficultyLevel`}
              name={`questionSet[${questionIndex}].difficultyLevel`}
              menuItems={difficultyLevelChoices}
              label="Difficulty Level"
              isRequired={true}
              value={
                formik?.values?.questionSet?.[questionIndex]?.difficultyLevel
              }
              onChange={formik.handleChange}
              error={questionErrors.difficultyLevel}
              touched={questionTouched.difficultyLevel}
            />
          </Grid>
        </Grid>
        <Box height={35} />
        <Typography variant="h2" sx={{ fontSize: 20 }}>
          Question
        </Typography>
        <Box height={19} />
        <Box className="d-flex align-items-start mb-2">
          <AppTextField
            placeholder="Enter Question"
            key={`questionSet[${questionIndex}].question`}
            value={questionText}
            onChange={onQuestionTextChange}
            className="flex-grow-1 flex-shrink-1"
            fullWidth
            error={questionTouched.question && Boolean(questionErrors.question)}
            helperText={questionTouched.question && questionErrors.question}
          />
          <img
            role="button"
            src={ImageIcon}
            onClick={onImagePopupOpen}
            className="flex-shrink-0 ms-4"
            alt="Upload Image"
          />
        </Box>
        {questionImageData?.url ? (
          <Box className="mt-4 position-relative" sx={{ width: "fit-content" }}>
            <img
              style={{ height: 182, borderRadius: 6 }}
              src={questionImageData.url}
              alt=""
            />
            <img
              role="button"
              src={CloseIcon}
              alt=""
              className="position-absolute"
              style={{ top: 12, right: 12 }}
              onClick={onImageDelete}
            />
          </Box>
        ) : null}
        <DraggableOptions {...{ formik, getOptionValues, getOptionPath }} />
      </Box>
    </React.Fragment>
  );
};

export default QuestionForm;
