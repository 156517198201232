import { TableRow } from "@material-ui/core";
import {
  Backdrop,
  Menu,
  MenuItem,
  styled,
  TableCell,
  Typography,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckBox from "components/CheckBox";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import BarChartIcon from "../../../../../../assets/icons/BarChartIcon.svg";
import styles from "./ContestTable.module.scss";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteTriviaContestItemAction, getViewTriviaContestDetailsAction, getViewTriviaQuestionsAction } from "redux/L&D/actions";
import { getFormattedDate, getMonthYearString } from "helpers/functions";
import { learningDevelopmentActions } from "redux/L&D/slice/user";
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "12px 24px"
}));

const ContestRow = ({
  row,
  isSelected,
  isOpenModal,
  onChange,
  index,
  columns,
  isDeletable,
  setModalData
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(false);
  const openMenu = Boolean(anchorEl);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const formattedDate = getFormattedDate(row.creation_date);

  const getStatusStyles = (status) => {
    switch (status.toLowerCase()) {
      case "completed":
        return { color: theme.palette.green.main };
      case "draft":
        return { color: theme.palette.orange.main };
      case "published":
        return { color: theme.palette.green.light };
      default:
        return { color: theme.palette.text.primary };
    }
  };

  const onOptionsClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDeletePopup = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const editContest = () => {
    dispatch(learningDevelopmentActions.setEditTriviaId(row.id));
    history.push("/learning_development/dashboard/trivia-contest/edit");
  };

  const onTriviaAnalyticsClick = (e) => {
    e.stopPropagation();
    dispatch(learningDevelopmentActions.setViewTriviaId(row.id));
    dispatch(learningDevelopmentActions.setContestAnalyticsId(row.id));
    dispatch(getViewTriviaContestDetailsAction());
    dispatch(getViewTriviaQuestionsAction());
    history.push(`/learning_development/dashboard/trivia-contest/trivia-analytics`);
  };

  const renameContest = () => {
    setModalData({ name: row.name });
    dispatch(learningDevelopmentActions.setEditTriviaId(row.id));
    isOpenModal(true);
  };

  const handleDeleteContest = () => {
    dispatch(deleteTriviaContestItemAction(row.id));
  };

  const viewTriviaContest = () => {
    dispatch(learningDevelopmentActions.setViewTriviaId(row.id));
    history.push("/learning_development/dashboard/trivia-contest/view-trivia-contest");
  };

  useEffect(() => {
    dispatch(learningDevelopmentActions.setViewTriviaId(""));
    dispatch(learningDevelopmentActions.setViewTriviaData({}));
  },[])

  return (
    <>
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        title="Delete Contest"
        onDelete={handleDeleteContest}
        onClose={toggleDeletePopup}
      />
      <TableRow>
        <TableCell align="center" className={styles.checkBoxCell}>
          {isDeletable ? (
            <CheckBox
              role="button"
              onClick={() => onChange(index, row.id)}
              checked={isSelected}
            />
          ) : null}
        </TableCell>
        <TableCell>
          <Typography
            role={isDeletable ? "button" : ""}
            onClick={() => (isDeletable ? onChange(index, row.id) : null)}
            align={columns?.[0]?.align}
            variant="body1Regular">
            {row.name}
          </Typography>
        </TableCell>
        <TableCell align={columns?.[1]?.align}>
          <Typography variant="body1Regular">
            {getMonthYearString(row?.start_date)}
          </Typography>
        </TableCell>
        <TableCell align={columns?.[2]?.align}>
          <Typography variant="body1Regular">{`Every ${(row.published_week_day).toLowerCase() ?? ""}`}</Typography>
        </TableCell>
        <TableCell align={columns?.[3]?.align}>
          <Typography variant="body1Regular">{formattedDate}</Typography>
        </TableCell>
        <TableCell align={columns?.[4]?.align}>
          <Typography
            sx={() => getStatusStyles(row.status)}
            variant="body1Regular">
            {row.status}
          </Typography>
        </TableCell>
        <TableCell align={columns?.[5]?.align}>
          <Typography variant="body1Regular">{row.scope}</Typography>
        </TableCell>
        <TableCell align={columns?.[6]?.align}>
          {row.status.toLowerCase() === "completed" ? (
            <img
              onClick={onTriviaAnalyticsClick}
              className={styles.analyticsIcon}
              src={BarChartIcon}
              alt="Trivia Analytics"
            />
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell align="center" onClick={(e) => e.stopPropagation()}>
          <span onClick={onOptionsClick} className={styles.actionBtnCtr}>
            <MoreHorizIcon
              sx={() => ({
                height: "24px",
                width: "24px"
              })}
            />
          </span>
          <Backdrop
            sx={{ color: "#fff", zIndex: 1300 }}
            open={openMenu}
            onClick={handleMenuClose}>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  marginTop: "15px",
                  backgroundColor: theme.palette.background.default,
                  borderRadius: "12px",
                  "& .MuiList-root": {
                    backgroundColor: theme.palette.background.default,
                    width: "225px",
                    boxSizing: "border-box",
                    padding: "10px 0"
                  }
                }
              }}>
              <StyledMenuItem onClick={viewTriviaContest}>
                <Typography variant="body1Regular">View</Typography>
              </StyledMenuItem>
              {row.status.toLowerCase() === "draft" ||
              row.status.toLowerCase() === "published" ? (
                <StyledMenuItem onClick={renameContest}>
                  <Typography variant="body1Regular">Rename</Typography>
                </StyledMenuItem>
              ) : null}
              {row.status.toLowerCase() === "draft" ||
              row.status.toLowerCase() === "published" ? (
                <StyledMenuItem onClick={editContest}>
                  <Typography variant="body1Regular">Edit</Typography>
                </StyledMenuItem>
              ) : null}
              {isDeletable ? (
                <StyledMenuItem
                  onClick={toggleDeletePopup}
                  sx={{
                    borderTop: `2px solid ${theme.palette.background.secondary}`
                  }}>
                  <Typography
                    sx={{ color: theme.palette.red.main }}
                    variant="body1Regular">
                    Delete
                  </Typography>
                </StyledMenuItem>
              ) : null}
            </Menu>
          </Backdrop>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ContestRow;
