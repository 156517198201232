import * as yup from "yup"
const validationSchemaLogin = yup.object({
  email: yup
    .string()
    .required("Email is Required")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid Email Address"),
  password: yup.string().required("Password is Required")
})

const validationSchemaAddContest = yup.object({
  name: yup
    .string()
    .trim()
    .required("Name is Required")
    .test("len", "Max 40 characters allowed", (val) => !val?.length || val?.length <= 40),
  scope: yup.string().required("Scope is Required"),
  created_by: yup.string().required("Creator is Required"),
  published_week_day: yup.string().required("Publish Weekday is Required"),
  start_date: yup.string().required("Start Date is Required").nullable(),
  creation_date: yup.string().required("Creation Date is Required").nullable()
})

const validationSchemaEditContest = yup.object({
  name: yup
    .string()
    .trim()
    .required("Name is Required")
    .test("len", "Max 40 characters allowed", (val) => !val?.length || val?.length <= 40),
  scope: yup.string().required("Scope is Required"),
  created_by: yup.string().required("Creator is Required"),
  start_date: yup.string().required("Start Date is Required").nullable(),
  creation_date: yup.string().required("Creation Date is Required").nullable()
})

const validationSchemaRenameContest = yup.object({
  name: yup
    .string()
    .trim()
    .required("Name is Required")
    .test("len", "Max 40 characters allowed", (val) => !val?.length || val?.length <= 40)
})

const validationSchemaEditSuggestedQuestion = yup.object({
  question: yup
    .string()
    .trim()
    .required("Question Field Cannot Be Empty")
    .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
  category: yup.string().required("Category Is Required"),
  difficultyLevel: yup.string().required("Difficulty Level Is Required"),
  optionSet: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required("Option Is Required")
          .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
        isCorrect: yup.boolean()
      })
    )
    .min(4, "Please add 4 options")
    .test("one-correct-option", "Please select one correct option", (answers) => {
      return answers.some((answer) => answer.isCorrect)
    })
    .test("unique", "All Options must be Unique", function (list) {
      return list.length === new Set(list.map((option) => option.name)).size
    }),
  duration: yup
    .number()
    .required("Duration Is Required")
    .lessThan(61, "Duration must be less than or equal to 60")
    .moreThan(29, "Duration must be greater than or equal to 30")
})

const validationSchemaAddQuestions = yup.object({
  questionSet: yup.array().of(
    yup.object().shape({
      category: yup.string().required("Category Is Required"),
      difficultyLevel: yup.string().required("Difficulty Level Is Required"),
      question: yup
        .string()
        .trim()
        .required("Question Field Cannot Be Empty")
        .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
      optionSet: yup
        .array()
        .of(
          yup.object().shape({
            name: yup
              .string()
              .trim()
              .required("Option Is Required")
              .test(
                "len",
                "Max 100 characters allowed",
                (val) => !val?.length || val?.length <= 100
              ),
            isCorrect: yup.boolean()
          })
        )
        .min(4, "Please add 4 options")
        .test("one-correct-option", "Please select one correct option", (answers) => {
          return answers?.some((answer) => answer?.isCorrect)
        })
        .test("unique", "All Options must be Unique", function (list) {
          return list?.length === new Set(list?.map((option) => option?.name)).size
        })
    })
  )
})

const validationSchemaSuggestQuestion = yup.object({
  userCategory: yup.string().required("User Category is Required"),
  question: yup
    .string()
    .trim()
    .required("Question Field Cannot Be Empty")
    .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
  suggestedFor: yup.string().required("Select a Trivia Contest"),
  triviaCategory: yup.string().required("Category Is Required"),
  difficultyLevel: yup.string().required("Difficulty Level Is Required"),
  optionSet: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required("Option Is Required")
          .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
        isCorrect: yup.boolean()
      })
    )
    .min(4, "Please add 4 options")
    .test("one-correct-option", "Please select one correct option", (answers) => {
      return answers.some((answer) => answer.isCorrect)
    })
    .test("unique", "All Options must be Unique", function (list) {
      return list.length === new Set(list.map((option) => option.name)).size
    }),
  duration: yup
    .number()
    .required("Duration Is Required")
    .lessThan(61, "Duration must be less than or equal to 60")
    .moreThan(29, "Duration must be greater than or equal to 30")
})

const validationSchemaWineOfTheMonth = yup.object({
  month: yup.string().required("Select a month"),
  nameOfTheWine: yup
    .string()
    .required("Enter name of the Feature Item")
    .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
  videoThumbnail: yup.mixed().nullable().required("Video Thumbnail Is Required"),
  videoURL: yup
    .string()
    .trim()
    .required("Video Url is Required")
    .matches(/^(https?:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/, "Invalid URL"),
  wineImageVideo: yup
    .mixed()
    .nullable()
    .required("Wine Image Is Required")
    .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
  wineRegion: yup
    .string()
    .trim()
    // .required("Enter Wine Region")
    .test("len", "Max 500 characters allowed", (val) => !val?.length || val?.length <= 500),
  grapeVarietals: yup
    .string()
    // .required("Enter Grape Varietals Region")
    .test("len", "Max 500 characters allowed", (val) => !val?.length || val?.length <= 500),
  description: yup
    .string()
    .required("Enter Description")
    .test("len", "Max 500 characters allowed", (val) => !val?.length || val?.length <= 500),
  tastingNotes: yup
    .array()
    .of(
      yup
        .string()
        .trim()
        .max(500, "Max 500 characters are allowed")
        .min(1, "Min 1 characters are required")
    )
    .min(1, "Minimum one items is required")
    .max(40, "Maximum 40 items is allowed")
    .required("Provide at least one item"),
  menuPairing: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .trim()
        .test("len", "Max 50 characters allowed", (val) => !val?.length || val?.length <= 50)
    })
  ),
})

const validationSchemaWineOfTheMonthImport = yup.object({
  month: yup.string().required("Select a month"),
  nameOfTheWine: yup.string().required("Enter name of the wine"),
  videoURL: yup.string().required("Please enter  video URL"),
  pdfURL: yup.string().required("Please enter  pdf URL")
})

const validationSchemaWineOfTheMonthAdditional = yup.object({
  month: yup.string().required("Select a month"),
  description: yup.string().required("Enter description"),
  videoURL: yup.string().required("Please select a file"),
  pdfURL: yup.string().required("Please select a file")
})

const validationSchemaAddCategories = yup.object({
  categorySet: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required("Category Name Is Required")
          .test("len", "Max 20 characters allowed", (val) => val?.length <= 20),
        image: yup.mixed().nullable().required("Category Image Is Required")
      })
    )
    .min(4, "Please add 5 categories")
})

const validationSchemaDMVWineOfMonth = yup.object({
  week: yup.string().required("Select a date"),
  wine: yup
    .string()
    .trim()
    .required("Wine Name Is Required")
    .test("len", "Max 60 characters allowed", (val) => val?.length <= 60),
  origin: yup
    .string()
    .trim()
    .required("Wine Origin Is Required")
    .test("len", "Max 27 characters allowed", (val) => val?.length <= 27),
  personality: yup
    .string()
    .trim()
    .required("Personality Is Required")
    .test("len", "Max 27 characters allowed", (val) => val?.length <= 27),
  grapeVarietals: yup
    .string()
    .trim()
    .required("Grape Varietals Is Required")
    .test("len", "Max 90 characters allowed", (val) => val?.length <= 90),
  wineImage: yup.mixed().nullable().required("Wine Image Is Required"),
  videoUrl: yup
    .string()
    .trim()
    .optional()
    .matches(/^(https?:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/, "Invalid URL"),
  videoThumbnail: yup
    .mixed()
    .nullable()
    .when("videoUrl", {
      is: (videoUrl) => Boolean(videoUrl),
      then: yup.mixed().nullable().required("Thumbnail Is Required")
    }),
  week_answer: yup.string().required("Wine Color is Required"),
  tastingNotesSet: yup.array().of(
    yup.object().shape({
      flavour: yup
        .string()
        .trim()
        .required("Flavour Is Required")
        .test("len", "Max 18 characters allowed", (val) => val?.length <= 18)
    })
  ),
  menuPairing: yup.array().of(
    yup.object().shape({
      name: yup.string().trim().max(40, "Max 40 characters allowed"),
      image: yup.mixed().nullable()
    })
  ),
  wineCharateristicsSet: yup
    .array()
    .of(
      yup.object().shape({
        characteristic: yup.string().required("Characteristic Is Required"),
        level: yup.string().required("Level Is Required")
      })
    )
    .min(2, "Please add 2 Characteristics")
    .test("unique", "All Characteristics must be Unique", function (list) {
      return list.length === new Set(list.map((option) => option.characteristic)).size
    })
})

const validationSchemaDMVSpiritOfMonth = yup.object({
  week: yup.string().required("Select a date"),
  wine: yup
    .string()
    .trim()
    .required("Spirit Name Is Required")
    .test("len", "Max 60 characters allowed", (val) => val?.length <= 60),
  origin: yup
    .string()
    .trim()
    .required("Spirit Origin Is Required")
    .test("len", "Max 27 characters allowed", (val) => val?.length <= 27),
  personality: yup
    .string()
    .trim()
    .required("Personality Is Required")
    .test("len", "Max 27 characters allowed", (val) => val?.length <= 27),
  grapeVarietals: yup
    .string()
    .trim()
    .required("Ingredients Is Required")
    .test("len", "Max 90 characters allowed", (val) => val?.length <= 90),
  wineImage: yup.mixed().nullable().required("Spirit Image Is Required"),
  videoUrl: yup
    .string()
    .trim()
    .optional()
    .matches(/^(https?:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/, "Invalid URL"),
  videoThumbnail: yup
    .mixed()
    .nullable()
    .when("videoUrl", {
      is: (videoUrl) => Boolean(videoUrl),
      then: yup.mixed().nullable().required("Thumbnail Is Required")
    }),
  week_answer: yup.string().required("Spirit Color is Required"),
  tastingNotesSet: yup.array().of(
    yup.object().shape({
      flavour: yup
        .string()
        .trim()
        .required("Flavour Is Required")
        .test("len", "Max 18 characters allowed", (val) => val?.length <= 18)
    })
  ),
  menuPairing: yup.array().of(
    yup.object().shape({
      name: yup.string().trim().max(40, "Max 40 characters allowed"),
      image: yup.mixed().nullable()
    })
  ),
  wineCharateristicsSet: yup
    .array()
    .of(
      yup.object().shape({
        characteristic: yup.string().required("Characteristic Is Required"),
        level: yup.string().required("Level Is Required")
      })
    )
    .min(2, "Please add 2 Characteristics")
    .test("unique", "All Characteristics must be Unique", function (list) {
      return list.length === new Set(list.map((option) => option.characteristic)).size
    })
})

const validationSchemaMembershipImage = yup.object({
  membershipImage: yup.mixed().nullable().required("Membership Image Is Required")
})

const validationSchemaWineClubMembership = yup.object({
  benefitsSet: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .trim()
        .required("Benefit Name Is Required")
        .test("len", "Max 20 characters allowed", (val) => val?.length <= 20),
      description: yup
        .string()
        .trim()
        .required("Benefit Detail Is Required")
        .test("len", "Max 20 characters allowed", (val) => val?.length <= 20),
      icon: yup.mixed().nullable().required("Benefit Icon Is Required"),
      memberships: yup.number().required("Please Select memberships"),
      tooltip: yup
        .string()
        .trim()
        .test("len", "Max 125 characters allowed", (val) => !val?.length || val?.length <= 125)
    })
  )
})

const validationSchemaWineClubMembershipEdit = yup.object({
  benefitName: yup
    .string()
    .trim()
    .required("Benefit Name Is Required")
    .test("len", "Max 20 characters allowed", (val) => val?.length <= 20),
  benefitDetail: yup
    .string()
    .trim()
    .required("Benefit Detail Is Required")
    .test("len", "Max 20 characters allowed", (val) => val?.length <= 20),
  imageIcon: yup.mixed().nullable().required("Benefit Icon Is Required"),
  membership: yup.number().required("Please Select memberships"),
  tooltip: yup
    .string()
    .trim()
    .test("len", "Max 125 characters allowed", (val) => !val?.length || val?.length <= 125)
})

const validationSchemaRaffleTicket = yup.object({
  start_date: yup.date().required("Start Date is Required"),
  time: yup.string().nullable().required("Raffle Time is Required"),
  // dinner_time: yup.string().nullable().required("Raffle Dinner Time is Required"),
  description: yup.string().trim().required("Description Is Required")
  // wine_club_dinner: yup
  //   .date()
  //   .min(yup.ref("start_date"), "Wine Club Dinner Date cannot be less/equals to than Raffle Date")
  //   .required("Dinner Date is Required")
})

const validationSchemaDMVGrandCru = yup.object(
  {
    date: yup.string().required("Month and Year are Required").nullable(),
    description: yup
      .string()
      .trim()
      .required("Description Is Required")
      .test("len", "Max 125 characters allowed", (val) => val?.length <= 125),
    title: yup
      .string()
      .trim()
      .required("Title Is Required")
      .test("len", "Max 100 characters allowed", (val) => val?.length <= 100),
    thumbnail: yup.mixed().nullable().required("Thumbnail Is Required"),
    video: yup.lazy(() =>
      yup
        .mixed()
        .nullable()
        .when("videoUrl", {
          is: (videoUrl) => !videoUrl,
          then: yup.mixed().nullable().required("Video Is Required"),
          otherwise: yup.mixed().optional().nullable()
        })
    ),
    videoUrl: yup.lazy(() =>
      yup.string().when("video", {
        is: (video) => !video,
        then: yup
          .string()
          .trim()
          .required("Video Url Is Required")
          .matches(/https?/, "Invalid URL"),
        otherwise: yup.string().optional().nullable()
      })
    )
  },
  [["video", "videoUrl"]]
)

const validationSchemaPerformanceManagementVideo = yup.object({
  title: yup
    .string()
    .trim()
    .required("Title Is Required")
    .test("len", "Max 125 characters allowed", (val) => val?.length <= 125),
  description: yup
    .string()
    .trim()
    .required("Description Is Required")
    .test("len", "Max 255 characters allowed", (val) => val?.length <= 255),
  videoUrl: yup
    .string()
    .trim()
    .required("Video Url is Required")
    .matches(/^(https?:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/, "Invalid URL"),
  thumbnail: yup.mixed().nullable().required("Thumbnail is Required")
})

const validationSchemaCreateRound = yup.object({
  year: yup.string().required("Year is Required").nullable(),
  round: yup.number().required("Round Is Required").max(15, "Must be less than 15"),
  startDate: yup.string().required("Start Date is Required").nullable(),
  endDate: yup.string().required("End Date is Required").nullable()
})

const validationSchemaFrontLineUser = yup.object({
  userType: yup.string().required("User Type is Required"),
  role: yup.string().required("Role is Required"),
  fullName: yup
    .string()
    .trim()
    .required("Name is Required")
    .test("len", "Max 50 characters allowed", (val) => val?.length <= 50),
  restaurant: yup.string().required("Restaurant is Required"),
  email: yup
    .string()
    .required("Email is Required")
    .email("Please enter the valid email id")
    .trim()
    .test(
      "Domain name is not valid",
      "Please enter a valid email",
      (email) =>
        email?.includes(".com") ||
        email?.includes(".co") ||
        email?.includes(".in") ||
        email?.includes(".io") ||
        email?.includes(".ai") ||
        email?.includes(".net") ||
        email?.includes(".org") ||
        email?.includes(".us")
    ),
  contactNumber: yup.string().trim().required("Contact Number is Required"),
  address: yup
    .string()
    .trim()
    .required("Address is Required")
    .test("len", "Max 255 characters allowed", (val) => val?.length <= 255),
  reportingManager: yup
    .string()
    .trim()
    .required("Reporting Manager is Required")
    .test("len", "Max 50 characters allowed", (val) => val?.length <= 50),
  hireDate: yup.string().required("Date of Hire is Required").nullable()
})

const validationSchemaAdminUser = yup.object({
  userType: yup.string().required("User Type is Required"),
  role: yup.string().required("Role is Required"),
  fullName: yup
    .string()
    .trim()
    .required("Name is Required")
    .test("len", "Max 50 characters allowed", (val) => val?.length <= 50),
  restaurant: yup.string().required("Restaurant is Required"),
  email: yup.string().trim().required("Email is Required"),
  contactNumber: yup.string().trim().required("Contact Number is Required"),
  address: yup
    .string()
    .trim()
    .required("Address is Required")
    .test("len", "Max 255 characters allowed", (val) => val?.length <= 255),
  reportingManager: yup
    .string()
    .trim()
    .required("Reporting Manager is Required")
    .test("len", "Max 50 characters allowed", (val) => val?.length <= 50),
  hireDate: yup.string().required("Date of Hire is Required").nullable(),
  specialUser: yup.string().required("Please select on Option").nullable()
  // exclusiveContent: yup.string().required("Please select on Option").nullable()
})

const validationSchemaCSV = yup.object({
  csvUrl: yup.string().required("File is Required")
})

const validationSchemaCommentAdd = yup.object({
  comment: yup
    .string()
    .trim()
    .required("Please add a comment")
    .test("len", "Max 20 characters allowed", (val) => val?.length <= 20)
})
const validationSchemaCategoryModal = yup.object({
  category: yup
    .string()
    .trim()
    .required("Please add a Category Text")
    .test("len", "Max 40 characters allowed", (val) => val?.length <= 40)
})

const validationSchemaMenuItems = yup.object({
  dishName: yup
    .string()
    .trim()
    .required("Dish Name is Required")
    .max(50, "Max 50 characters are allowed"),
  uploadThumbnail: yup.mixed(),
  // menuDescription: yup
  //   .array()
  //   .of(
  //     yup.string().max(50, "Max 50 characters are allowed").min(1, "Min 1 characters are required")
  //   )
  //   .min(1, "Minimum one items is required")
  //   .max(20, "Maximum 50 items is allowed")
  //   .required("Provide at least one item"),
  // allergens: yup
  //   .array()
  //   .of(
  //     yup
  //       .string()
  //       .trim()
  //       .max(50, "Max 50 characters are allowed")
  //       .min(1, "Min 1 characters are required")
  //   )
  //   .max(20, "Maximum 20 items is allowed"),
  chefDescription: yup
    .string()
    .trim()
    .required("Chef's Description Is Required")
    .test("len", "Max 500 characters are allowed", (val) => val?.length <= 500),
  scheduleNotificationDate: yup
    .string()
    .required("Notification Schedule Date is Required")
    .nullable(),
  scheduleNotificationTime: yup
    .string()
    .nullable()
    .required("Notification Schedule Time is Required")
})

const validationSchemaBarMenuItems = yup.object({
  cocktailName: yup
    .string()
    .trim()
    .required("Cocktail Name is Required")
    .max(50, "Max 50 characters are allowed"),
  preparationMethod: yup.string().trim().max(100, "Max 100 characters are allowed"),
  garnish: yup.string().trim().max(100, "Max 100 characters are allowed"),
  glassWare: yup.string().trim().max(100, "Max 100 characters are allowed"),
  bartenderSoundBites: yup.string().trim().max(1000, "Max 1000 characters are allowed"),
  scheduleNotificationDate: yup
    .string()
    .required("Notification Schedule Date is Required")
    .nullable(),
  scheduleNotificationTime: yup
    .string()
    .nullable()
    .required("Notification Schedule Time is Required")
})

const validationSchemaWineMenuItems = yup.object({
  wineName: yup
    .string()
    .trim()
    .required("Wine Name is Required")
    .max(50, "Max 50 characters are allowed"),
  producer: yup.string().trim().max(100, "Max 100 characters are allowed"),
  region: yup.string().trim().max(100, "Max 100 characters are allowed"),
  country: yup.string().trim().max(100, "Max 100 characters are allowed"),
  varietal: yup.string().trim().max(100, "Max 100 characters are allowed"),
  menuPairing: yup.string().trim().max(100, "Max 100 characters are allowed"),
  soundBites: yup.string().trim().max(600, "Max 600 characters are allowed"),
  scheduleNotificationDate: yup
    .string()
    .required("Notification Schedule Date is Required")
    .nullable(),
  scheduleNotificationTime: yup
    .string()
    .nullable()
    .required("Notification Schedule Time is Required")
})

const validationSchemaVersionAdd = yup.object({
  title: yup
    .string()
    .trim()
    .required("App Name is Required")
    .max(50, "Max 50 characters are allowed"),
  platform: yup.string().trim().required("Platform is Required"),
  appVersion: yup
    .string()
    .trim()
    .required("App Version is Required")
    .max(10, "Max 10 characters are allowed"),
  status: yup.string().trim().required("Status is Required")
})

const validationSchemaWineRegion = yup.object({
  region: yup
    .string()
    .trim()
    .required("Region Name is Required")
    .max(50, "Max 50 characters are allowed"),
  Place: yup.string().trim().max(100, "Max 100 characters are allowed"),
  famous_wines: yup.string().trim().max(600, "Max 600 characters are allowed"),
  white_grape_varietals: yup.string().trim().max(600, "Max 600 characters are allowed"),
  red_grape_varietals: yup.string().trim().max(600, "Max 600 characters are allowed"),
  terroir: yup.string().trim().max(100, "Max 100 characters are allowed"),
  famous_vineyards: yup.string().trim().max(100, "Max 100 characters are allowed"),
  unique_fact: yup.string().trim().max(500, "Max 500 characters are allowed")
})

const validationSchemaBeverageItems = yup.object({
  cocktailName: yup
    .string()
    .trim()
    .required("Cocktail Name is Required")
    .max(50, "Max 50 characters are allowed"),
  description: yup.string().trim().max(500, "Max 500 characters are allowed"),
  tasteProfile: yup.string().trim().max(600, "Max 600 characters are allowed"),
  // unique_fact: yup.string().trim().max(1500, "Max 1500 characters are allowed"),
  origin_name: yup.string().trim().max(100, "Max 100 characters are allowed"),
  how_its_made: yup.string().trim().max(1500, "Max 1500 characters are allowed")
})

const validationSchemaTriviaMonth = yup.object({
  month: yup.string().trim().required("Cocktail Name is Required")
})

const validationSchemaEditGamificationQuestion = yup.object({
  question: yup
    .string()
    .trim()
    .required("Question Field Cannot Be Empty")
    .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
  optionSet: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required("Option Is Required")
          .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
        isCorrect: yup.boolean()
      })
    )
    .min(4, "Please add 4 options")
    .test("one-correct-option", "Please select one correct option", (answers) => {
      return answers.some((answer) => answer.isCorrect)
    })
    .test("unique", "All Options must be Unique", function (list) {
      return list.length === new Set(list.map((option) => option.name)).size
    })
})

const validationSchemaSpiritItems = yup.object({
  spiritName: yup
    .string()
    .trim()
    .required("Spirit Name is Required")
    .max(50, "Max 50 characters are allowed"),
  description: yup.string().trim().max(500, "Max 500 characters are allowed"),
  origin_name: yup.string().trim().max(500, "Max 500 characters are allowed"),
  nose: yup.string().trim().max(500, "Max 500 characters are allowed"),
  palate: yup.string().trim().max(500, "Max 500 characters are allowed"),
  finish: yup.string().trim().max(500, "Max 500 characters are allowed"),
  distillation: yup.string().trim().max(500, "Max 500 characters are allowed"),
  aging: yup.string().trim().max(500, "Max 500 characters are allowed"),
  bottling: yup.string().trim().max(500, "Max 500 characters are allowed"),
  unique_fact: yup.array().of(
    yup.object().shape({
      fact: yup.string().trim().max(500, "Max 500 characters are allowed")
    })
  )
})

const validationSchemaNotification = yup.object({
  notificationName: yup
    .string()
    .trim()
    .required("Notification Name is Required")
    .test("len", "Max 40 characters allowed", (val) => !val?.length || val?.length <= 40),
  employeeClass: yup
    .array()
    .of(yup.string().min(1, "Employee Class is Required"))
    .min(1, "Employee Class is Required")
    .required("Employee Class is Required"),
  scheduleNotificationDate: yup
    .string()
    .required("Date of Schedule Notification is Required")
    .nullable(),
  scheduleNotificationTime: yup
    .string()
    .nullable()
    .required("Notification Schedule Time is Required"),
  notificationContent: yup
    .string()
    .trim()
    .required("Notification Content is Required")
    .max(100, "Max 100 characters are allowed")
})

const validationSchemaSpiritSummary = yup.object().shape({
  category: yup.string().test("is-not-zero", "Category must be selected", (value) => value !== "0"),
  description: yup.string().trim().max(500, "Max 500 characters are allowed"),
  origin_name: yup.string().trim().max(500, "Max 500 characters are allowed"),
  nose: yup.string().trim().max(500, "Max 500 characters are allowed"),
  palate: yup.string().trim().max(500, "Max 500 characters are allowed"),
  finish: yup.string().trim().max(500, "Max 500 characters are allowed"),
  distillation: yup.string().trim().max(500, "Max 500 characters are allowed"),
  aging: yup.string().trim().max(500, "Max 500 characters are allowed"),
  bottling: yup.string().trim().max(500, "Max 500 characters are allowed"),
  unique_fact: yup.array().of(
    yup.object().shape({
      fact: yup.string().trim().max(500, "Max 500 characters are allowed")
    })
  )
})

const validationSchemaGiftCard = yup.object({
  giftCardType: yup.string().required("Price Type is Required"),
  uploadThumbnail: yup.mixed().nullable().required("Background Image Is Required"),
  header: yup
    .string()
    .trim()
    .required("Header is Required")
    .max(100, "Max 100 characters are allowed"),
  month: yup.string().required("Date is Required").nullable(),
})

export {
  validationSchemaLogin,
  validationSchemaAddContest,
  validationSchemaEditSuggestedQuestion,
  validationSchemaAddQuestions,
  validationSchemaSuggestQuestion,
  validationSchemaWineOfTheMonth,
  validationSchemaWineOfTheMonthImport,
  validationSchemaWineOfTheMonthAdditional,
  validationSchemaAddCategories,
  validationSchemaDMVWineOfMonth,
  validationSchemaEditContest,
  validationSchemaRenameContest,
  validationSchemaWineClubMembership,
  validationSchemaWineClubMembershipEdit,
  validationSchemaMembershipImage,
  validationSchemaRaffleTicket,
  validationSchemaDMVGrandCru,
  validationSchemaPerformanceManagementVideo,
  validationSchemaCreateRound,
  validationSchemaFrontLineUser,
  validationSchemaCSV,
  validationSchemaAdminUser,
  validationSchemaCommentAdd,
  validationSchemaCategoryModal,
  validationSchemaMenuItems,
  validationSchemaBarMenuItems,
  validationSchemaWineMenuItems,
  validationSchemaVersionAdd,
  validationSchemaWineRegion,
  validationSchemaBeverageItems,
  validationSchemaTriviaMonth,
  validationSchemaEditGamificationQuestion,
  validationSchemaSpiritItems,
  validationSchemaNotification,
  validationSchemaSpiritSummary,
  validationSchemaDMVSpiritOfMonth,
  validationSchemaGiftCard
}
