import { createSlice } from "@reduxjs/toolkit"
import { revertAllDMV } from "../actions/user"

const initialState = {
  wineClubMembership: {
    benefitsImages: [],
    isSubmitted: false,
    membershipImage: null,
    existingBenefits: [],
    membershipImageURL: null
  },
  triviaCategories: {
    id: null,
    list: [],
    images: [],
    isSubmitted: false
  },
  tastingNotes: {
    id: null,
    wineImage: null,
    flavourImages: [],
    isSubmitted: false,
    weekList: [],
    tastingNotesData: {},
    videoThumbnail: null
  },
  wineBenefits: {
    id: null,
    benefitImageIcon: null,
    wineBenefitData: {},
    isSubmitted: false
  },
  grandCru: {
    videoData: {},
    goalsData: {
      id: "",
      server: 0,
      bartender: 0,
      tr: 0
    },
    loading: 0,
    thumbnail: null,
    video: null,
    isVideoSubmitted: false,
    isGoalsSubmitted: false
  },
  videoGallery: {
    id: "",
    videoData: null,
    allVideos: [],
    assignedVideos: [],
    unassignedVideos: [],
    thumbnail: null,
    isSubmitted: false,
    isDeleted: false
  },
  rounds: {
    roundId: null,
    roundsList: [],
    viewRoundData: {}
  },
  roundForm: {
    id: null,
    isFormSubmitted: false,
    data: {}
  },
  holidayCalendar: {
    data: {}
  },
  assignVideos: {
    loading: false,
    videoLibrary: [],
    totalPages: 0,
    weekData: [],
    isSubmitted: false
  },
  menuCategory: {
    name: "",
    list: "Summary View"
  },
  dmvMenuLink: "wine_region",
  wineRegionData: [],
  beverageData: [],
  DMVmenu_Category_ID: "",
  wineMenuItem: {},
  itemID: "",
  beverageMenuItem: {},
  DMVAutoSuggestQuestions: [],
  DMVgenerate_question_MenuLink: "wine_region",
  SelectedDMVMenuItems: [],
  SpiritSubMenu: [],
  spiritData: [],
  spiritMenuItem: {},
  generateQuestionSpiritData: {},
  spiritSummary: {}
}

const dmv = createSlice({
  name: "dmv",
  initialState: initialState,
  extraReducers: (builder) => builder.addCase(revertAllDMV, () => initialState),
  reducers: {
    setbenefitImage: (state, action) => {
      state.wineClubMembership.benefitsImages[action.payload.index] = action.payload.id
    },
    setIsWineClubMembershipSubmitted: (state, action) => {
      state.wineClubMembership.isSubmitted = action.payload
    },
    setMembershipImage: (state, action) => {
      state.wineClubMembership.membershipImage = action.payload
    },
    setMembershipImageURL: (state, action) => {
      state.wineClubMembership.membershipImageURL = action.payload
    },
    initWineClubMembership: (state) => {
      state.wineClubMembership = {
        ...state.wineClubMembership,
        benefitsImages: [],
        isSubmitted: false,
        membershipImage: null,
        membershipImageURL: null
      }
    },
    setExistingBenefits: (state, action) => {
      state.wineClubMembership.existingBenefits = action.payload
    },
    setTriviaCategoriesList: (state, action) => {
      state.triviaCategories.list = action.payload
    },
    setTastingNotesWineImage: (state, action) => {
      state.tastingNotes.wineImage = action.payload
    },
    setTastingNotesVideoThumbnail: (state, action) => {
      state.tastingNotes.videoThumbnail = action.payload
    },
    setTastingNotesFlavourImage: (state, action) => {
      state.tastingNotes.flavourImages[action.payload.index] = action.payload.id
    },
    setIsTastingNotesSubmitted: (state, action) => {
      state.tastingNotes.isSubmitted = action.payload
    },
    initTastingNotes: (state) => {
      state.tastingNotes = {
        ...state.tastingNotes,
        wineImage: null,
        flavourImages: [],
        isSubmitted: false
      }
    },
    setWeekList: (state, action) => {
      state.tastingNotes.weekList = action.payload
    },
    setTastingNotesId: (state, action) => {
      state.tastingNotes.id = action.payload
    },
    setTastingNotesData: (state, action) => {
      state.tastingNotes.tastingNotesData = action.payload
    },
    setTriviaCategoryImage: (state, action) => {
      state.triviaCategories.images[action.payload.index] = action.payload.id
    },
    removeTriviaCategoryImage: (state, action) => {
      state.triviaCategories.images = state.triviaCategories.images?.splice(action.payload, 1)
    },
    setIsTriviaCategoriesSubmitted: (state, action) => {
      state.triviaCategories.isSubmitted = action.payload
    },
    initTriviaCategories: (state) => {
      state.triviaCategories = {
        ...state.triviaCategories,
        images: [],
        isSubmitted: false
      }
    },
    setWineBenefitId: (state, action) => {
      state.wineBenefits.id = action.payload
    },
    setWineBenefitData: (state, action) => {
      state.wineBenefits.wineBenefitData = action.payload
    },
    initWineBenefits: (state) => {
      state.wineBenefits = {
        ...state.wineBenefits,
        isSubmitted: false,
        benefitImageIcon: null
      }
    },
    setTriviaCategoryId: (state, action) => {
      state.triviaCategories.id = action.payload
    },
    setBenefitImageIcon: (state, action) => {
      state.wineBenefits.benefitImageIcon = action.payload
    },
    setIsBenefitSubmitted: (state, action) => {
      state.wineBenefits.isSubmitted = action.payload
    },
    initGrandCru: (state) => {
      state.grandCru = {
        videoData: {},
        goalsData: {
          id: "",
          server: 0,
          bartender: 0,
          tr: 0
        },
        loading: 0,
        thumbnail: null,
        video: null,
        isVideoSubmitted: false,
        isGoalsSubmitted: false
      }
    },
    setGrandCruVideoData: (state, action) => {
      state.grandCru.videoData = action.payload
    },
    setGrandCruGoalsData: (state, action) => {
      state.grandCru.goalsData = action.payload
    },
    setGrandCruVideo: (state, action) => {
      state.grandCru.video = action.payload
    },
    setGrandCruThumbnail: (state, action) => {
      state.grandCru.thumbnail = action.payload
    },
    setGrandCruVideoSubmitted: (state, action) => {
      state.grandCru.isVideoSubmitted = action.payload
    },
    setGrandCruGoalsSubmitted: (state, action) => {
      state.grandCru.isGoalsSubmitted = action.payload
    },
    setGrandCruLoadingStart: (state) => {
      state.grandCru.loading = state.grandCru.loading + 1
    },
    setGrandCruLoadingEnd: (state) => {
      state.grandCru.loading = state.grandCru.loading - 1
    },
    initVideoGallery: (state) => {
      state.videoGallery = {
        ...state.videoGallery,
        videoData: null,
        thumbnail: null,
        isSubmitted: false
      }
    },
    setVideoGalleryThumbnail: (state, action) => {
      state.videoGallery.thumbnail = action.payload
    },
    setVideoGalleryVideoSubmitted: (state, action) => {
      state.videoGallery.isSubmitted = action.payload
    },
    setVideoGalleryVideoDeleted: (state, action) => {
      state.videoGallery.isDeleted = action.payload
    },
    setVideoGalleryVideoData: (state, action) => {
      state.videoGallery.videoData = action.payload
    },
    setVideoGalleryId: (state, action) => {
      state.videoGallery.id = action.payload
    },
    setVideoGalleryVideosList: (state, action) => {
      switch (action.payload.type) {
        case "assigned":
          state.videoGallery.assignedVideos = action.payload.data
          break
        case "unassigned":
          state.videoGallery.unassignedVideos = action.payload.data
          break
        default:
          state.videoGallery.allVideos = action.payload.data
      }
    },
    setRoundsList: (state, action) => {
      state.rounds.roundsList = action.payload
    },
    setRoundId: (state, action) => {
      state.rounds.roundId = action.payload
    },
    setViewRoundData: (state, action) => {
      state.rounds.viewRoundData = action.payload
    },
    initRoundForm: (state) => {
      state.roundForm = {
        id: "",
        isFormSubmitted: false,
        data: {}
      }
    },
    setIsRoundFormSubmitted: (state, action) => {
      state.roundForm.isFormSubmitted = action.payload
    },
    setFromRoundId: (state, action) => {
      state.roundForm.id = action.payload
    },
    setRoundFormData: (state, action) => {
      state.roundForm.data = action.payload
    },
    initHolidayCalendar: (state) => {
      state.holidayCalendar.data = {}
    },
    setHolidayCalendarData: (state, action) => {
      const { date, data } = action.payload
      let newData = { ...(state?.holidayCalendar?.data || {}) }
      newData[date] = data
      state.holidayCalendar.data = newData
    },
    setVideoLibrary: (state, action) => {
      state.assignVideos.videoLibrary = [...state.assignVideos.videoLibrary, ...action.payload]
    },
    setAssignVideosLoading: (state, action) => {
      state.assignVideos.loading = action.payload
    },
    setAssignVideoTotalPages: (state, action) => {
      state.assignVideos.totalPages = action.payload
    },
    initVideoLibrary: (state) => {
      state.assignVideos = {
        loading: false,
        videoLibrary: [],
        totalPages: 0,
        weekData: []
      }
    },
    setAssignVideosWeekData: (state, action) => {
      state.assignVideos.weekData = action.payload
    },
    setAssignVideos: (state, action) => {
      state.assignVideos.videoLibrary = action.payload
    },
    setAssignVideosSubmitted: (state, action) => {
      state.assignVideos.isSubmitted = action.payload
    },
    setDMV_Menu_Link: (state, action) => {
      state.dmvMenuLink = action.payload
    },
    setWineRegionData: (state, action) => {
      state.wineRegionData = action.payload
    },
    setDMVMenuCategoryId: (state, action) => {
      state.DMVmenu_Category_ID = action.payload
    },
    setWineMenuItem: (state, action) => {
      state.wineMenuItem = action.payload
    },
    setDMVItemID: (state, action) => {
      state.itemID = action.payload
    },
    setBeverageData: (state, action) => {
      state.beverageData = action.payload
    },
    setBeverageMenuItem: (state, action) => {
      state.beverageMenuItem = action.payload
    },
    setDMVAutoSuggestQuestions: (state, action) => {
      state.DMVAutoSuggestQuestions = action.payload
    },
    setDMVGenerate_Question_MenuLink: (state, action) => {
      state.DMVgenerate_question_MenuLink = action.payload
    },
    setDMVMenuItems: (state, action) => {
      state.SelectedDMVMenuItems = action.payload
    },
    setSubFilterValue: (state, action) => {
      state.menuCategory = action.payload
    },
    setSubFilterValueView: (state, action) => {
      state.menuCategory.list = action.payload
    },
    setSpiritSubMenuItems: (state, action) => {
      state.SpiritSubMenu = action.payload
    },
    setSpiritData: (state, action) => {
      state.spiritData = action.payload
    },
    setSpiritMenuItem: (state, action) => {
      state.spiritMenuItem = action.payload
    },
    setSpiritsData: (state, action) => {
      state.generateQuestionSpiritData = action.payload
    },
    setSpiritSummary: (state, action) => {
      state.spiritSummary = action.payload
    }
  }
})

export default dmv
export const DMVActions = dmv.actions
