import React, { Fragment, useEffect, useState } from "react"
import { Box, Divider, Grid, Typography } from "@mui/material"
import styles from "../../trivia-contest/components/PendingQuestionTable/PendingQuestionTable.module.scss"
import { useTheme } from "@mui/system"
import BankQuestions from "./BankQuestions"
import CreateIcon from "@mui/icons-material/Create"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import Header from "components/Header/Header"
import Card from "components/Card/Card"
import AppPagination from "components/StyledComponents/AppPagination"
import EditQuestion from "../EditQuestion"
import ImageDisplay from "components/ImageDisplayer"
import { Col } from "react-bootstrap"
import AppButton from "components/StyledComponents/AppButton"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
  addToQuestionBankAction,
  deleteQuestionFromAutoSuggestAction,
  deleteQuestionFromBankAction
} from "redux/L&D/actions/user"
import DeleteIcon from "@mui/icons-material/Delete"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import AppSelect from "components/StyledComponents/AppSelect"

const btnText = {
  questionBank: "Add To Question Bank",
  triviaText: "Add to Trivia"
}

function QuestionsBanks({
  questions,
  title,
  isEditable = false,
  showImage = true,
  showPagination = true,
  currentPage = 1,
  setCurrentPage,
  isTiriva = false,
  handleAddToTrivia,
  pages = 0,
  isGamification = false,
  isAutoSuggest = false,
  isDelete = false,
  questionFilter = false,
  setFilterQuestion,
  filterQuestion,
  filterOptions
}) {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const style = {
    card: {
      padding: "24px 0"
    },
    box: {
      padding: "0px 24px",
      background: "none"
    },
    divider: {
      background: "#7f7f7f",
      height: "2px",
      opacity: 0.12
    },
    btnQuestion: {
      paddingLeft: "47px",
      paddingRight: "47px"
    },
    checkBoxPosition: {
      cursor: "pointer",
      marginLeft: 10,
      marginRight: 25
    }
  }

  const [selectAll, setSelectAll] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [editData, setEditData] = useState()
  const [selectedQuestions, setSelectedQuestions] = useState([])
  const [execeptQuestions, setExceptQuestions] = useState([])
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
  const [deleteQuestion, setDeleteQuestion] = useState("")

  const handleQuestionSelect = () => {
    if (selectAll && execeptQuestions?.length > 0) {
      setExceptQuestions([])
      return
    }
    setSelectedQuestions([])
    setExceptQuestions([])
    setSelectAll((prevSelectAll) => !prevSelectAll)
  }

  const handleSelectQuestion = (ID) => {
    if (selectAll) {
      const isExceptQuestionsAvailable = execeptQuestions.filter((item) => item === ID)
      if (isExceptQuestionsAvailable?.length > 0) {
        const updatedExceptQuestionList = execeptQuestions.filter((item) => item !== ID)
        setExceptQuestions(updatedExceptQuestionList)
      } else {
        setExceptQuestions([...execeptQuestions, ID])
      }
    } else {
      //check that question is already selected or not
      const isAvailable = selectedQuestions.filter((item) => item === ID)
      //if item is already selected remove that item from list
      if (isAvailable?.length > 0) {
        const updatedList = selectedQuestions.filter((item) => item !== ID)
        setSelectedQuestions(updatedList)
      } else {
        //add new item in list
        setSelectedQuestions([...selectedQuestions, ID])
      }
    }
  }

  const checkSelected = (ID) => {
    if (selectAll && execeptQuestions?.length === 0) {
      return true
    }
    if (selectAll && execeptQuestions?.length > 0) {
      let exceptIems = execeptQuestions.filter((data) => data === ID)
      if (exceptIems?.length > 0) {
        return false
      } else {
        return true
      }
    } else {
      let isItemSelected = selectedQuestions.filter((item) => item === ID)
      if (isItemSelected?.length > 0) {
        return true
      } else {
        return false
      }
    }
  }

  const handleEditDialogOpen = (question) => {
    setEditData(question)
    setOpenEditDialog(true)
  }

  const handleDeleteQuestion = () => {
    if (isAutoSuggest) {
      dispatch(
        deleteQuestionFromAutoSuggestAction({
          question: deleteQuestion,
          currentPage,
          menu: filterQuestion
        })
      )
    } else {
      dispatch(deleteQuestionFromBankAction({ question: deleteQuestion, currentPage }))
    }
  }

  const handleOpenDelete = (question) => {
    setIsDeletePopupOpen(true)
    setDeleteQuestion(question)
  }

  const handleCloseDelete = () => {
    setIsDeletePopupOpen(false)
  }

  const handleEditDialogClose = () => {
    setOpenEditDialog(false)
  }

  useEffect(() => {
    if (selectedQuestions?.length > 0 && questions?.length === selectedQuestions?.length) {
      setSelectAll(true)
    }
  }, [selectedQuestions])

  const handleAddToBank = () => {
    const payload = {
      is_all_selected: selectAll,
      question_ids: selectedQuestions,
      excepted_ids: execeptQuestions
    }
    dispatch(addToQuestionBankAction({ payload, isAutoSuggest }))
    if (!isGamification) {
      history.push("/learning_development/dashboard/question-bank")
    } else {
      history.goBack()
    }
  }

  const handleAddQuestionsToTrivia = () => {
    const payload = {
      is_all_selected: selectAll,
      question_ids: selectedQuestions,
      excepted_ids: execeptQuestions
    }
    handleAddToTrivia(payload)
  }

  const checkButtonDisable = () => {
    if (selectAll || selectedQuestions?.length > 0 || execeptQuestions?.length > 0) {
      return true
    } else {
      return false
    }
  }

  const handleFilter = (event) => {
    setFilterQuestion(event.target.value)
    setCurrentPage(1)
  }

  return (
    <Fragment>
      {openEditDialog ? (
        <EditQuestion
          onClose={handleEditDialogClose}
          data={editData}
          currentPage={currentPage}
          isGamification={isGamification}
        />
      ) : null}
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        onClose={handleCloseDelete}
        onDelete={() => handleDeleteQuestion()}
        description="Are you Sure you want to Delete this question?"
      />

      <Header title={title} back={false}>
        <Grid container minWidth={430} xs={12} justifyContent="flex-end">
          {questionFilter && (
            <Grid item xs={5} mr={2}>
              <AppSelect menuItems={filterOptions} value={filterQuestion} onChange={handleFilter} />
            </Grid>
          )}
          {questions?.length > 0 && (
            <Grid item xs={4} display="flex" justifyContent="flex-end" alignItems="center">
              <Typography>
                Select All
                {selectAll && execeptQuestions?.length === 0 ? (
                  <CheckCircleIcon
                    htmlColor={theme.palette.secondary.main}
                    onClick={handleQuestionSelect}
                    style={style.checkBoxPosition}
                  />
                ) : (
                  <RadioButtonUncheckedIcon
                    style={style.checkBoxPosition}
                    onClick={handleQuestionSelect}
                  />
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Header>

      <Card sx={style.card}>
        {questions?.length > 0 ? (
          questions?.map((question, index) => (
            <Fragment key={question?.id}>
              <Box
                key={question?.id}
                className={`${styles.questionCtr} ${
                  question?.is_used_in_trivia ? styles.question_added : ""
                }`}
                sx={style.box}>
                <Grid container>
                  <Grid item xs={10}>
                    <div className="d-flex">
                      <div
                        className={styles.countBorder}
                        style={{
                          borderColor: "#FFFFFF",
                          marginTop: 25,
                          marginRight: 10
                        }}>
                        {currentPage * 12 - 11 + index}
                      </div>
                      {showImage ? (
                        <div className="d-flex ml-2">
                          <div
                            className="me-3 mt-3 p-2 d-flex justify-content-center"
                            style={{ width: "200px", height: "auto", borderRadius: "6px" }}>
                            {question?.image ? (
                              <img
                                className="img-fluid"
                                style={{ borderRadius: "6px", height: 120, width: 233 }}
                                src={question.image}></img>
                            ) : (
                              <ImageDisplay />
                            )}
                          </div>
                        </div>
                      ) : (
                        <div style={{ marginLeft: 20 }}></div>
                      )}
                      <BankQuestions question={question} status={question?.status} index={index} />
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container justifyContent="flex-end">
                      <div style={{ marginTop: 20 }}>
                        <Grid container spacing={1}>
                          <Grid item>
                            {isEditable && (
                              <CreateIcon
                                sx={(theme) => ({
                                  color: theme.palette.white.main,
                                  cursor: "pointer"
                                })}
                                className="me-2"
                                onClick={() => handleEditDialogOpen(question)}></CreateIcon>
                            )}
                          </Grid>
                          {!question?.is_used_in_trivia && isDelete ? (
                            <Grid item>
                              <DeleteIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => handleOpenDelete(question)}
                              />
                            </Grid>
                          ) : null}
                          <Grid item>
                            {checkSelected(question?.id) || question?.is_used_in_trivia ? (
                              <CheckCircleIcon
                                htmlColor={theme.palette.secondary.main}
                                onClick={() => handleSelectQuestion(question?.id)}
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <RadioButtonUncheckedIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => handleSelectQuestion(question?.id)}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    {question?.is_used_in_trivia ? (
                      <Grid item mt={2} display="flex" justifyContent="flex-end">
                        <Typography className={styles.inUse} variant="body2Regular">
                          In Use
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
              {questions?.length - 1 > index && <Divider sx={style.divider} />}
            </Fragment>
          ))
        ) : (
          <Typography variant="h2" className="d-flex justify-content-center">
            No Questions Found
          </Typography>
        )}
        {showPagination && pages > 0 && (
          <AppPagination
            onPageChange={setCurrentPage}
            totalPages={pages}
            currentPage={currentPage}
          />
        )}
      </Card>
      {questions?.length > 0 && (
        <Col lg="12" className={isTiriva ? "d-flex justify-content-end" : ""}>
          <AppButton
            styles={
              isTiriva
                ? { paddingLeft: 47, paddingRight: 47 }
                : { paddingLeft: 13, paddingRight: 13 }
            }
            disabled={checkButtonDisable() ? false : true}
            onClick={isTiriva ? handleAddQuestionsToTrivia : handleAddToBank}>
            {isTiriva ? btnText.triviaText : btnText.questionBank}
          </AppButton>
        </Col>
      )}
    </Fragment>
  )
}

export default QuestionsBanks
