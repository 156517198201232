import React, { useState } from "react"
import classes from "pages/private/marketing/menu/menu.module.scss"
import { Menu, MenuItem, Typography } from "@mui/material"
import ImageDisplay from "components/ImageDisplayer"
// import ReadMoreReact from "read-more-react"
import { useTheme } from "@mui/system"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import {
  deleteMenuItemAction,
  getFoodItemsAction,
  updateFoodItemsAction
} from "redux/marketing/actions"
import { useEffect } from "react"
import Paragraph from "components/ReadMoreParagraph"

function MenuListItems({
  foodList,
  isEdit,
  categoryIdData,
  setMenuItemsModal = () => {},
  setIsEditModalItem = () => {}
}) {
  const theme = useTheme()
  const [modal, setDeleteModal] = useState(false)
  const { linkCategory } = useSelector((store) => store?.marketing?.menu ?? {})
  const [ArchiveModal, setArchiveItemModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event, id, categoryId) => {
    setAnchorEl(event.currentTarget)
    dispatch(marketingActions.setMenuItemId(id))
    dispatch(marketingActions.setMenuCategoryId(categoryId))
    dispatch(getFoodItemsAction())
  }

  function checkDetailView(item) {
    if (item === "Summary View") {
      return false
    } else {
      return true
    }
  }

  function handleMenuItemDelete() {
    dispatch(deleteMenuItemAction())
  }

  function handleMenuDeleteModal() {
    handleClose()
    setDeleteModal(true)
    setAnchorEl(null)
  }

  function handleCloseModal() {
    setDeleteModal(false)
    dispatch(marketingActions.setMenuItemId(""))
  }

  function handleEditMenuItemModal() {
    setAnchorEl(null)
    setIsEditModalItem(true)
    setMenuItemsModal(true)
  }

  function handleArchiveItem() {
    setArchiveItemModal(true)
    setAnchorEl(null)
  }

  function handleCloseArchiveModal() {
    setArchiveItemModal(false)
  }

  function handleArchiveMenuItem() {
    const payload = {
      item_state: "archived"
    }
    dispatch(updateFoodItemsAction(payload))
  }

  useEffect(() => {}, [])

  return (
    <>
      <DeleteConfirmationDialog
        title={`Are You Sure You Want To Delete This Dish ?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseModal}
        onDelete={() => handleMenuItemDelete()}
        open={modal}></DeleteConfirmationDialog>
      <DeleteConfirmationDialog
        title={`Are You Sure You Want To Archive This Dish?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseArchiveModal}
        onDelete={() => handleArchiveMenuItem()}
        open={ArchiveModal}
        btnText="Archive"
        type="warning"
      />
      <div
        className={classes.itemContainer}
        style={{
          border: `${isEdit ? `1px solid ${theme.palette.secondary.main}` : "none"}`,
          borderRadius: `${isEdit ? "6px" : "0px"}`,
          padding: `${isEdit ? "8px" : "0px"}`
        }}>
        <div className={classes.imageContainer}>
          <ImageDisplay data={foodList?.image}></ImageDisplay>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: 375,
            overflowWrap: "break-word"
          }}>
          <div>
            <Typography variant="h4">{foodList?.name ?? "-"}</Typography>
            {!checkDetailView(linkCategory.list) && (
              <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                {foodList?.menu_description ?? "-"}
              </Typography>
            )}
          </div>
          {checkDetailView(linkCategory.list) && (
            <div>
              <div className="mt-2">
                <Typography variant="tag1">{"Menu Description:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.menu_description ?? "-"}
                </Typography>
              </div>
              <div className="mt-2">
                <Typography variant="tag1">{"Allergens:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.allergance?.length > 0
                    ? foodList?.allergance?.map((item, index) => {
                        return foodList?.allergance?.length - 1 > index
                          ? `${item?.name}, `
                          : `${item?.name}`
                      })
                    : ""}
                </Typography>
              </div>
              <div className="mt-2">
                <Typography variant="tag1">{"Chef’s Description:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {<Paragraph text={foodList?.description ?? ""} maxCharCount={70}></Paragraph>}
                </Typography>
              </div>
            </div>
          )}
        </div>
        <div style={{ display: "flex" }}>
          {foodList?.item_price > 0 && (
            <div>
              <Typography variant="bold">${foodList?.item_price}</Typography>
            </div>
          )}
          {isEdit && (
            <div>
              <MoreVertIcon
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e) => handleClick(e, foodList?.id, categoryIdData)}
                sx={{ cursor: "pointer" }}></MoreVertIcon>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}>
                <MenuItem onClick={() => handleEditMenuItemModal()} sx={{ width: "150px" }}>
                  Edit
                </MenuItem>
                <MenuItem onClick={() => handleArchiveItem()} sx={{ width: "150px" }}>
                  Archive
                </MenuItem>
                <MenuItem onClick={handleMenuDeleteModal} sx={{ color: theme.palette.red.main }}>
                  Delete
                </MenuItem>
              </Menu>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MenuListItems
