import { createSlice } from "@reduxjs/toolkit"
import { revertAllSuperAdmin } from "../actions/user"

const initialState = {
  frontlineUser: {
    id: null,
    frontlineUserData: {},
    frontlineUserList: {
      totalPages: 1,
      data: []
    }
  },
  notifications: {
    id: null,
    notificationData: {},
    notificationList: {
      totalPages: 1,
      data: []
    },
    notificationImageId: ""
  },
  adminUsers: {
    id: null,
    isFormSubmitted: false,
    adminUserData: {},
    adminUserList: {
      totalPages: 1,
      data: {}
    }
  },
  versionListData: {
    totalPages: 1,
    data: {}
  },
  versionData: {},
  versionId: null,
  dashboardStats: {},
  giftCards: {
    id: null,
    giftCardData: {},
    giftCardList: {
      totalPages: 1,
      data: []
    },
    giftCardImageId: ""
  }
}

const superAdmin = createSlice({
  name: "superAdmin",
  initialState: initialState,
  extraReducers: (builder) => builder.addCase(revertAllSuperAdmin, () => initialState),
  reducers: {
    setFrontlineUserID: (state, action) => {
      state.frontlineUser.id = action.payload
    },
    setAdminUserID: (state, action) => {
      state.adminUsers.id = action.payload
    },
    setFrontlineUserList: (state, action) => {
      state.frontlineUser.frontlineUserList = action.payload
    },
    setFrontlineUserData: (state, action) => {
      state.frontlineUser.frontlineUserData = action.payload
    },
    setAdminUserList: (state, action) => {
      state.adminUsers.adminUserList = action.payload
    },
    setAdminUserData: (state, action) => {
      state.adminUsers.adminUserData = action.payload
    },
    setDashboardStats: (state, action) => {
      state.dashboardStats = action.payload
    },
    setVersionListData: (state, action) => {
      state.versionListData = action.payload
    },
    setVersionID: (state, action) => {
      state.versionId = action.payload
    },
    setVersiondata: (state, action) => {
      state.versionData = action.payload
    },
    setNotificationList: (state, action) => {
      state.notifications.notificationList = action.payload
    },
    setNotificationID: (state, action) => {
      state.notifications.id = action.payload
    },
    setNotificationData: (state, action) => {
      state.notifications.notificationData = action.payload
    },
    setNotificationImageId: (state, action) => {
      state.notifications.notificationImageId = action.payload
    },
    setGiftCardList: (state, action) => {
      state.giftCards.giftCardList = action.payload
    },
    setGiftCardID: (state, action) => {
      state.giftCards.id = action.payload
    },
    setGiftCardData: (state, action) => {
      state.giftCards.giftCardData = action.payload
    },
    setGiftCardImageId: (state, action) => {
      state.giftCards.giftCardImageId = action.payload
    }
  }
})

export default superAdmin
export const superAdminAction = superAdmin.actions
