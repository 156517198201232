import React, { useEffect, useRef, useState } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { Typography } from "@mui/material"
import styles from "./ContestTable.module.scss"
import { styled } from "@mui/system"
import CheckBox from "components/CheckBox"
import ContestRow from "./ContestRow"
import RenameModal from "components/Modal/RenameModal"
import { useDispatch, useSelector } from "react-redux"
import AppPagination from "components/StyledComponents/AppPagination"
import { getTriviaContestListAction } from "redux/L&D/actions"
import { dateDiffInDays } from "helpers/functions"
import TableNoRecords from "components/TableNoRecords"

const StyledTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  "& .MuiTableCell-root": {
    padding: "14px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `2px solid ${theme.palette.background.default}`,
    boxSizing: "border-box",
    "&:first-of-type": {
      paddingLeft: 6,
      paddingRight: 27
    },
    "&:last-child": {
      paddingRight: 0
    }
  },

  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const ContestTable = ({
  selectedContestCount,
  setSelectedContestCount,
  selectedContests,
  setSelectedContests,
  setIsAllSelected,
  isAllSelected
}) => {
  const [isModal, setModal] = useState(false)
  const [modalData, setModalData] = useState({ name: "" })
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [deletableContests, setDeletableContests] = useState([])
  const triviaContestList = useSelector(
    (state) => state?.learningDevelopment?.trivia?.triviaContestList
  )
  const totalDeletableContestsRef = useRef(0)

  const handleSetModal = (data) => {
    setModal(data)
  }
  const handleCloseModal = (data) => {
    setModal(data)
  }

  const onRowClick = (index, id) => {
    let selectedCount = selectedContestCount
    if (selectedContests[index]) {
      setSelectedContests((prev) => {
        prev[index] = false
        return [...prev]
      })
      --selectedCount
    } else {
      setSelectedContests((prev) => {
        prev[index] = id
        return [...prev]
      })
      ++selectedCount
    }
    setSelectedContestCount(selectedCount)
    selectedCount === totalDeletableContestsRef.current
      ? setIsAllSelected(true)
      : setIsAllSelected(false)
  }

  const hasDeletePermission = (contest) => {
    if (contest.status.toLowerCase() === "completed") {
      return false
    }
    const contestDateArr = contest?.start_date?.split("-")?.map(Number)
    if (contestDateArr?.length) {
      const contestDate = new Date(contestDateArr[0], contestDateArr[1] - 1, contestDateArr[2])
      let currentDate = new Date()
      if (
        contestDate.getMonth() === currentDate.getMonth() &&
        contestDate.getFullYear() === currentDate.getFullYear()
      ) {
        return false
      }
      if (dateDiffInDays(currentDate, contestDate) > 5) {
        return true
      }
    }
    return false
  }

  const onSelectAllClick = () => {
    if (isAllSelected) {
      setSelectedContests(
        selectedContests.map((contest) => (contest === "disabled" ? contest : false))
      )
      setSelectedContestCount(0)
    } else {
      let selectedCount = 0
      setSelectedContests(
        selectedContests.map((contest, index) => {
          if (contest === "disabled") {
            return contest
          } else {
            ++selectedCount
            return triviaContestList?.data?.[index]?.id
          }
        })
      )
      setSelectedContestCount(selectedCount)
    }
    setIsAllSelected(!isAllSelected)
  }

  useEffect(() => {
    dispatch(getTriviaContestListAction({ page: currentPage }))
  }, [currentPage])

  useEffect(() => {
    let selectedContest = []
    let totalDeletableContests = triviaContestList?.data?.length || 0
    const deletableItems = triviaContestList?.data?.map((contest, index) => {
      const isEnabled = hasDeletePermission(contest)
      selectedContest[index] = false
      if (!isEnabled) {
        selectedContest[index] = "disabled"
        --totalDeletableContests
      }
      return isEnabled
    })
    totalDeletableContestsRef.current = totalDeletableContests
    setDeletableContests(deletableItems || [])
    setSelectedContests(selectedContest)
    setSelectedContestCount(0)
  }, [triviaContestList])

  return (
    <>
      <Paper className={styles.container}>
        {isModal ? (
          <RenameModal
            closeModal={handleCloseModal}
            page={currentPage}
            modalData={modalData}></RenameModal>
        ) : null}
        <TableContainer sx={{ maxHeight: 600 }}>
          <StyledTable stickyHeader aria-label="contest table">
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ height: 24, width: 24 }}>
                  {deletableContests.includes(true) && (
                    <CheckBox role="button" checked={isAllSelected} onClick={onSelectAllClick} />
                  )}
                </TableCell>
                {ContestColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography className={styles.header} variant="body2Regular">
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {triviaContestList?.data?.length ? (
                <>
                  {triviaContestList?.data?.map((row, index) => {
                    return (
                      <ContestRow
                        isOpenModal={handleSetModal}
                        setModalData={setModalData}
                        key={row.id}
                        row={row}
                        isSelected={selectedContests[index]}
                        onChange={onRowClick}
                        index={index}
                        columns={ContestColumns}
                        isDeletable={deletableContests[index]}
                      />
                    )
                  })}
                </>
              ) : (
                <TableNoRecords colSpan={9} label="No Contests Found" />
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Paper>
      <AppPagination
        onPageChange={setCurrentPage}
        totalPages={triviaContestList?.totalPages}
        currentPage={currentPage}
      />
    </>
  )
}

export default ContestTable

const ContestColumns = [
  {
    id: "Name",
    label: "Name",
    align: "left",
    minWidth: 150
  },
  {
    id: "Trivia For",
    label: "Trivia For",
    align: "center",
    minWidth: 75
  },
  {
    id: "Weekly Published",
    label: "Weekly Published",
    align: "center",
    minWidth: 75
  },
  {
    id: "Created",
    label: "Created",
    align: "center",
    minWidth: 95
  },
  {
    id: "Status",
    label: "Status",
    align: "center",
    minWidth: 43
  },
  {
    id: "Region",
    label: "Region",
    align: "center",
    minWidth: 100
  },
  {
    id: "Trivia Analytics",
    label: "Trivia Analytics",
    align: "center",
    minWidth: 100
  },
  {
    id: "Actions",
    label: "Actions",
    align: "center",
    minWidth: 50
  }
]
