import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import { validationSchemaRenameContest } from "helpers/validationSchema";
import AppTextField from "components/StyledComponents/AppTextField";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

import AppButton from "components/StyledComponents/AppButton";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  editTriviaContestAction,
  getTriviaContestListAction
} from "redux/L&D/actions";
import { learningDevelopmentActions } from "redux/L&D/slice/user";
import { useTheme } from '@mui/system';



export default function RenameModal({ closeModal, modalData, page }) {
  const [open] = React.useState(true);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isContestEdited } = useSelector(
    (store) => store?.learningDevelopment?.trivia?.editTrivia
  );
  const formik = useFormik({
    initialValues: modalData,
    enableReinitialize: true,
    validationSchema: validationSchemaRenameContest,
    onSubmit: (values) => {
      dispatch(editTriviaContestAction(values));
    }
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "12px",
    color: "#ffffff",
    boxShadow: 24,
    p: 4
  };

  React.useEffect(() => {
    if (isContestEdited) {
      dispatch(learningDevelopmentActions.setIsContestEdited(false));
      dispatch(getTriviaContestListAction({ page }));
      closeModal();
    }
  }, [isContestEdited]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => closeModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <>
          <Box sx={style}>
            <AppTextField
              id="name"
              fullWidth
              label="Rename Contest"
              isRequired={true}
              placeholder="Rename"
              value={formik.values.name}
              onChange={formik.handleChange("name")}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <Row className="mt-3">
              <Col
                lg="12"
                style={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}>
                <Box
                  className="me-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                  onClick={() => closeModal(false)}>
                  <Typography variant="body1Regular">Cancel</Typography>
                </Box>
                <AppButton
                  variant="outlined"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#ffffff"
                  }}
                  onClick={formik.handleSubmit}>
                  Submit
                </AppButton>
              </Col>
            </Row>
          </Box>
        </>
      </Modal>
    </div>
  );
}
